<template>
    <div class="card">

        <div class="card-header" v-cloak>
            <h3>{{messages("panel.admin.configs.title") }}<small>:{{messages("panel.admin.configs.subtitle")}}</small>
            </h3>
        </div>

        <ul class="list-group">

            <div class="list-group-item">
                <p>{{"Versão da aplicação: " + version}} </p>
            </div>

            <div class="list-group-item" v-for="setting in settings" :key="setting.key">
                <div class="form-group row" :class="{ 'is-invalid': setting.error }">
                    <label class="control-label col-3">{{ setting.display }}</label>
                    <div class="col-5">
                        <input type="text" v-model="setting.value" class="form-control"
                               v-on:keyup.enter="updateSetting(setting)">
                        <small class="help-block">{{messages("label.key")}}: {{setting.key }}</small>
                        <span v-show="setting.error" class="help-block">{{messages("label.error")}}</span>
                    </div>
                    <div class="col-4">
                        <p class="text-muted">
                            {{ setting.description }}
                        </p>
                    </div>
                </div>

            </div>
        </ul>

    </div>

</template>

<script>
    import {mapMutations} from 'vuex'
    import {messagesMixin} from "../../../mixins/messagesMixin";

    export default {
        name: "ParametrosMainContainer",
        mixins: [
            messagesMixin,
        ],

        mounted() {
            this.loadSettings();
            this.loadAppVersion();
        },

        data() {
            return {
                settings: [],
                editMode: false,
                version: '',
            }
        },

        methods: {
            ...mapMutations({
                alert: 'addAlert'
            }),


            updateSetting: function (setting) {
                // console.log("updateSetting");
                if (setting.value === undefined || setting.value === "") {
                    // console.error("invalid!");
                    setting.error = true;
                    return;
                } else if (setting.value.length > 200) {
                    // console.error("invalid!");
                    setting.error = true;
                    return;
                }
                setting.error = false;
                const url = jsRoutes.controllers.AdminController.updateSetting().url;


                this.$http.post(url, setting)
                    .then((response) => {
                        this.loadSettings();
                        this.alert({
                            title: this.messages('success.modal.title'),
                            message: this.messages('success.msg.edited.setting'),
                            type: 'success'
                        })
                    })
                    .catch(error => {
                        this.alert({
                            title: this.messages('error.modal.title'),
                            message: error.response.data,
                            type: 'error'
                        })
                    });
            },

            loadSettings: function () {
                const url = jsRoutes.controllers.AdminController.listSettings().url;
                this.$http.get(url)
                    .then(response => {
                        this.settings = response.data;
                    })
                    .catch(error => {
                        this.alert({
                            title: this.messages('error.modal.title'),
                            message: error.response.data,
                            type: 'error'
                        })
                    })
            },

            loadAppVersion() {
                const url = jsRoutes.controllers.AdminController.appVersion().url;
                this.$http.get(url)
                    .then(response => {
                        this.version = response.data
                    })

            }

        },

    }
</script>

<style scoped>

</style>
