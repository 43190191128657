<template>


    <div class="row justify-content-center" id="loginContainer">

        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4  card mt-5">

            <div class="card-body">

                <img :src="state.logoUrl" width="340px">
                <h4 class="mt-5">Login</h4>

                <form v-show="!state.recoveryMode">
                    <div class="form-group row">
                        <label class="col-4 col-form-label">{{messages("label.username")}}</label>
                        <div class="col">
                            <input type="text"
                                   class="form-control"
                                   v-on:keypress.enter="doLogin"
                                   id="aceusername"
                                   name="aceusername" :placeholder="messages('label.username')"
                                   v-model="loginData.username">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-4 col-form-label">{{messages("label.password")}}</label>
                        <div class="col">
                            <input type="password"
                                   class="form-control"
                                   @keypress.enter="doLogin"
                                   id="acepassword" name="acepassword"
                                   :placeholder="messages('label.password')"
                                   v-model="loginData.password">
                        </div>
                    </div>
                </form>

                <!--   recover password -->
                <div class="row mt-4">
                    <div class="col-12">
                        <button @click.prevent="state.recoveryMode = !state.recoveryMode"
                                class="btn-link m-3">
                            {{messages("modal.login.forgot.password.msg")}}
                        </button>
                        <div v-show="state.recoveryMode" class="form-group">
                            <label>{{messages("modal.login.forgot.password.username")}}</label>
                            <input v-model="state.recoveryEmail"
                                   @focus="state.recoveryError = false"
                                   :class="{ 'is-invalid' : state.recoveryError }"
                                   type="email"
                                   class="form-control">
                            <span class="invalid-feedback">{{messages('error.field.required')}}</span>
                        </div>
                    </div>
                </div>

                <div class="card-footer ">
                    <div class="row justify-content-end">
                        <div v-if="state.recoveryMode"
                             class="col-auto">
                            <button @click.prevent="closeRecoveryMode"
                                    class="btn btn-outline-secondary">
                                {{messages("button.close")}}
                            </button>

                            <button @click.prevent="sendRecover"
                                    class="btn btn-primary">
                                {{messages("button.submit")}}
                            </button>

                        </div>
                        <div v-else
                             class="col-auto">
                            <button :disabled="state.recoveryMode"
                                    type="button"
                                    class="btn btn-primary"
                                    v-on:click.prevent="doLogin">
                                {{messages("modal.login.executebutton")}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {reactive} from '@vue/composition-api'
    import axios from 'axios'
    import {messagesMixin} from "../mixins/messagesMixin";

    export default {
        name: "LoginPage",
        mixins: [messagesMixin],
        setup(props, {root: {$store}}) {
            // console.log('login setup store', $store)

            const state = reactive({
                showError: false,
                errorMessage: '',

                recoveryMode: false,
                recoveryEmail: '',
                recoveryError: false,

                logoUrl: jsRoutes.controllers.Assets.versioned('images/logos/vigerprev_logo.jpg').url,
            })

            const loginData = reactive({
                username: '',
                password: '',
            })
            const doLogin = () => {
                // console.log('do login')
                $store.commit('showMask', true)
                const url = jsRoutes.controllers.Application.doLogin().url;
                axios.post(url, loginData)
                    .then((response) => {
                        // console.log("doLogin - success!! redirecting...");
                        $store.commit('showMask', false)
                        window.location.assign('/');
                    })
                    .catch((error) => {
                        // console.error("doLogin - error", error.response);
                        $store.commit('showMask', false)
                        $store.commit('addAlert', {
                            title: Messages('error.modal.title'),
                            message: error.response.data,
                            type: 'error'
                        });
                    })
            }


            const closeRecoveryMode = () => {
                state.recoveryMode = false
                state.recoveryEmail = ''
            }

            const sendRecover = () => {
                if (state.recoveryEmail === '') {
                    state.recoveryError = true;
                    return
                }

                $store.commit('showMask', true)
                const url = jsRoutes.controllers.Application.sendRecovery().url;

                axios.post(url, {email: state.recoveryEmail})
                    .then(response => {
                        $store.commit('showMask', false)
                        $store.commit('addAlert', {
                            title: Messages('success.modal.title'),
                            message: Messages('success.msg.recovery.sent'),
                            type: 'success'
                        });
                        closeRecoveryMode()
                    })
                    .catch(error => {
                        // console.error("sendRecovery - error", error.response);
                        $store.commit('showMask', false)
                        $store.commit('addAlert', {
                            title: Messages('error.modal.title'),
                            message: error.response.data,
                            type: 'error'
                        });
                    })
            }


            return {
                state,
                loginData,
                doLogin,
                closeRecoveryMode,
                sendRecover
            }
        }

    }
</script>

<style scoped>

</style>
