/**
 * Filipe Ares
 * 18/01/19
 *
 * filename: messagesMixin.js
 */

export const messagesMixin = {

  methods: {

    messages(key,...theArgs) {
      return Messages(key, theArgs)
    },

  }

}
