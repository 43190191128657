/**
 * Filipe Ares
 * 18/01/19
 *
 * filename: paginatorMixin.js
 */

export const paginatortMixin = {

  data() {
    return {
      selectedPage: 1,
      itemsShow: 10,
    }
  },

  methods:{

    changePage: function ($event) {
      this.selectedPage = $event;
    },

    changeItems: function ($event) {
      this.itemsShow = $event;
    },

  }

}
