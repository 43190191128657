/**
 *
 * Created by Filipe - 28/05/2020
 *
 */
import axios from 'axios'

const state = {
    planeamentoData: [],

    // NOVO
    empresas: [
        {
            id: 'PREVEL',
            key: 'enum.empresa.prevel'
        },
        {
            id: 'GERALSEG',
            key: 'enum.empresa.geralseg'
        },
        {
            id: 'VITELSIS',
            key: 'enum.empresa.vitelsis'
        },
    ]


}

const getters = {
    // `getters` is localized to this module's getters
    // you can use rootGetters via 4th argument of getters
    // someGetter (state, getters, rootState, rootGetters)

    getEmpresas: state => state.empresas,
    getPlaneamentoData: state => state.planeamentoData,

}

const mutations = {
    setPlaneamentoList: (state, data) => state.planeamentoData = data,
}

const actions = {

    // dispatch and commit are also localized for this module
    // they will accept `root` option for the root dispatch/commit
    // dispatch('someOtherAction', null, { root: true })
    // commit('someMutation', null, { root: true })
    loadPlaneamentoData({commit}, filterData) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.PlaneamentoController.loadPlaneamentoData().url;

        return axios.post(url, filterData)
            .then((response) => {
                commit('showMask', false, {root: true})
                commit('setPlaneamentoList', response.data)
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

        /*

                loadingVm.show();
                this.$http.post(url, this.filterData).then(function (response) {
                    // console.log("clientes loaded");
                    self.eventos = response.body.list;
                    self.isTecnico = response.body.isTecnico;
                    this.$nextTick(function () {
                        $('[data-toggle="tooltip"]').tooltip();
                        loadingVm.hide();
                    });
                }, function (reason) {
                    loadingVm.hide();
                    showError(Messages('error.modal.title'), reason.body);
                });

            },

         */
    }

}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
