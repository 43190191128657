import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import pedidos from "./modules/pedidos";
import folhasobra from "./modules/folhasobra";
import planeamento from "./modules/planeamento";
import artigos from "./modules/artigos";
import contratos from "./modules/contratos";
import servicos from "./modules/servicos";
import agencias from "./modules/agencias";
import users from "./modules/users";
import user from "./modules/user";

Vue.use(Vuex)


export default new Vuex.Store({

    state: {
        alertid: 0, //aux
        alertsList: [],
        showMask: false,

        dialog: {
            title: Messages('confirm.modal.title'),
            message: '',
            successCallback: undefined,
            cancelCallback: undefined,
            show: false,
            successStyle: 'btn-primary',
            successText: Messages('button.yes'),
            cancelText: Messages('button.cancel'),
        },

        appInfo: {
            loggedUserId: null,
            userProfile: null
        },

        agenciasAtivasList: [],
        agenciasWithCodeAtivasList: [],
        tecnicosList: [],
        zonasTecnicosList: [],
        gruposTecnicosList: [],
        zonasAgenciasList: [],

    },

    getters: {
        getAlertsList: state => state.alertsList,
        getShowMask: state => state.showMask,
        getDialogProps: state => state.dialog,
        getUserProfile: state => state.appInfo.userProfile,
        getLoggedUserId: state => state.appInfo.loggedUserId,
        isTecnico: state => state.appInfo.userProfile === 'tecnico',
        isAdmin: state => state.appInfo.userProfile === 'admin',
        iscoordenacao: state => state.appInfo.userProfile === 'coordenacao',

        getAgenciasAtivasSelect: state => state.agenciasAtivasList,
        getAgenciasAtivasCodeSelect: state => state.agenciasWithCodeAtivasList,
        getTecnicosSelect: state => state.tecnicosList,
        getZonasTecnicosSelect: state => state.zonasTecnicosList,
        getGruposTecnicosSelect: state => state.gruposTecnicosList,

        getZonasAgencias: state => state.zonasAgenciasList,
        getUsersList: state => state.usersList,
        getLoggedUser: state => state.loggedUser,
        getProfilesSelect: state => state.profilesSelectList


    },

    mutations: {
        addAlert: (state, alert) => {
            alert.id = state.alertid++
            state.alertsList.push(alert)
            setTimeout(() => {
                state.alertsList.shift()
            }, 5000)// 5s
        },
        showMask(state, val) {
            state.showMask = val
        },

        setDialogProps(state, data) {
            if (data.show) {
                state.dialog.message = data.message
                state.dialog.successCallback = data.callback
                if (data.successStyle) {
                    state.dialog.successStyle = data.successStyle
                }
                if (data.cancelCallback) {
                    state.dialog.cancelCallback = data.cancelCallback
                }
                if (data.successText) {
                    state.dialog.successText = data.successText
                }
                if (data.cancelText) {
                    state.dialog.cancelText = data.cancelText
                }
                if (data.title) {
                    state.dialog.title = data.title
                }
                state.dialog.show = true
            } else {
                state.dialog.show = false
                state.dialog.successCallback = undefined
                state.dialog.cancelCallback = undefined
                state.dialog.successStyle = 'btn-primary'
                state.dialog.successText = Messages('button.yes')
                state.dialog.cancelText = Messages('button.cancel')
                state.dialog.title = Messages('confirm.modal.title')
            }
        },

        setAppInfo(state, data) {
            state.appInfo = data
        },
        setAgencias(state, list) {
            state.agenciasAtivasList = list
        },
        setAgenciasCode(state, list) {
            state.agenciasWithCodeAtivasList = list
        },
        setTecnicos(state, list) {
            state.tecnicosList = list
        },
        setZonasTecnicos(state, list) {
            state.zonasTecnicosList = list
        },
        setGruposTecnicos(state, list) {
            state.gruposTecnicosList = list
        },
        setZonasAgencias(state, list) {
            state.zonasAgenciasList = list
        },
        setUsersList(state, list) {
            state.usersList = list
        },
        setLoggedUser(state, result) {
            state.loggedUser = result
        },
        setProfilesSelectList(state, list) {
            state.profilesSelectList = list
        }


    },

    actions: {
        loadAppInfo({commit}) {
            const url = jsRoutes.controllers.Application.loadAppInfo().url;

            return axios.get(url)
                .then(response => {
                    commit('setAppInfo', response.data)
                })
                .catch(error => {
                    return error
                });

        },

        loadAgenciasAtivasSelect({commit}) {
            const url = jsRoutes.controllers.ListasComunsController.loadClientesSelect().url;

            return axios.get(url)
                .then(response => {
                    commit('setAgencias', response.data)
                })
                .catch(error => {
                    commit('addAlert', {
                        title: Messages('error.modal.title'),
                        message: error.response.data,
                        type: 'error'
                    });
                });

        },

        loadAgenciasWithCodeAtivasSelect({commit}) {
            const url = jsRoutes.controllers.ListasComunsController.loadClientesWithCodeSelect().url;

            return axios.get(url)
                .then(response => {
                    commit('setAgenciasCode', response.data)
                })
                .catch(error => {
                    commit('addAlert', {
                        title: Messages('error.modal.title'),
                        message: error.response.data,
                        type: 'error'
                    });
                });

        },

        loadTecnicosSelect({commit}) {
            const url = jsRoutes.controllers.ListasComunsController.loadTecnicosSelect().url;
            return axios.get(url)
                .then(response => {
                    commit('setTecnicos', response.data)
                })
                .catch(error => {
                    commit('addAlert', {
                        title: Messages('error.modal.title'),
                        message: error.response.data,
                        type: 'error'
                    });
                });

        },

        loadZonasTecnicoSelect({commit}) {
            const url = jsRoutes.controllers.ListasComunsController.loadZonasTecnicoSelect().url;
            return axios.get(url)
                .then(response => {
                    commit('setZonasTecnicos', response.data)
                })
                .catch(error => {
                    commit('addAlert', {
                        title: Messages('error.modal.title'),
                        message: error.response.data,
                        type: 'error'
                    });
                });

        },

        loadGruposTecnicoSelect({commit}) {
            const url = jsRoutes.controllers.ListasComunsController.loadGruposTecnicoSelect().url;
            return axios.get(url)
                .then(response => {
                    commit('setGruposTecnicos', response.data)
                })
                .catch(error => {
                    commit('addAlert', {
                        title: Messages('error.modal.title'),
                        message: error.response.data,
                        type: 'error'
                    });
                });

        },

        loadZonasAgenciasSelect({commit}) {
            const url = jsRoutes.controllers.ListasComunsController.loadZonasClienteSelect().url;
            return axios.get(url)
                .then(response => {
                    commit('setZonasAgencias', response.data)
                })
                .catch(error => {
                    commit('addAlert', {
                        title: Messages('error.modal.title'),
                        message: error.response.data,
                        type: 'error'
                    });
                });

        },

        loadUsersList({commit}) {
            let url = jsRoutes.controllers.AdminController.getUsersList().url;

            return axios.get(url).then(response => {
                // handle success
                // console.log(response);
                commit('setUsersList', response.data.list)
                commit('setLoggedUser', response.data.loggedUserId)
            })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
                .finally(function () {
                    // always executed
                });
        },
        loadProfilesSelect({commit}) {
            let url = jsRoutes.controllers.AdminController.loadSimpleProfiles().url;
            axios.get(url)
                .then(function (response) {
                    // handle success
                    console.log(response);
                    commit('setProfilesSelectList', response.data)
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                })
                .finally(function () {
                    // always executed
                });

        },

        deleteUser({commit, dispatch, state}, payload) {
            // console.log('delete user', obj)
            let url = jsRoutes.controllers.AdminController.deleteUser(payload.id).url;
            return axios.get(url)
                .then((response) => {
                    dispatch('loadUsersList')
                })

        }


    },

    modules: {
        pedidos,
        folhasobra,
        planeamento,
        artigos,
        contratos,
        servicos,
        agencias,
        users,
        user
    }


})
