<template>
    <div class="row border-top pt-2 mb-4">

        <div class="col-12" v-show="state.artigosFolhaObra.length > 0">
            <h4>
                {{ messages("panel.fo.tec.material.aplicado.title") }}
            </h4>
        </div>

        <div class="col-12" v-show="state.artigosFolhaObra.length > 0">

            <div class="row">
                <div class="offset-1 col-4">
                    {{ messages('label.artigo') }}
                </div>
                <div class="col-3 text-center">
                    {{ messages('label.origem') }}
                </div>
                <div class="col-2 text-center">
                    {{ messages('label.abr.quantity') }}
                </div>
                <div class="col-2 text-center">
                    <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                        €
                    </profiles-allowed>
                </div>

            </div>

            <div class="row align-middle mt-1" v-for="(artigo) in $v.state.artigosFolhaObra.$each.$iter" :key="artigo.id">
                <div class="col-1"></div>

                <div class="col-4">
                    {{ artigo.$model.descricao }}
                </div>

                <div class="col-3 text-center">
                    {{ artigo.$model.origem }}
                </div>

                <div class="col-2 text-center">
                    {{artigo.$model.quantidade}}
                </div>

                <div class="col-2 text-right">
                    <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                        <div v-if="artigo.$model.origem !== 'CGD' ">
                            {{ artigo.$model.precoDisplay }}
                        </div>

                    </profiles-allowed>
                </div>

            </div>
            <div class="col-13 text-right">
                <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                    TOTAL: {{totalAppliedMaterial()}}€
                </profiles-allowed>
            </div>
        </div>


    </div>
</template>

<script>
import {computed, onMounted, reactive, ref} from '@vue/composition-api'
import {required} from 'vuelidate/lib/validators'
import {messagesMixin} from "../../../mixins/messagesMixin";

export default {
    name: "MaterialAplicadoFinalPanel",
    mixins: [messagesMixin],


    setup(props, {root: {$store}}) {

        onMounted(() => {
            $store.dispatch('artigos/loadArtigosSelect')
            $('[data-toggle="tooltip"]').tooltip();
        })

        const state = reactive({
            artigosList: computed(() => $store.getters['artigos/getArtigosSelectList']
                .filter(value => !state.artigosFolhaObra.find(val => val.artigoId === value.id))),

            artigosFolhaObra: computed(() => $store.getters['folhasobra/getSelectedFolhaObra'].artigosFolhaObra),

        })

        return {
            state,
        }

    },

    validations: {
        state: {
            artigosFolhaObra: {
                $each: {
                    origem: {
                        required
                    },
                    quantidade: {
                        required
                    }

                }
            }
        }
    },

    methods: {
        totalAppliedMaterial: function () {
            let totalArtigos=0;
            for (let i=0; i < this.state.artigosFolhaObra.length; i++){
                if(this.state.artigosFolhaObra[i].origem ==="CGD"){
                    continue;
                }
                let preco = (this.state.artigosFolhaObra[i].precoDisplay).split("€");
                totalArtigos+= this.state.artigosFolhaObra[i].quantidade * parseFloat(preco[0].replace(",","."));
            }
            return parseFloat(totalArtigos).toFixed(2);
        },
    },
}
</script>

<style scoped>

</style>
