<template>

    <div class="card">

        <div class="card-header">
            <h6>{{ title }}</h6>
        </div>

        <ul class="list-group">

            <li class="list-group-item list-group-item-light">
                <div class="row">
                    <div class="col-1 text-center">{{ messages('relatorio.testes.header.order') }}</div>
                    <div class="col-2">{{ messages('relatorio.testes.header.equipment') }}</div>
                    <div class="col-2">{{ messages('relatorio.testes.header.test') }}</div>
                    <div class="col">{{ messages('relatorio.testes.header.description') }}</div>
                    <div class="col-1 text-center">{{ messages('relatorio.testes.header.result') }}</div>
                </div>
            </li>

            <li class="list-group-item" v-for="item in listItems" :key="item.id">
                <div class="row">
                    <div class="col-1 text-center">{{ item.ordem }}</div>
                    <div class="col-2" v-if="item.equipamento !== null">{{ item.equipamento }}</div>
                    <div class="col-2" v-if="item.teste !== null">{{ item.teste }}</div>
                    <div class="col">{{ item.descricaoTeste }}</div>
                    <div class="col-1 text-center">
                        <button class="btn btn-sm"
                                @click="item.result = changeStatus(item.result)"
                                :class="getResultClass(item.result)">
                            <font-awesome-icon
                                :icon="getResultIcon(item.result)"
                                size="lg">
                            </font-awesome-icon>
                        </button>
                    </div>
                </div>
            </li>
        </ul>

    </div>


</template>

<script>
import {changeStatus, getButtonStyleForBool, getIconForBool} from "../../../compositionfn/utils";
import {messagesMixin} from "../../../mixins/messagesMixin";

export default {
    name: "RelatoriosTesteResultsPanel",
    props: ['title', 'listItems'],
    mixins: [messagesMixin],

    setup() {

        const getResultIcon = (result) => getIconForBool(result)
        const getResultClass = (result) => getButtonStyleForBool(result)

        return {
            getResultIcon,
            getResultClass,
            changeStatus
        }
    }
}
</script>

<style scoped>

</style>
