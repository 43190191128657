<template>

    <div class="card">

        <div class="card-header">
            <h3>{{ messages("panel.planeamento.title") }}</h3>
        </div>

        <ul class="list-group">

            <!-- FILTRO -->
            <li class="list-group-item">

                <profiles-allowed :authorized-profiles="['admin', 'backoffice', 'coordenacao']">

                    <div class="row">

                        <div class="col-6">
                            <div class="form-row form-group">
                                <label class="col-form-label">{{ messages("label.tecnico") }}</label>
                                <div class="col">
                                    <select class="custom-select" v-model="filterData.tecnico">
                                        <option :value="null">-- {{ messages("label.tecnico") }} --</option>
                                        <option :value="tec.id" :key="tec.id" v-for="tec in state.tecnicos">{{
                                                tec.text
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-row form-group">
                                <label class="col-form-label">{{ messages("label.estado.fo") }}</label>
                                <div class="col">
                                    <select class="custom-select" v-model="filterData.estado">
                                        <option :value="null">-- {{ messages("label.estado.fo") }} --</option>
                                        <option v-for="estado in state.estados" :value="estado.id" :key="estado.id">
                                            {{ messages(estado.key) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>

                </profiles-allowed>

                <div class="row">

                    <div class="col">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{ messages("label.visualization.type") }}</label>

                            <div class="col">
                                <select class="custom-select" v-model="state.tipoVisualizacao">
                                    <option value="mes">{{ messages("label.visualization.type.month") }}</option>
                                    <option value="dias">{{ messages("label.visualization.type.days") }}</option>
                                </select>
                            </div>

                            <div class="col">
                                <select class="custom-select" v-model="filterData.tipoApresentacao">
                                    <option value="ocupa">{{ messages("label.visualization.type.occupation") }}</option>
                                    <option value="agenda">{{ messages("label.visualization.type.book") }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

                <!--ALTERAÇAO NOVOS FILTROS-->
                <profiles-allowed :authorized-profiles="['admin', 'backoffice', 'coordenacao']">
                <div class="row">

                    <div class="col-6">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{ messages("label.empresa.user") }}</label>

                            <div class="col">
                                <select class="form-control"
                                        v-model="filterData.empresa">
                                    <option :value="null">-- {{ messages("label.empresa.user") }} --</option>
                                    <option v-for="empresa in state.empresas" :value="empresa.id" :key="empresa.id">{{ messages(empresa.id) }}</option>
                                </select>

                            </div>
                        </div>
                    </div>

                    <!--ZONA AGENCIA-->
                    <div class="col-6">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{ messages("label.zona.cliente") }}</label>
                               <div class="col">
                                <select class="custom-select" v-model="filterData.zonaAgenciaId">
                                    <option :value="null">--{{ messages("label.zona.cliente") }}--</option>
                                    <option :value="zona" :key="zona.id" v-for="zona in state.zonasAgencias">{{
                                            zona.text
                                        }}
                                    </option>
                                </select>
                                </div>
                        </div>
                    </div>

                </div>
                <!--FIM ALTERAÇÃO-->
                </profiles-allowed>

            </li>


            <div class="list-group-item h-100">
                <transition name="component-fade" mode="out-in">


                    <calendar v-if="state.tipoVisualizacao === 'mes'"
                              :current-month="filterData.month"
                              :is-tecnico="state.isTecnico"
                              @month-changed="monthChanged"
                              @view-day="inspectDay"
                              :listaeventos="state.eventos">
                    </calendar>

                    <day-viewer v-else
                                :lista-eventos="state.eventos"
                                v-on:period-changed="setPeriod"
                                :current-date.sync="state.currentDay"
                                :is-tecnico="state.isTecnico">
                    </day-viewer>


                </transition>

            </div>

        </ul>
    </div>
</template>

<script>
import {computed, onMounted, reactive, watch} from '@vue/composition-api'

import {messagesMixin} from "@/mixins/messagesMixin";
import Calendar from "../../components/calendar/Calendar";
import DayViewer from "../../components/day-viewer/DayViewer";

export default {
    name: "PlaneamentoMainPanel",

    mixins: [messagesMixin],
    components: {
        Calendar,
        DayViewer
    },

    setup(props, {root: {$store, $nextTick}}) {

        onMounted(() => {
            $store.dispatch('loadTecnicosSelect')
            $store.dispatch('loadZonasTecnicoSelect')
            $store.dispatch('loadGruposTecnicoSelect')
            $store.dispatch('loadZonasAgenciasSelect')
            setLimitDates()
            loadData()
            //console.log("AlreadyLoaded  -  ", state.alreadyLoaded)

        })

        const state = reactive({
            tecnicos: computed(() => $store.getters['getTecnicosSelect']),
            zonasTecnicos: computed(() => $store.getters['getZonasTecnicosSelect']),
            gruposTecnicos: computed(() => $store.getters['getGruposTecnicosSelect']),
            estados: computed(() => $store.getters['folhasobra/getEstados']),
            tipoVisualizacao: 'mes',

            isTecnico: computed(() => $store.getters['getUserProfile'] === 'tecnico'),
            currentDay: moment().format('DD-MM-YYYY'),

            eventos: computed(() => $store.getters['planeamento/getPlaneamentoData']),
            tecnicoId: computed(() => $store.getters['getLoggedUserId']),

            alreadyLoaded: false,

            //NOVO
            empresas: computed(()=>$store.getters['planeamento/getEmpresas']),
            zonasAgencias: computed(()=>$store.getters['getZonasAgencias'])


        })


        const filterData = reactive({
            dateFrom: '',
            dateTo: '',
            tipoApresentacao: 'agenda',
            estado: null,
            tecnico: null,
            grupoTecnicoId: null,
            zonaTecnicoId: null,
            month: moment().format('MM-YYYY'),
            empresa: null, /* NOVO */
            zonaAgenciaId: null
        })

        const loadData = () => {
            // console.log('load data')
            $store.dispatch('planeamento/loadPlaneamentoData', filterData)
                .then(() => {
                    state.alreadyLoaded = true
                    $nextTick(() => {
                        $('[data-toggle="tooltip"]').tooltip();
                    })
                })

        }

        const setLimitDates = () => {
            // console.log('setLimitDates')
            let firstDayOfMonth = moment(filterData.month, 'MM-YYYY').startOf('month')
            let firstWeeksDay = moment(firstDayOfMonth).startOf('isoWeek')

            filterData.dateFrom = moment(firstWeeksDay).format('DD-MM-YYYY')
            let lastDay = moment(filterData.month, 'MM-YYYY').endOf('month')
            let lasttWeeksDay = moment(lastDay).endOf('isoWeek')
            filterData.dateTo = moment(lasttWeeksDay).format('DD-MM-YYYY')
        }


        watch(() => state.tipoVisualizacao, (value, oldValue) => {
            // console.log('watch tipoVisualizacao', value, oldValue)
            if (oldValue) {
                setLimitDates()
            }
        })

        watch(() => filterData.tecnico, (value, oldValue) => {
            if(!state.alreadyLoaded){
                return
            }
            if (value) {
                loadData()
            } else {
                filterData.tecnico = null;
                loadData();
            }
        })

        watch(() => filterData.tipoApresentacao, (value, oldValue) => {
            // console.log('watch filterData.tipoApresentacao', value, oldValue)
            if(!state.alreadyLoaded){
                return
            }
            if (oldValue) {
                loadData()
            }
        })

        watch(() => state.isTecnico, (value) => {
            //console.log('watch state.isTecnico', value)
            if(!state.alreadyLoaded){
                return
            }
            if (value) {
                filterData.tecnico = state.tecnicoId;
                loadData()
            }
        })

        watch(() => filterData.estado, (value) => {
            // console.log('watch filterData.estado', value)
            if(!state.alreadyLoaded){
                return
            }
            loadData()

        })

        watch(() => filterData.month, (value)=>{
            if(!state.alreadyLoaded){
                return
            }
            loadData()
        })

       watch(() => filterData.empresa, (value)=>{
           if(!state.alreadyLoaded){
               return
           }
           filterData.empresa = value
           loadData()
        })

        watch(() => filterData.zonaAgenciaId, (value)=>{
            if(!state.alreadyLoaded){
                return
            }
            filterData.zonaAgenciaId = value
            loadData()
        })

        const monthChanged = ($event) => {
            // console.log("monthChanged %s", $event);
            filterData.month = $event;
            setLimitDates()
        }

        const inspectDay = (day) => {
            state.tipoVisualizacao = 'dias'
            state.currentDay = day
        }

        const setPeriod = (period) => {
            filterData.dateFrom = period.start
            filterData.dateTo = period.end
            loadData();
        }




       /* function reloadPage() {
            if(!window.location.hash) {
                window.location = window.location + '#loaded';
                window.location.reload();
            }
        }*/


        return {
            state,
            filterData,
            monthChanged,
            setLimitDates,
            inspectDay,
            setPeriod
        }

    }

}

</script>

<style scoped>

.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
