<template>
    <div class="modal" tabindex="-1" role="dialog" ref="faturacaoFilterModal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ messages("button.export.billing.info") }}
                    </h5>
                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="card-body">


                    <div class="form-row form-group mb-4 border-bottom">
                        <label class="col-form-label">{{ messages("label.date.from") }}</label>
                        <div class="col-auto">
                            <date-picker :date="filterData.dateFrom"
                                         v-on:day-selected="filterData.dateFrom = $event; loadFOs()"></date-picker>
                        </div>
                        <label class="col-form-label">{{ messages("label.date.to") }}</label>
                        <div class="col-auto">
                            <date-picker :date="filterData.dateTo"
                                         v-on:day-selected="filterData.dateTo = $event; loadFOs()"></date-picker>
                        </div>


                        <label class="col-form-label col text-right">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input"
                                       type="radio"
                                       name="exportTypeRadios"
                                       id="inlineCheckbox1"
                                       v-model="state.exportType"
                                       value="exportList">
                                <label class="form-check-label"
                                       for="inlineCheckbox1">{{ messages('label.export.folhaobras.list') }}</label>
                            </div>

                            <div class="form-check form-check-inline">
                                <input class="form-check-input"
                                       type="radio"
                                       name="exportTypeRadios"
                                       id="inlineCheckbox2"
                                       v-model="state.exportType"
                                       value="exportItem">
                                <label class="form-check-label"
                                       for="inlineCheckbox2">{{ messages('label.export.folhaobra') }}</label>
                            </div>
                        </label>

                    </div>

                    <div v-if="state.exportType === 'exportList'"
                         class="form-row form-group">
                        <label class="col-form-label">{{ messages("label.tipo.fo") }}</label>
                        <div class="col">
                            <select class="custom-select"
                                    @focus="state.tipoError = false"
                                    :class="{'is-invalid': state.tipoError}"
                                    v-model="filterData.tipo">
                                <option :value="null">-- {{ messages("label.tipo.fo") }} --</option>
                                <option v-for="tipo in state.tipos" :key="tipo.id" :value="tipo.id">
                                    {{ messages(tipo.key) }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div v-else
                         class="form-row form-group">
                        <label class="col-form-label">{{ messages("label.fo") }}</label>
                        <div class="col">
                            <select class="custom-select"
                                    @focus="state.foError = false"
                                    :class="{'is-invalid': state.foError}"
                                    v-model="filterData.folhaObraId">
                                <option :value="null">-- {{ messages("label.fo") }} --</option>
                                <option v-for="tipo in state.fosSelect" :key="tipo.id" :value="tipo.id">
                                    {{ tipo.text }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div v-if="filterData.tipo === 'OBRAS'" class="form-row form-group">
                        <label class="col-form-label">{{ messages("label.client.request.number") }}</label>
                        <div class="col-2">
                            <input type="text" align="left" class="form-control"
                                   v-model="filterData.nPedido">
                        </div>
                    </div>

                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                            @click="closeModal">
                        {{ messages('button.close') }}
                    </button>

                    <button class="btn btn-info"
                            @click="exportarInformacao">
                        {{ messages("button.export.billing.info") }}&nbsp;
                        <font-awesome-icon icon="file-excel"></font-awesome-icon>
                    </button>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
import {computed, reactive, ref, watch} from '@vue/composition-api'
import {messagesMixin} from "../../../mixins/messagesMixin";

export default {
    name: "FaturacaoFilterModal",
    mixins: [messagesMixin],

    setup(props, {root: {$store}}) {
        const faturacaoFilterModal = ref(null)

        const state = reactive({
            tipos: computed(() => $store.getters['pedidos/getTiposPedidoFaturacao']),
            fosSelect: computed(() => $store.getters['folhasobra/getFolhasObraSelectList']),
            tipoError: false,
            foError: false,
            exportType: 'exportList'
        })

        const filterData = reactive({
            dateFrom: moment().subtract(1, 'months').format('DD-MM-YYYY'),
            dateTo: moment().format('DD-MM-YYYY'),
            tipo: null,
            folhaObraId: null,
            nPedido: ''
        })

        watch(() => state.exportType, (value) => {
            // console.log('watch(state.exportType', value)
            if (value === 'exportItem') {
                loadFOs()
            }
        })


        function loadFOs() {
            if (state.exportType === 'exportItem') {
                filterData.folhaObraId = null
                $store.dispatch('folhasobra/loadFolhasObraSelectList', {
                    from: filterData.dateFrom,
                    to: filterData.dateTo,
                    nPedido : filterData.nPedido
                })
            }
        }

        const exportarInformacao = () => {
            if (!filterData.tipo && state.exportType === 'exportList') {
                state.tipoError = true
                return
            }

            if (!filterData.folhaObraId && state.exportType === 'exportItem') {
                state.foError = true
                return
            }

            $store.dispatch('folhasobra/exportarInfoFaturacao', filterData)
                .then(() => {
                    closeModal()
                    filterData.nPedido=''
                })

        }


        function showModal() {
            state.foError = false
            state.tipoError = false
            filterData.dateFrom = moment().subtract(1, 'months').format('DD-MM-YYYY')
            filterData.dateTo = moment().format('DD-MM-YYYY')
            filterData.tipo = null
            filterData.folhaObraId = null
            $(faturacaoFilterModal.value).modal('show')
        }

        function closeModal() {
            $(faturacaoFilterModal.value).modal('hide')
        }

        return {
            state,
            faturacaoFilterModal,
            showModal,
            closeModal,
            filterData,
            exportarInformacao,
            loadFOs
        }
    }
}
</script>

<style scoped>

</style>
