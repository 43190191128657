<template>

    <div class="main-container">
        <transition-group name="slide-up" tag="div">
            <div class="alert  "
                 :class="{'alert-success' : alert.type === 'success', 'alert-danger' : alert.type === 'error', 'alert-warning' : alert.type === 'warning', 'alert-info' : alert.type === 'info'}"
                 role="alert"
                 :key="alert.id"
                 v-for="alert in messagesList">
                <h4 class="alert-heading">{{ alert.title }}</h4>
                <p v-html="alert.message"></p>
                <hr v-show="!!alert.helpText">
                <p v-show="!!alert.helpText" class="mb-0" v-html="alert.helpText"></p>
            </div>
        </transition-group>
    </div>


</template>

<script>
    import {reactive, computed, onMounted, ref} from '@vue/composition-api';

    /*
    Alert types:
    - success
    - error
    - warning
    - info
     */


    export default {
        name: "AlertPopup",

        setup(props, {root: {$store}}) {
            // console.log('alert popup context:')

            const messagesList = computed(() => $store.getters.getAlertsList)


            const state = reactive({
                title: '',
                message: 'Aww yeah, you successfully read this important alert message.<br>' +
                    'This example text is going to run a bit longer so that you can see how spacing within an alert works ' +
                    'with this kind of content.',
                helpText: 'Whenever you need to, be sure to use margin utilities to keep things nice and tidy.'
            })


            return {
                state,
                messagesList
            }
        }
    }
</script>

<style scoped>

    .main-container {
        width: 380px;
        position: fixed;
        top: 40px;
        right: 50px;
        z-index: 1050;
    }


    .slide-up-enter-active,
    .slide-up-leave-active {
        transition: all 200ms ease;
    }

    .slide-up-move {
        transition: all 200ms ease 50ms;
    }

    .slide-up-enter {
        opacity: 0;
        transform: translateY(40px);
    }

    .slide-up-leave-to {
        opacity: 0;
        transform: translateY(-40px);
    }


</style>
