<template>

    <div class="card border-top pt-2">

        <div class="card-body">
            <div class="form-row form-group">
                <label class="col-form-label">{{ messages("label.guia.number") }}:
                </label>
                <div class="col-5">
                    <input type="text"
                           :class="{'is-invalid': $v.objectData.guiaTransporte.$error}"
                           class="form-control " v-model="objectData.guiaTransporte">
                    <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                </div>
            </div>

            <div class="form-row form-group">

                <label class="col-form-label col-5">
                    {{ messages("folhasobra.outrostipos.label.registo.dossier") }}
                </label>
                <div class="col-auto col-form-label" >
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="objectData.registoDossier"
                               name="registoRadioOptions" id="inlineRadio1"
                               :value="true">
                        <label class="form-check-label"
                               :class="{'text-danger': $v.objectData.registoDossier.$error}"
                               for="inlineRadio1" >{{ messages('label.yes') }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="objectData.registoDossier"
                               name="registoRadioOptions" id="inlineRadio2"
                               :value="false">
                        <label class="form-check-label "
                               :class="{'text-danger': $v.objectData.registoDossier.$error}"
                               for="inlineRadio2">{{ messages('label.no') }}</label>
                    </div>

                    <br>
                    <small v-show="$v.objectData.registoDossier.$error" class="text-danger">{{ messages("error.field.required") }}</small>

                </div>
                <div class="col">
                    <input type="text" class="form-control"
                           :class="{'is-invalid': $v.objectData.registoDossierMotivo.$error}"
                           :placeholder="messages('label.motivo')"
                           v-model="objectData.registoDossierMotivo">
                    <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                </div>
            </div>

            <div class="form-row form-group">
                <label class="col-form-label col-5">
                    {{ messages("folhasobra.outrostipos.label.pedido.encerrado") }}
                </label>
                <div class="col-auto col-form-label">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="objectData.pedidoEncerrado"
                               name="pedidoEncerradoRadioOptions"
                               id="inlineRadio3"
                               :value="true">
                        <label class="form-check-label"
                               :class="{'text-danger': $v.objectData.pedidoEncerrado.$error}"
                               for="inlineRadio1">{{ messages('label.yes') }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="objectData.pedidoEncerrado"
                               name="pedidoEncerradoRadioOptions"
                               id="inlineRadio4"
                               :value="false">
                        <label class="form-check-label"
                               :class="{'text-danger': $v.objectData.pedidoEncerrado.$error}"
                               for="inlineRadio2">{{ messages('label.no') }}</label>
                    </div>
                    <br>
                    <small v-show="$v.objectData.pedidoEncerrado.$error" class="text-danger">{{ messages("error.field.required") }}</small>
                </div>
                <div class="col">
                    <input type="text" class="form-control"
                           :placeholder="messages('label.motivo')"
                           :class="{'is-invalid': $v.objectData.pedidoEncerradoMotivo.$error}"
                           v-model="objectData.pedidoEncerradoMotivo">
                    <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                </div>
            </div>

            <material-aplicado-panel ref="materialsPanel"></material-aplicado-panel>

            <div class="form-group">
                <label class="col-form-label">
                    {{ messages("folhasobra.outrostipos.descricao.servicos") }}:
                </label>
                <textarea class="form-control"
                          :class="{'is-invalid': $v.objectData.descricaoServicosPrestados.$error}"
                          rows="4"
                          v-model="objectData.descricaoServicosPrestados"></textarea>
                <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
            </div>

        </div>

    </div>

</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators'
import {computed, ref} from '@vue/composition-api'

import {messagesMixin} from "@/mixins/messagesMixin";
import MaterialAplicadoPanel from "./MaterialAplicadoPanel";


export default {
    name: "OutrosTiposPanel",
    components: {MaterialAplicadoPanel},
    mixins: [messagesMixin],

    setup(props, {root: {$store}}) {

        const materialsPanel = ref(null)


        const objectData = computed(() => $store.getters['folhasobra/getOutrosTiposData']);

        function validateResults() {
            // console.log('OutrosTiposPanel validateResults')
            return materialsPanel.value.validateMaterials()

        }

        return {
            materialsPanel,
            objectData,
            validateResults,
        }

    },

    validations: {
        objectData: {
            guiaTransporte: {
                required
            },
            registoDossier: {
                required
            },
            pedidoEncerrado: {
                required
            },
            descricaoServicosPrestados:{
                required
            },

            registoDossierMotivo:{
                required: requiredIf((model) => {
                    // console.debug('model', model)
                    if(model.registoDossier === null){
                        return false
                    }
                    return !model.registoDossier
                })
            },

            pedidoEncerradoMotivo:{
                required: requiredIf((model) => {
                    // console.debug('model', model)
                    if(model.pedidoEncerrado === null){
                        return false
                    }
                    return !model.pedidoEncerrado
                })
            },


        }
    },

    methods: {

        isDataValid() {
            this.$v.$touch()
            return !this.$v.objectData.$invalid
        },
    }

}
</script>

<style scoped>

</style>
