<template>

    <div class="row justify-content-center">

        <div class="col-auto">
            <ul class="pagination">

                <li class="page-item" :class="{ disabled: !canDecrease }">
                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="decreasePage">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>

                <li class="page-item" v-for="page in pagesList" :key="page.number"
                    :class="{ active: page === selectedPage }">
                    <a class="page-link"
                       href="#"
                       @click.prevent="goToPage(page)">{{ page }}</a>
                </li>

                <li class="page-item" :class="{ disabled: !canIncrease }">
                    <a class="page-link" href="#" aria-label="Next" @click.prevent="increasePage">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>

            </ul>
        </div>

    </div>


</template>

<script>
export default {
    name: "Paginator",

    props: {
        totalItems: {
            type: Number,
            default() {
                return 10
            }
        },

        itemsPerPage: {
            type: Number,
            default() {
                return 10
            }
        },

        selectedPage: {
            type: Number,
            default() {
                return 1
            }
        },
        maxPages: {
            type: Number,
            required: false,
            default() {
                return 10
            }

        },
    },

    data() {
        return {
            upperLimit: 0,
            lowerLimit: 1,
        }
    },

    mounted() {
        this.upperLimit = this.maxPages
    },

    computed: {

        pagesList() {
            let list = []
            let first = this.lowerLimit
            let last = this.totalPages

            if (last > this.maxPages) {
                last = this.upperLimit
                let midList = Math.floor(this.maxPages / 2)
                if (this.selectedPage === last) {
                    first = last - midList
                    last = first + this.maxPages
                } else if (this.selectedPage === first) {
                    first = this.lowerLimit - midList
                    if (first < 1) {
                        first = 1
                        last = this.maxPages
                    } else {
                        last = first + this.maxPages
                    }
                }
            } else {
                first = 1
            }

            last = last > this.totalPages ? this.totalPages : last
            //this.lowerLimit = first
            //this.upperLimit = last
            this.setUpperLimit(last)
            this.setLowerLimit(first);
            for (let i = first; i <= last; i++) {
                list.push(i)
            }
            return list
        },

        totalPages() {
            let result = this.totalItems / this.itemsPerPage;
            result = result < 1 ? 1 : Math.ceil(result)
            if (result < this.selectedPage) {
                this.goToPage(result)
            }
            return result
        },

        canDecrease() {
            return this.selectedPage > 1;
        },

        canIncrease() {
            return this.selectedPage < this.totalPages;
        },

    },

    methods: {

        increasePage() {
            // console.log("increasePage")
            let page = this.selectedPage + 1
            this.goToPage(page)
        },

        decreasePage() {
            // console.log("decreasePage")
            let page = this.selectedPage - 1
            this.goToPage(page)
        },

        goToPage(page) {
            // console.log("go To Page %s", page)
            this.$emit('page-changed', page)
        },

        setLowerLimit(first) {
            this.lowerLimit = first
        },

        setUpperLimit(last) {
            this.upperLimit = last
        }
    },


}
</script>

<style scoped>

</style>
