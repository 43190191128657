<template>

    <div class="card">

        <div class="card-header">
            <div class="row">

                <div class="col">
                    <h3>{{ messages("panel.pedidos.create.title") }}</h3></div>

                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/pedidos'}"
                                 class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{ messages("button.back") }}
                    </router-link>
                </div>
            </div>

        </div>

        <div class="card-body">

            <div class="row">
                <div class="col">
                    <div class="form-group form-row">
                        <label class="col-form-label">{{ messages("label.client") }}</label>
                        <div class="col">
                            <select class="form-control" :class="{'is-invalid': $v.pedido.clienteId.$error }"
                                    v-model="pedido.clienteId">
                                <option :value="null">-- {{ messages("label.client") }} --</option>
                                <option v-for="cliente in clientes" :value="cliente.id" :key="cliente.id">{{ cliente.text }}</option>
                            </select>
                            <span v-if="!$v.pedido.clienteId.required"
                                  class="invalid-feedback">{{ messages("error.field.required") }}</span>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group form-row">
                        <label class="col-form-label">{{ messages("label.client.request.number") }}</label>
                        <div class="col">
                            <input type="text" class="form-control" :class="{'is-invalid': $v.pedido.numeroPedidoCliente.$error }"
                                   v-model="pedido.numeroPedidoCliente"
                                   :placeholder="messages('label.client.request.number') + '...'">
                            <span v-show="!$v.pedido.numeroPedidoCliente.required"
                                  class="invalid-feedback">{{ messages("error.field.required") }}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col">

                    <div class="form-group form-row">
                        <label class="col-form-label">{{ messages("label.order.by") }}</label>
                        <div class="col">
                            <input type="text" class="form-control" v-model="pedido.pedidoPor"
                                   :placeholder="messages('label.order.by') + '...'">
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">

                <div class="col-6">
                    <div class="form-group">
                        <label class="col-form-label">{{ messages("label.request.description") }}</label>
                        <textarea class="form-control" :class="{'is-invalid': $v.pedido.descricao.$error }" rows="8"
                                  v-model="pedido.descricao"></textarea>
                        <span v-show="!$v.pedido.descricao.required"
                              class="invalid-feedback">{{ messages("error.field.required") }}</span>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group form-row">
                        <label class="col-form-label mr-2">{{ messages("label.request.date") }}</label>
                        <date-picker :date.sync="pedido.dataPedido"></date-picker>
                        <time-picker :time.sync="pedido.horaPedido"></time-picker>
                    </div>

                    <div class="form-group form-row">
                        <label class="col-form-label">{{ messages("label.tipo.pedido") }}</label>
                        <div class="col">
                            <select class="form-control" :class="{'is-invalid': $v.pedido.tipo.$error }"
                                    v-model="pedido.tipo">
                                <option :value="null">-- {{ messages("label.tipo.pedido") }} --</option>
                                <option v-for="tipo in tiposPedido" :value="tipo.id" :key="tipo.id">{{ messages(tipo.key) }}</option>
                            </select>
                            <span v-show="!$v.pedido.tipo.required"
                                  class="invalid-feedback">{{ messages("error.field.required") }}</span>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <label class="col-form-label mr-2">{{ messages("label.pedido.refexterna") }}</label>
                        <div class="col">
                            <input type="text"
                                   class="form-control"
                                   v-model="pedido.referenciaSage">
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">

                <div class="col">
                    <div class="form-group form-row">
                        <label class="col-form-label font-weight-bold">{{messages("label.request.email")}}</label>
                        <div class="col">
                            <button class="btn btn-success btn-sm rounded" v-show="listaEmails.length < 5" v-on:click="addEmailAddress">
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                    <div class="form-group form-row" v-for="(email, idx) in $v.listaEmails.$each.$iter" :key="email.id">
                        <div class="col">
                            <input type="text" v-model="email.text.$model"
                                   class="form-control"
                                   :class="{ 'is-invalid': email.$error }">
                            <span class="invalid-feedback">{{messages("error.field.email")}}</span>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-outline-danger btn-sm rounded"
                                    v-on:click="removeEmailAddress(idx)">
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-12">

                    <div class="form-group form-row">
                        <label class="col-form-label">{{ messages('label.confidential.info') }}</label>
                        <div class="col">
                            <textarea class="form-control" rows="8"
                                      v-model="pedido.observacoesConfidenciais"></textarea>
                        </div>
                    </div>


                </div>


            </div>

        </div>

        <div class="card-footer">

            <div class="row justify-content-end">

                <div class="col-auto">

                    <router-link tag="button"
                                 :to="{path: '/pedidos'}"
                                 class="btn btn-outline-secondary mr-2">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{ messages("button.cancel") }}
                    </router-link>

                    <button class="btn btn-primary mr-2" @click="criarPedido(true)">
                        {{ messages("button.criar.agendar.pedido") }}&nbsp;
                        <font-awesome-icon icon="book"></font-awesome-icon>
                    </button>

                    <button class="btn btn-success mr-2" @click="criarPedido(false)">
                        {{ messages("button.criar.pedido") }}&nbsp;
                        <font-awesome-icon icon="save"></font-awesome-icon>
                    </button>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {required, email} from 'vuelidate/lib/validators'

import {messagesMixin} from "../../mixins/messagesMixin";
import pedidos from "@/store/modules/pedidos";


export default {
    name: "CriarPedidoPanel",
    mixins: [messagesMixin],

    data() {
        return {
            pedido: {
                pedidoId: null,
                clienteId: null,
                tipo: null,
                dataPedido: moment().format('DD-MM-YYYY'),
                horaPedido: moment().format('HH:mm'),
                sistemasId: [],

                descricao: "",
                pedidoPor: "",
                numeroPedidoCliente: "",
                emailAlt: "",
                orcamento: false,
                referenciaSage: ""

            },
            listaEmails: [],
            emailsList: [],
            pseudoId: 0,
        }
    },

    computed: {
        ...mapGetters('pedidos', {
            tiposPedido: 'getTiposPedido'
        }),

        ...mapGetters({
            clientes: 'getAgenciasAtivasSelect'
        }),

    },

    validations: {
        pedido: {
            clienteId: {
                required,
            },
            tipo: {
                required,
            },
            descricao: {
                required,
            },
            numeroPedidoCliente: {
                required,
            },
        },

        listaEmails: {
            $each: {
                text: {
                    email,
                }
            }
        },

    },

    methods: {
        ...mapActions('pedidos', {
            doCriar: 'criarPedido'
        }),


        criarPedido: function (agendar) {
            this.$v.$touch();
            if (this.$v.$invalid) {
                // console.error("validation ERROR!");
                return;
            }

            this.pedido.emailAlt = this.listaEmails.map(value => value.text).join(';');

            this.doCriar(this.pedido)
                .then(resp => {
                    if (agendar) {
                        this.$router.push({name: 'pedidoAgendar', params: {pedidoId: resp.data.pedidoId}})
                    } else {
                        this.$router.push({path: '/pedidos'})
                    }
                })
        },

        setEmail() {
            const self = this
            var url = jsRoutes.controllers.PedidoController.loadClienteData(this.pedido.clienteId).url;
            this.$http.get(url).then(function (response) {
                self.pedido.emailAlt = response.data.email;
                self.listaEmails = []
                if (self.pedido.emailAlt) {
                    const emailsList = self.pedido.emailAlt.split(';')
                    emailsList.forEach(value => {
                        self.listaEmails.push({id: self.pseudoId++, text: value})
                    })
                }
            }, function (reason) {
                showError(Messages('error.modal.title'), reason.body);
            });

        },
        addEmailAddress(){
            this.listaEmails.push({id: this.pseudoId++, text: ''})
        },

        removeEmailAddress(idx) {
            //console.log('removeEmailAddress', idx)
            this.listaEmails.splice(idx, 1)
        },
    },

    watch: {
        'pedido.clienteId': function (newVal, oldVal) {
            if (newVal !== null) {
                this.setEmail();
            }
            else {
                this.pedido.emailAlt = ''
            }
        }
    }


}

</script>

<style scoped>

</style>
