import { render, staticRenderFns } from "./FolhaObraMainPanel.vue?vue&type=template&id=1bacb607&scoped=true&"
import script from "./FolhaObraMainPanel.vue?vue&type=script&lang=js&"
export * from "./FolhaObraMainPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bacb607",
  null
  
)

export default component.exports