import axios from 'axios'
const state = {
    empresas: [
        {
            id: 'PREVEL',
            key: 'enum.empresa.prevel'
        },
        {
            id: 'GERALSEG',
            key: 'enum.empresa.geralseg'
        },
        {
            id: 'VITELSIS',
            key: 'enum.empresa.vitelsis'
        }
    ],

    usersList: [],
    profilesSelectList: []
}

const getters = {
    getUsersList: state => state.usersList,
    getLoggedUser: state => state.loggedUser,
    getProfilesSelect: state => state.profilesSelectList,
    getEmpresas: state => state.empresas

}

const mutations = {
    setUsersList(state, list){
        state.usersList = list
    },
    setLoggedUser(state, result) {
        state.loggedUser = result
    },
    setProfilesSelectList(state, list){
        state.profilesSelectList =list
    },
    setEmpresas(state, list){
        state.empresas=list
    }
}

const actions = {

    loadUsersList({commit}) {
        let url = jsRoutes.controllers.AdminController.getUsersList().url;

        return axios.get(url).then (response => {
            // handle success
            // console.log(response);
            commit('setUsersList', response.data.list)
            commit('setLoggedUser', response.data.loggedUserId)
        })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
            .finally(function () {
                // always executed
            });
    },
    loadProfilesSelect({commit}) {
        let url = jsRoutes.controllers.AdminController.loadSimpleProfiles().url;
        axios.get(url)
            .then(function (response) {
                // handle success
                console.log(response);
                commit('setProfilesSelectList', response.data)
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
            .finally(function () {
                // always executed
            });

    },

    deleteUser({commit, dispatch, state}, payload) {
        // console.log('delete user', obj)
        let url = jsRoutes.controllers.AdminController.deleteUser(payload.id).url;
        return axios.get(url)
            .then((response) => {
                dispatch('loadUsersList')
            })

    },

    criarUser({commit}, user) {
        commit('showMask', true, {root: true})
        const url =jsRoutes.controllers.AdminController.createUser().url
        return axios.post(url, user).then(response => {
            commit('addAlert', {
                title: Messages('success.modal.title'),
                message: Messages('success.msg.generic'),
                type: 'success'
            }, {root: true});
            return response
        })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })

    },

    updateUser({commit}, user){
        commit('showMask', true, {root: true})
        const url =jsRoutes.controllers.AdminController.updateUser().url
        return axios.post(url, user).then(response => {
            commit('addAlert', {
                title: Messages('success.modal.title'),
                message: Messages('success.msg.generic'),
                type: 'success'
            }, {root: true});
            return response
        })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },


}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
