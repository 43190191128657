<template>
    <div class="card">

        <div class="card-header">
            <h3>{{messages("panel.artigo.title")}}</h3>
        </div>

        <ul class="list-group">

            <!-- FILTRO -->
            <li class="list-group-item">

                <div class="row mt-4">
                    <div class="col-3">
                        <div class="form-group form-row">
                            <label class="col-form-label">{{messages("label.code")}}</label>
                            <div class="col">
                                <input class="form-control" v-model="filterData.codigo">
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group form-row">
                            <label class="col-form-label">{{messages("label.name")}}</label>
                            <div class="col">
                                <input class="form-control" v-model="filterData.descricao">
                            </div>
                        </div>
                    </div>

                    <div class="col-2 offset-1">
                        <div class="form-group form-row">
                            <input class="form-check-input" type="checkbox" id="actives-ck" v-model="filterData.ativos">
                            <label class="form-check-label"
                                   for="actives-ck">{{messages("label.stock.inactive")}}</label>
                        </div>
                    </div>

                </div>

                <div class="row justify-content-center mt-4">
                    <button class="btn btn-outline-secondary" @click="resetFilter()">{{ messages("button.clean") }}
                    </button>
                    <button class="btn btn-primary" style="margin-left: 15px;" @click="loadDataList">
                        {{messages("button.search")}}
                    </button>
                </div>

            </li>


            <!--ACTION BUTTON BAR -->

            <li class="list-group-item">
                <button class="btn btn-success"
                        data-toggle="tooltip"
                        data-placement="left" @click="artigoCreate">
                    {{messages('button.criar.artigo')}}
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                </button>
            </li>


            <!--@* TABLE DATA *@-->

            <table-list :list="artigos" :page.sync="artigosPage">
                <template #header>
                    <th scope="col">{{messages("label.code")}}</th>
                    <th scope="col">{{messages("label.name")}}</th>
                    <th scope="col" class="text-center">{{messages("label.active")}}</th>
                    <th scope="col"></th>
                </template>

                <tbody>
                <tr v-for="artigo in artigosPage" :key="artigo.id">
                    <th scope="row">
                        {{ artigo.codigo }}
                    </th>
                    <td>{{ artigo.nome }}</td>

                    <td class="text-center">
                        <i v-if="artigo.ativo" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-times text-danger"></i>
                    </td>

                    <td class="text-right">

                        <button class="btn btn-outline-primary btn-sm mr-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                :title="messages('tooltip.edit.artigo')"
                                v-on:click="artigoEdit(artigo)">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                        </button>
                        <button class="btn btn-outline-danger btn-sm"
                                :title="messages('tooltip.delete.artigo')"
                                v-on:click="deleteArtigo(artigo)">
                            <i class="fas fa-times"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table-list>
        </ul>

        <artigo-modal ref="artigoModal" v-bind:alter-mode="editMode" :artigo="artigo" :artigos-page="artigosPage"
                      :unidades="unidades"
                      @loadDataList="loadDataList" @saveArtigo="saveArtigo"></artigo-modal>

    </div>

</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'

    import {messagesMixin} from "../../mixins/messagesMixin";
    import TableList from "../../components/TableList";
    import ArtigoModal from "./ArtigoModal";

    export default {
        name: "ArtigosMainList",
        components: {TableList, ArtigoModal},
        mixins: [messagesMixin],

        mounted() {
            this.loadDataList()
        },

        data() {
            return {

                filterData: {
                    from: null,
                    to: null,
                    codigo: null,
                    nome: null,
                    ativos: true
                },

                artigosPage: [],
                editMode: false,

                artigo: {
                    id: 0,
                    codigo: "",
                    nome: "",
                    descricao: "",
                    ativo: true,
                    unidade: []
                },

            }
        },

        computed: {
            ...mapGetters('artigos', {
                artigos: 'getArtigosList',
                unidades: 'getUnidades'
            })
        },

        beforeRouteLeave(to, from, next) {
            // console.log('beforeRouteLeave')
            $('[data-toggle="tooltip"]').tooltip('hide');
            next()
        },


        methods: {

            ...mapActions('artigos', {
                doLoadArtigos: 'loadArtigos',
                doDeleteArtigo: 'deleteArtigo',
                doCriarArtigo: 'criarArtigo',
                doUpdateArtigo: 'updateArtigo',

            }),

            ...mapMutations({
                dialog: 'setDialogProps'
            }),


            loadDataList() {
                // console.log('LoadDataList')
                this.doLoadArtigos(this.filterData)
                    .then(() => {
                        this.$nextTick(function () {
                            $('[data-toggle="tooltip"]').tooltip();
                        });
                    })
            },

            resetFilter: function () {
                this.filterData.codigo = null;
                this.filterData.descricao = null;
                this.loadDataList(this.filterData);
            },

            deleteArtigo: function (artigo) {

                this.dialog({
                    show: true,
                    message: this.messages('confirm.msg.delete.artigo', artigo.codigo + " -- " + artigo.nome),
                    callback: () => {
                        this.doDeleteArtigo(artigo.id)
                            .then(() => {
                                this.loadDataList()
                            })
                    }
                })

            },

            artigoCreate() {

                this.editMode = false;
                this.showArtigoModal();
            },

            artigoEdit(artigo) {

                this.editMode = true;
                this.artigo = artigo;
                this.artigosPage.pop(artigo);
                /*this.$refs.artigoModal.showModal();*/
                this.showArtigoModal();

            },

            showArtigoModal: function () {
                this.$refs.artigoModal.showModal()
            },

            criarArtigo: function (artigo) {
                // console.log("criar artigo %o", artigo)

                this.doCriarArtigo(artigo)
                    .then(() => {
                        this.loadDataList()
                    })
                this.$refs.artigoModal.closeModal();

            },


            saveArtigo(artigo) {
                // console.log("save artigo", artigo.codigo)

                if (!this.editMode) {
                    this.criarArtigo(artigo);
                    //this.$router.go();
                } else {
                    console.log("update artigo", artigo.codigo)
                    this.doUpdateArtigo(artigo);
                    this.$router.go();
                }
            },

            resetCurrentArtigo: function () {
                this.artigo = {
                    id: 0,
                    codigo: "",
                    nome: "",
                    descricao: "",
                    ativo: true,
                    unidade: []
                };
                this.editMode = false;
            },

        }

    }
</script>

<style scoped>

</style>
