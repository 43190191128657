<template>
    <div class="modal" tabindex="-1" role="dialog" ref="infoPedidoModal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ messages("label.request") }}&nbsp;{{ selectedPedido.referenciaPedido }}
                    </h5>
                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col">
                            <p>
                                <label class="bg-secondary text-light p-2 rounded">
                                    {{ selectedPedido.clienteCode }}
                                </label> - <label>
                                {{ selectedPedido.clienteNome }}
                            </label>
                            </p>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col">
                            <p>
                                <label>{{ messages('label.estado.pedido') + ': ' }}</label>
                                <label
                                    :class="{'text-danger': selectedPedido.estado === 'CANCELADO', 'text-primary': selectedPedido.estado === 'AGENDADO', 'text-success': selectedPedido.estado === 'ABERTO'}">
                                    {{ messages(selectedPedido.estadoPedidoKey) }}
                                </label>
                            </p>
                        </div>

                        <div class="col">
                            <p>
                                <label class="col-form-label">{{ messages("label.request.date") }}:&nbsp;</label>
                                <label class="col-form-label">
                                    {{ selectedPedido.dataPedido }} {{ selectedPedido.horaPedido }}
                                </label>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <p>
                                <label class="col-form-label">{{ messages("label.request.number") }}:&nbsp;</label>
                                <label class="col-form-label">{{ selectedPedido.numeroPedidoCliente }}</label>
                            </p>
                        </div>
                        <div class="col">
                            <p>
                                <label class="col-form-label">{{ messages("label.order.by") }}:&nbsp;</label>
                                <label class="col-form-label">{{ selectedPedido.pedidoPor }}</label>
                            </p>
                        </div>


                    </div>

                    <div class="row">

                        <div class="col">
                            <p>
                                <label>{{ messages("label.request.description") }}</label>:&nbsp;
                                <label>
                                {{ selectedPedido.descricao }}
                                </label></p>
                        </div>

                        <div class="col">
                            <p><label>{{ messages("label.pedido.refexterna.abrv") }}</label>:&nbsp;<label>
                                {{ selectedPedido.referenciaSage }}</label></p>
                        </div>

                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                            @click="closeModal">
                        {{ messages('button.close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from '@vue/composition-api'
import $ from 'jquery'

import {messagesMixin} from "../../mixins/messagesMixin";

export default {
    name: "InfoPedidoModal",
    props: {
        selectedPedido: {
            type: Object,
            required: false,
            default() {
                return {
                    estadoPedidoKey: ''
                }
            }
        }
    },
    mixins: [messagesMixin],

    setup(props, ctx) {

        const infoPedidoModal = ref(null)


        function showModal() {
            $(infoPedidoModal.value).modal('show')
        }

        function closeModal() {
            $(infoPedidoModal.value).modal('hide')
        }

        return {
            infoPedidoModal,
            showModal,
            closeModal
        }

    }

}
</script>

<style scoped>

</style>
