<template>

    <div class="card">

        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h4>{{messages('relatorio.testes.panel.title', folhaObra.referenciaFolhaObra)}}</h4>
                </div>

                <div class="col-auto">

                    <router-link tag="button"
                                 :to="{name: 'folhasobrasEditar', params: {foId: folhaObra.id}}"
                                 class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.back")}}
                    </router-link>

                </div>

            </div>
        </div>

        <relatorios-teste-results-panel :title="state.preventivaSistemasItemsList.title"
                                        :list-items="state.preventivaSistemasItemsList.listItems"></relatorios-teste-results-panel>

        <relatorios-teste-results-panel :title="state.corretivaCofresItemsList.title"
                                        :list-items="state.corretivaCofresItemsList.listItems"></relatorios-teste-results-panel>

        <relatorios-teste-results-panel :title="state.corretivaSicaItemsList.title"
                                        :list-items="state.corretivaSicaItemsList.listItems"></relatorios-teste-results-panel>

        <relatorios-teste-results-panel :title="state.corretivaSadiItemsList.title"
                                        :list-items="state.corretivaSadiItemsList.listItems"></relatorios-teste-results-panel>

        <relatorios-teste-results-panel :title="state.corretivaCftvItemsList.title"
                                        :list-items="state.corretivaCftvItemsList.listItems"></relatorios-teste-results-panel>


        <div class="card-footer text-right">

            <router-link tag="button"
                         :to="{name: 'folhasobrasEditar', params: {foId: folhaObra.id}}"
                         class="btn btn-outline-secondary mr-2">
                <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                {{messages("button.cancel")}}
            </router-link>

            <button class="btn btn-primary mr-2"
                    @click="saveRelatorioTestes">
                {{messages("button.save")}}
                <font-awesome-icon icon="save"></font-awesome-icon>
            </button>

        </div>

    </div>

</template>

<script>

    import {computed, onMounted, reactive} from '@vue/composition-api'
    import RelatoriosTesteResultsPanel from "./components/RelatoriosTesteResultsPanel";
    import {messagesMixin} from "../../mixins/messagesMixin";

    export default {
        name: "RelatorioTestesMainPanel",
        components: {RelatoriosTesteResultsPanel},
        mixins: [messagesMixin],

        setup(props, {root: {$store, $route, $router}}) {
            // console.log('RelatorioTestesMainPanel', root)

            onMounted(() => {
                $store.dispatch('folhasobra/loadFolhaObraData', $route.params.foId)
            })

            const folhaObra = computed(() => $store.getters['folhasobra/getSelectedFolhaObra'])

            const state = reactive({
                preventivaSistemasItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].preventivaSistemasItemsList),
                corretivaCofresItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaCofresItemsList),
                corretivaSicaItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaSicaItemsList),
                corretivaSadiItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaSadiItemsList),
                corretivaCftvItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaCftvItemsList),
            })

            const saveRelatorioTestes = () => {

                const data = {
                    folhaObraId: folhaObra.value.id,
                    preventivaSistemasItemsList: state.preventivaSistemasItemsList.listItems,
                    corretivaCofresItemsList: state.corretivaCofresItemsList.listItems,
                    corretivaSicaItemsList: state.corretivaSicaItemsList.listItems,
                    corretivaSadiItemsList: state.corretivaSadiItemsList.listItems,
                    corretivaCftvItemsList: state.corretivaCftvItemsList.listItems,
                }
                // console.log('going to save', folhaObra.value)
                $store.dispatch('folhasobra/saveRelatorioTestes', data)
                    .then(response => {
                        $router.push({name: 'folhasobrasEditar', params: {foId: folhaObra.value.id}})
                    })

            }


            return {
                state,
                folhaObra,
                saveRelatorioTestes,
            }


        }
    }
</script>

<style scoped>

</style>
