<template>
    <div class="row border-top pt-2 mb-4">

        <div class="col-12">
            <h4>
                {{ messages("panel.fo.tec.material.aplicado.title") }}
                <button class="btn btn-success btn-sm ml-3 rounded"
                        @click="addMaterial"
                        data-toggle="tooltip" data-placement="left"
                        :title="messages('tooltip.add.material')">
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                </button>
            </h4>
        </div>

        <div class="col-12" v-show="state.artigosFolhaObra.length > 0">

            <div class="row">
                <div class="offset-1 col-4">
                    {{ messages('label.artigo') }}
                </div>
                <div class="col-3 text-center">
                    {{ messages('label.origem') }}
                </div>
                <div class="col-2 text-center">
                    {{ messages('label.abr.quantity') }}
                </div>
                <div class="col-2 text-center">
                    <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                    €
                    </profiles-allowed>
                </div>

            </div>

            <div class="row align-middle mt-1" v-for="(artigo, index) in $v.state.artigosFolhaObra.$each.$iter" :key="artigo.id">
                <div class="col-1">
                    <button class="btn btn-link text-danger"
                            @click="removeArtigo(index)">
                        <font-awesome-icon icon="times"></font-awesome-icon>
                    </button>
                </div>

                <div class="col-4">
                    {{ artigo.$model.descricao }}
                </div>

                <div class="col-3">
                    <select class="form-control"
                            v-model="artigo.origem.$model"
                            :class="{'is-invalid': artigo.origem.$error}">
                        <option :value="null">{{ messages("label.select.item") }}</option>
                        <option value="VIGERPREV">{{ messages("enum.empresa.vigerprev") }}</option>
                        <option value="PREVEL">{{ messages("enum.empresa.prevel") }}</option>
                        <option value="GERALSEG">{{ messages("enum.empresa.geralseg") }}</option>
                        <option value="VITELSIS">{{ messages("enum.empresa.vitelsis") }}</option>
                        <option value="CGD">{{ messages("enum.empresa.cgd") }}</option>
                    </select>
                    <p class="invalid-feedback">{{ messages('error.field.required') }}</p>

                </div>

                <div class="col-2">
                    <input type="number" step="1"
                           class="form-control"
                           :class="{'is-invalid': artigo.quantidade.$error}"
                           v-model="artigo.$model.quantidade">
                    <p class="invalid-feedback">{{ messages('error.field.required') }}</p>
                </div>

                <div class="col-2 text-center">
                    <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                        <div v-if="artigo.origem.$model !== 'CGD' ">
                            {{ artigo.$model.precoDisplay }}
                        </div>

                    </profiles-allowed>
                </div>


            </div>

        </div>


        <material-aplicado-modal ref="materialModal" @added="doAdd" :list="state.artigosList"></material-aplicado-modal>

    </div>
</template>

<script>
import {computed, onMounted, reactive, ref} from '@vue/composition-api'
import {required} from 'vuelidate/lib/validators'
import {messagesMixin} from "../../../mixins/messagesMixin";
import MaterialAplicadoModal from "./MaterialAplicadoModal";
import {mapGetters} from "vuex";
import artigos from "@/store/modules/artigos";

export default {
    name: "MaterialAplicadoPanel",
    components: {MaterialAplicadoModal},
    mixins: [messagesMixin],


    setup(props, {root: {$store}}) {
        // console.log('MaterialAplicadoPanel $store', $store)

        const materialModal = ref(null)

        onMounted(() => {
            $store.dispatch('artigos/loadArtigosSelect')
            $('[data-toggle="tooltip"]').tooltip();
        })

        const state = reactive({
            /*artigosList: computed(() => $store.getters['artigos/getArtigosSelectList']
                .filter(value => !state.artigosFolhaObra.find(val => val.artigoId === value.id))),*/
            artigosList: computed(() => $store.getters['artigos/getArtigosSelectList']),


            artigosFolhaObra: computed(() => $store.getters['folhasobra/getSelectedFolhaObra'].artigosFolhaObra),
        })

        function addMaterial() {
            materialModal.value.showModal()
        }

        function doAdd(ev) {
            // console.log('do add', ev)
            ev.folhaObraId = $store.getters['folhasobra/getSelectedFolhaObra'].id
            state.artigosFolhaObra.push(ev) // ArtigoFolhaObraData
        }


        function removeArtigo(idx) {
            state.artigosFolhaObra.splice(idx, 1)
        }


        return {
            state,
            materialModal,
            addMaterial,
            doAdd,
            removeArtigo
        }

    },

    validations: {

        state: {
            artigosFolhaObra: {
                $each: {
                    origem: {
                        required
                    },
                    quantidade: {
                        required
                    }

                }
            }
        }

    },

    methods: {
        validateMaterials() {
            // console.log('MaterialAplicadoPanel validateMaterials')
            this.$v.$touch()
            return this.$v.$invalid
        }


    },

}
</script>

<style scoped>

</style>
