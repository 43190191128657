<template>
    <div class="card">
        <div class="card-body">

            <div class="form-row form-group">
                <label class="col-form-label col-auto">
                    {{messages("label.guia.number")}}:
                </label>
                <label class="form-control-plaintext font-weight-bold col">
                    {{objectData.guiaTransporte}}:
                </label>
            </div>


            <div class="form-row form-group">
                <label class="col-form-label col-5">
                    {{messages("folhasobra.outrostipos.label.registo.dossier")}}
                </label>

                <div class="col-auto col-form-label">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" disabled v-model="objectData.registoDossier" name="registoRadioOptions" id="inlineRadio1"
                               :value="true">
                        <label class="form-check-label" for="inlineRadio1">{{messages('label.yes')}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" disabled v-model="objectData.registoDossier" name="registoRadioOptions" id="inlineRadio2"
                               :value="false">
                        <label class="form-check-label" for="inlineRadio2">{{messages('label.no')}}</label>
                    </div>
                </div>

                <label class="form-control-plaintext font-weight-bold col">
                    {{objectData.registoDossierMotivo}}
                </label>
            </div>


            <div class="form-row form-group">
                <label class="col-form-label col-5">
                    {{messages("folhasobra.outrostipos.label.pedido.encerrado")}}
                </label>

                <div class="col-auto col-form-label">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" disabled v-model="objectData.pedidoEncerrado" name="pedidoEncerradoRadioOptions"
                               id="inlineRadio3"
                               :value="true">
                        <label class="form-check-label" for="inlineRadio1">{{messages('label.yes')}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" disabled v-model="objectData.pedidoEncerrado" name="pedidoEncerradoRadioOptions"
                               id="inlineRadio4"
                               :value="false">
                        <label class="form-check-label" for="inlineRadio2">{{messages('label.no')}}</label>
                    </div>
                </div>

                <label class="form-control-plaintext font-weight-bold col">
                    {{objectData.pedidoEncerradoMotivo}}
                </label>

            </div>

          <!--  material aplicado-->
            <material-aplicado-final-panel ref="materialsPanel"></material-aplicado-final-panel>

            <div class="form-group">
                <label class="col-form-label">
                    {{messages("folhasobra.outrostipos.descricao.servicos")}}:
                </label>
                <label class="form-control-plaintext font-weight-bold col">
                    {{objectData.descricaoServicosPrestados}}
                </label>
            </div>

        </div>
    </div>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
    import {messagesMixin} from "../../../mixins/messagesMixin";
    import MaterialAplicadoFinalPanel from "@/views/folhasobra/components/MaterialAplicadoFinalPanel";

    export default {
        name: "OutrosTiposFinalPanel",
        mixins: [messagesMixin],
        components: {MaterialAplicadoFinalPanel},

        setup(props, {root: {$store}}) {

            const objectData = computed(() => $store.getters['folhasobra/getOutrosTiposData']);

            const materialsPanel = ref(null)

            return {
                objectData,
                materialsPanel
            }

        }
    }
</script>

<style scoped>

</style>
