<template>
    <div class="card">

        <div class="card-header">
            <h3>{{messages("panel.clientesadmin.title")}}</h3>
        </div>

        <ul class="list-group">

            <!-- FILTRO -->
            <li class="list-group-item">

                <div class="row mt-4">
                    <div class="col-3">
                        <div class="form-group form-row">
                            <label class="col-form-label">{{messages("label.code")}}</label>
                            <div class="col">
                                <input class="form-control" v-model="filterData.codigo">
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group form-row">
                            <label class="col-form-label">{{messages("label.name")}}</label>
                            <div class="col">
                                <input class="form-control" v-model="filterData.descricao">
                            </div>
                        </div>
                    </div>

                    <div class="col-2 offset-1">
                        <div class="form-group form-row">
                            <input class="form-check-input" type="checkbox" id="actives-ck" v-model="filterData.ativos">
                            <label class="form-check-label"
                                   for="actives-ck">{{messages("label.stock.inactive")}}</label>
                        </div>
                    </div>

                </div>

                <div class="row justify-content-center mt-4">
                    <button class="btn btn-outline-secondary" @click="resetFilter()">{{ messages("button.clean") }}
                    </button>
                    <button class="btn btn-primary" style="margin-left: 15px;" @click="loadDataList">
                        {{messages("button.search")}}
                    </button>
                </div>

            </li>


            <!--ACTION BUTTON BAR -->

                <li class="list-group-item">
                    <router-link tag="button"
                                 :to="{name: 'agenciasCriar'}"
                                 class="btn btn-success">
                        {{ messages('button.criar.cliente') }}&nbsp;
                        <font-awesome-icon icon="plus"></font-awesome-icon>
                    </router-link>
                </li>



            <!--@* TABLE DATA *@-->

            <table-list :list="agencias" :page.sync="agenciasPage">
                <template #header>
                    <th scope="col">{{messages("label.code")}}</th>
                    <th scope="col">{{messages("label.name")}}</th>
                    <th scope="col" class="text-center">{{messages("label.active")}}</th>
                    <th scope="col"></th>
                </template>

                <tbody>
                <tr v-for="agencia in agenciasPage" :key="agencia.id">
                    <th scope="row">
                        {{ agencia.code }}
                    </th>
                    <td>{{ agencia.nome }}</td>
                    <td class="text-center">
                        <i v-if="agencia.ativo" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-times text-danger"></i>
                    </td>

                    <td class="text-right">

                        <button class="btn btn-outline-primary btn-sm mr-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                :title="messages('tooltip.view.client')"
                                @click="editAgencia(agencia)">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                        </button>

                        <button class="btn btn-outline-danger btn-sm"
                                :title="messages('tooltip.delete.cliente')"
                                v-on:click="deleteAgencia(agencia)">
                            <i class="fas fa-times"></i>
                        </button>
                    </td>
                </tr>
                </tbody>

            </table-list>

        </ul>


    </div>

</template>
<script>
    import TableList from "../../components/TableList";
    import {messagesMixin} from "../../mixins/messagesMixin";
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "AgenciasMainList",
        components: {TableList},
        mixins: [messagesMixin],

        mounted() {
            this.loadDataList()
        },

        data(){
            return{
                filterData: {
                    codigo: "",
                    descricao: "",
                    ativos: true,

                },
                agenciasPage: [],

            }

        },

        computed: {
            ...mapGetters('agencias', {
                agencias: 'getAgenciasList',
            },),
        },

        beforeRouteLeave(to, from, next) {
            // console.log('beforeRouteLeave')
            $('[data-toggle="tooltip"]').tooltip('hide');
            next()
        },

        methods: {

            ...mapActions('agencias', {
                doLoadAgencias: 'loadAgencias',
                doDeleteAgencia: 'deleteAgencia',
            }),

            ...mapMutations({
                dialog: 'setDialogProps'
            }),

            loadDataList() {
                this.doLoadAgencias(this.filterData)
                    .then(() => {
                        this.$nextTick(function () {
                            $('[data-toggle="tooltip"]').tooltip();
                        });
                    })
            },


            resetFilter: function () {
                this.filterData.codigo = null;
                this.filterData.descricao = null;
                this.loadDataList(this.filterData);
            },

            deleteAgencia: function (agencia) {

                this.dialog({
                    show: true,
                    message: this.messages('confirm.msg.delete.cliente', agencia.code + " -- " + agencia.nome),
                    callback: () => {
                        this.doDeleteAgencia(agencia.id)
                            .then(() => {
                                this.loadDataList()
                            })
                    }
                })

            },

            editAgencia: function (agencia) {
                this.$router.push({name: 'agenciasEditar', params: {agenciaId: agencia.id}})
                return;
            }

        },


    }
</script>

<style scoped>

</style>
