import axios from 'axios'

const state = {
    tiposPedido: [
        {
            id: 'CORRETIVA',
            key: 'enum.tipopedido.corretiva'
        },
        {
            id: 'PREVENTIVA',
            key: 'enum.tipopedido.preventiva'
        },
        {
            id: 'BENS_ARREMATADOS',
            key: 'enum.tipopedido.bens.arrematados'
        },
        {
            id: 'OBRAS',
            key: 'enum.tipopedido.obras'
        },
        {
            id: 'ARROMBAMENTO_COFRES',
            key: 'enum.tipopedido.arrombamento'
        },
        {
            id: 'DIVERSOS',
            key: 'enum.tipopedido.diversos'
        },
        {
            id: 'NOTIFIER',
            key: 'enum.tipopedido.notifier'
        },
        {
            id: 'EXTINCAO',
            key: 'enum.tipopedido.extincao'
        },
        {
            id: 'EXTINTORES',
            key: 'enum.tipopedido.extintores'
        },
        {
            id: 'CFTV',
            key: 'enum.tipopedido.cftv'
        },


    ],

    estadosPedido: [
        {
            id: 'ABERTO',
            key: 'enum.estadopedido.aberto'
        },
        {
            id: 'AGENDADO',
            key: 'enum.estadopedido.agendado'
        },
        {
            id: 'CANCELADO',
            key: 'enum.estadopedido.cancelado'
        },

    ],

    pedidosList: [],


}

const getters = {
    // `getters` is localized to this module's getters
    // you can use rootGetters via 4th argument of getters
    // someGetter (state, getters, rootState, rootGetters)
    getTiposPedido: state => state.tiposPedido,
    getTiposPedidoFaturacao: state => state.tiposPedido.filter(value => value.id === 'CORRETIVA' || value.id === 'PREVENTIVA' || value.id === 'BENS_ARREMATADOS'
        || value.id === 'OBRAS' || value.id === 'ARROMBAMENTO_COFRES' || value.id === 'DIVERSOS' || value.id === 'NOTIFIER' || value.id === 'EXTINCAO'
        || value.id === 'EXTINTORES' || value.id === 'CFTV'),
    getEstadosPedido: state => state.estadosPedido,
    getPedidosList: state => state.pedidosList,

}

const mutations = {
    setPedidos: (state, list) => state.pedidosList = list,
}

const actions = {

    // dispatch and commit are also localized for this module
    // they will accept `root` option for the root dispatch/commit
    // dispatch('someOtherAction', null, { root: true })
    // commit('someMutation', null, { root: true })
    criarPedido({commit}, pedido) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.PedidoController.doCriarPedido().url;
        return axios.post(url, pedido)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.pedido.create'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },

    editarPedido({commit}, pedido) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.PedidoController.editarPedido().url;
        return axios.post(url, pedido)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.pedido.edit'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },

    loadPedidos({commit}, filterData) {
        commit('showMask', true, {root: true})

        const url = jsRoutes.controllers.PedidoController.loadPedidos().url;

        return axios.post(url, filterData)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('setPedidos', response.data)
            }).catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });
    },

    loadPedido({commit}, pedidoId) {
        // console.log('loadPedido', pedidoId)
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.PedidoController.loadPedido(pedidoId).url;
        return axios.get(url)
            .then(response => {
                commit('showMask', false, {root: true})
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });
    },

    cancelPedido({dispatch, commit}, pedidoId) {
        const url = jsRoutes.controllers.PedidoController.cancelPedido(pedidoId).url;

        return axios.get(url)
            .then(response => {
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.generic'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },

    agendarPedido({dispatch, commit}, folhaObra) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.PedidoController.doAgendarPedido().url;
        return axios.post(url, folhaObra)
            .then((response) => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.pedido.booked'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch((error) => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },

}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
