<template>

    <div class="card">

        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h3>{{ messages("panel.folhasobra.final.title") }} - {{ folhaObra.referenciaFolhaObra }}</h3>
                </div>
                <div class="col-auto">
                    <button @click="redirectBack"
                            class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{ messages("button.back") }}
                    </button>
                </div>
            </div>


        </div>


        <div class="card-body">

            <!-- HEADER FO -->
            <div class="form-row form-group">
                <label class="col-form-label">{{ messages("label.date.creation") }}:</label>

                <div class="col">
                    <p class="form-control-plaintext font-weight-bold">{{ folhaObra.dataRegisto }}</p>
                </div>

                <label class="col-form-label">{{ messages("label.tipo.fo") }}:</label>
                <div class="col">
                    <p class="form-control-plaintext font-weight-bold">{{ messages(folhaObra.tipoPedidoKey) }}</p>
                </div>


                <!-- BUTTONS -->
                <div class="col-auto">
                    <router-link class="btn btn-warning btn-sm"
                                 v-show="folhaObra.relatorioTestes !== null"
                                 :to="{name: 'relatorioTestesFinal', params: {foId: folhaObra.id }}">
                        {{ messages('folhasobra.btn.test.report') }}
                    </router-link>

                    <button class="btn btn-outline-success btn-sm"
                            v-show="folhaObra.hasReport"
                            data-toggle="tooltip" data-placement="left"
                            @click="downloadRelatorio"
                            :title="messages('tooltip.download.folhaobra')">
                        <font-awesome-icon icon="file-pdf"></font-awesome-icon>
                    </button>

                </div>

            </div>

            <!-- AGENCIA -->
            <div class="form-row form-group">
                <label class="col-form-label col-auto">{{ messages("label.client") }}:</label>
                <label class="col-form-label col-auto font-weight-bold">
                    {{ folhaObra.codigoCliente }}</label>
                &nbsp;
                <label class="col-form-label col font-weight-bold">{{ folhaObra.nomeCliente }}</label>
            </div>


            <!-- TECNICO -->
            <div class="form-row form-group">
                <label class="col-form-label col-auto">{{ messages("label.tecnico") }}:</label>
                <label class="col-form-label col font-weight-bold">{{ folhaObra.tecnicoNome }}</label>

                <label class="col-form-label col-auto">{{ messages("label.client.request.number") }}:</label>
                <div class="col-auto">
                    <p class="form-control-plaintext font-weight-bold">{{
                            folhaObra.nPedidoCliente
                        }}</p>
                </div>
            </div>

            <!-- DESCRIÇÃO PEDIDO -->

            <div class="orm-row form-group">

                <div class="form-row form-group mt-3">
                    <label class="col-form-label">{{ messages("panel.fo.tec.descricao.avaria.title") }}:</label>
                    <div class="col">
                    <textarea class="form-control" rows="3" readonly
                              v-model="folhaObra.descricaoAvaria">
                    </textarea>
                    </div>
                </div>

            </div>

            <!-- TEMPOS -->
            <div class="form-row form-group">
                <label class="col-form-label col-auto text-right mr-2">
                    {{ messages("label.date.start") }}:
                </label>

                <label class="col-form-label col-auto font-weight-bold">{{
                        folhaObra.dataInicioReal + ' - ' +
                        folhaObra.horaInicioReal
                    }}</label>

                <label class="col-form-label col-2 text-right mr-2">
                    {{ messages("label.date.end") }}:
                </label>

                <label class="col-form-label col-auto font-weight-bold">{{
                        folhaObra.dataFimReal + ' - ' +
                        folhaObra.horaFimReal
                    }}</label>

                <label class="col-form-label col-2 text-right mr-2">
                    {{ messages("label.hours.total") }}:
                </label>
                <label class="col-form-label col-auto font-weight-bold">{{ folhaObra.totalHoras }} h</label>
            </div>

        </div>

        <div class="col-12">
            <p class="text-warning">{{ messages('labe.fo.period.extra') }}
                <input type="checkbox" id="pe" v-model="folhaObra.periodoExtra" disabled>
            </p>
        </div>


        <component :is="folhaObra.tipoFolhaObra"></component>


        <div class="card-body">
            <div class="form-row form-group mt-3">
                <label class="col-form-label">{{ messages("label.observacoes") }}:</label>
                <label class="col-form-label col font-weight-bold">{{ folhaObra.observacoes }}</label>
            </div>
        </div>


        <div class="card-body border-top">
            <div class="row">

                <div class="col-6">

                    <div class="form-row form-group">
                        <label class="col-form-label">{{ messages("label.name.speak.pearson") }}:</label>
                        <div class="col">
                            <input class="form-control"
                                   :class="{'is-invalid': state.nameError}"
                                   :disabled="!state.isConclusao"
                                   @focus="state.nameError = false"
                                   v-model="folhaObra.nomeInterlocutor">
                            <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                        </div>
                    </div>

                    <!--ORIGINAL-->
                   <!-- <div class="form-row form-group">
                        <label class="col-form-label">{{ messages("label.request.email") }}:</label>
                        <div class="col">
                            <input class="form-control"
                                   :disabled="!state.isConclusao"
                                   v-model="folhaObra.emailsEnvio">
                        </div>
                    </div>-->

                    <!--NOVO-->

                  <div v-if="control==0">{{createListaEmails()}}</div>

                   <div class="form-row form-group">
                        <label class="col-form-label">{{ messages("label.request.email") }}:</label>
                        <div class="col">
                            <button class="btn btn-success btn-sm rounded" v-show="listaEmails.length < 5" v-on:click="addEmailAddress" :disabled="!state.isConclusao">
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div class="form-group form-row" v-for="(email, idx) in $v.listaEmails.$each.$iter" :key="email.id">
                        <div class="col">
                            <input type="text" v-model="email.text.$model"
                                   class="form-control"
                                   :class="{ 'is-invalid': email.$error }"
                                   :disabled="!state.isConclusao"
                                   :placeholder="messages('label.email')">
                            <span class="invalid-feedback">{{messages("error.field.email")}}</span>
                            <!--<span class="invalid-feedback" v-show="!email.text.required">{{ messages("error.field.required") }}</span>-->
                            <span v-show="!email.text.required" class="text-danger"
                                  style="font-size: 80%;">{{ messages("error.field.required") }}</span>
                        </div>
                        <div class="col-auto">
                           <button class="btn btn-outline-danger btn-sm rounded"
                                    v-on:click="removeEmailAddress(idx)" :disabled="!state.isConclusao">
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                </div>

                <div v-show="state.isConclusao" class="col-6 justify-content-center">
                    <signature-pad @signed="signDocument"></signature-pad>
                    <span v-show="state.signatureError" class="text-danger"
                          style="font-size: 80%;">{{ messages("error.field.signature") }}</span>
                </div>

            </div>
        </div>


        <div class="card-footer text-right">

            <button @click="redirectBack"
                    class="btn btn-outline-secondary mr-2">
                <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                {{ messages("button.back") }}
            </button>

            <button class="btn btn-success mr-2"
                    v-show="state.isConclusao"
                    @click="concluirFolhaObra()">
                {{ messages("button.finish.fo") }}
                <font-awesome-icon :icon="['far', 'check-circle']"></font-awesome-icon>
            </button>

        </div>
    </div>

</template>

<script>

import {computed, onMounted, reactive} from '@vue/composition-api'
import {messagesMixin} from "../../mixins/messagesMixin";
import PreventivaFinalPanel from "./components/PreventivaFinalPanel";
import OutrosTiposFinalPanel from "./components/OutrosTiposFinalPanel";
import SignaturePad from "../../components/SignaturePad";
import {setDownloadFrame} from "../../utils";
import MaterialAplicadoPanel from "@/views/folhasobra/components/MaterialAplicadoPanel";

import {required, email} from 'vuelidate/lib/validators'
import {mapGetters, mapMutations} from 'vuex'

export default {
    name: "FolhaObraFinalPanel",
    mixins: [messagesMixin],

    components: {
        MaterialAplicadoPanel,
        SignaturePad,
        'PREVENTIVA': PreventivaFinalPanel,
        'OUTROS_TIPOS': OutrosTiposFinalPanel,
    },

    data() {
        return {
            listaEmails: [],
            emailsList: [],
            pseudoId: 0,
            componentKey: 0,
            control: 0,
        }
    },


    validations: {
        listaEmails: {
            required,
            $each: {
                text: {
                    required,
                    email
                }
            }
        },

    },


    methods: {

        ...mapMutations({
            alert: 'addAlert'
        }),

        addEmailAddress(){
            this.listaEmails.push({id: this.pseudoId++, text: ''})
        },

        removeEmailAddress(idx) {
            //console.log('removeEmailAddress', idx)
            if(idx>0) {
                this.listaEmails.splice(idx, 1)
            }
        },

    },

    beforeRouteEnter(to, from, next) {
        if (from.meta.module && from.meta.module === 'planeamento') {
            // console.debug('arrived from planeamento!!!')
            next((vm) => {
                vm.state.isFromPlaneamento = true
            })
            return
        }
        next()
    },


    setup(props, {root: {$store, $route, $router}}) {
        // console.log('FolhaObraFinalPanel ctx', ctx)

        onMounted(() => {
            // console.log('FolhaObraFinalPanel foId', $route.params.foId)
            $store.dispatch('folhasobra/loadFolhaObraData', $route.params.foId)
            },
            //$router.push({name: 'folhasobrasEditar', params: {foId: folhaObra.value.id}})
        )


        const state = reactive({
            isFromPlaneamento: false,
            signature: '',
            nameError: false,
            signatureError: false,
            isConclusao: computed(() => folhaObra.value.estadoFolhaObra === 'ABERTO'),
            emailsList: computed(() => folhaObra.value.emailsEnvio?.split(';')),
        })


        const folhaObra = computed(() => $store.getters['folhasobra/getSelectedFolhaObra'])
        //const emailsList  = computed(() => this.folhaObra.emailsEnvio.split(';'))

        function createListaEmails(){
            //console.log("Create list ")
            //this.emailsList = this.folhaObra.emailsEnvio?.split(';');
            state.emailsList.forEach(value=> {
                this.listaEmails.push({id: this.pseudoId++, text: value})
            });
            this.control++
            return this.listaEmails
        }

        function signDocument($event) {
            // console.log('data %s', $event);
            state.signature = $event;
            state.signatureError = false
        }


        function concluirFolhaObra() {

            state.signatureError = !state.signature

            const data = {
                ...folhaObra.value,
                clientSignature: state.signature
            }
            state.nameError = !data.nomeInterlocutor

            const emailsEnvio = this.listaEmails.map(value => value.text).join(';');

            var tmp = this.listaEmails.map(value => value.text).join(';');

            //this.folhaObra.emailsEnvio = this.listaEmails.map(value => value.text).join(';');

            this.folhaObra.emailsEnvio = tmp

            if (state.signatureError || state.nameError ||this.$v.$invalid ) {
                //console.error("interlocutor error")
                return
            }



            if (state.isFromPlaneamento) {
                $store.commit('setDialogProps', {
                    show: true,
                    message: Messages('confirm.msg.close.fo'),
                    successStyle: 'btn-success',
                    callback: () => {
                        data.emailsEnvio=emailsEnvio
                        $store.dispatch('folhasobra/closeFolhaObra', data)
                            .then(() => {
                                $router.push({path: '/planeamento'})
                                //state.isFromPlaneamento = false
                                $router.go()
                                return
                            })

                    }
                })
            }
            else {
               $store.commit('setDialogProps', {
                    show: true,
                    message: Messages('confirm.msg.close.fo'),
                    successStyle: 'btn-success',
                    callback: () => {
                        data.emailsEnvio=emailsEnvio
                        $store.dispatch('folhasobra/closeFolhaObra', data)
                            .then(() => {
                                $router.push({path: '/folhas-obra'})
                                $router.go()
                            })

                    }
                })
            }
           /* $store.commit('setDialogProps', {
                show: true,
                message: Messages('confirm.msg.close.fo'),
                successStyle: 'btn-success',
                callback: () => {
                    data.emailsEnvio=emailsEnvio
                    $store.dispatch('folhasobra/closeFolhaObra', data)
                        .then(() => {
                            $router.push({path: '/folhas-obra'})
                            $router.go()
                        })

                }
            })*/



        }

        function redirectBack() {

            if (state.isFromPlaneamento) {
                $router.push({path: '/planeamento'})
                state.isFromPlaneamento = false
                $router.go()
                return
            }
            // :to="{name: 'folhasobrasEditar', params:{foId: folhaObra.id}}"
            if (folhaObra.value.estadoFolhaObra === 'ABERTO') {
                $router.push({name: 'folhasobrasEditar', params: {foId: folhaObra.value.id}})
            } else {
                $router.push({path: '/folhas-obra'})
                //$router.go()
            }
        }

        function downloadRelatorio() {
            const url = jsRoutes.controllers.FolhaObraController.downloadRelatorioFolhaObra(folhaObra.value.id).url;
            setDownloadFrame(url)
        }

        return {
            state,
            folhaObra,
            signDocument,
            concluirFolhaObra,
            redirectBack,
            downloadRelatorio,
            createListaEmails,
        }
    },

}
</script>

<style scoped>

</style>
