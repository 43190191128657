import Vue from 'vue'
import VueRouter from 'vue-router'


import PedidosModule from "../views/pedidos/PedidosModule";
import PedidosMainList from "../views/pedidos/PedidosMainList";
import CriarPedidoPanel from "../views/pedidos/CriarPedidoPanel";
import AgendarPedidoPanel from "../views/pedidos/AgendarPedidoPanel";
import EditarPedidoPanel from "../views/pedidos/EditarPedidoPanel";
import FolhasObraModule from "../views/folhasobra/FolhasObraModule";
import FolhasObraMainList from "../views/folhasobra/FolhasObraMainList";
import LoginPage from "../views/LoginPage";
import FolhaObraMainPanel from "../views/folhasobra/FolhaObraMainPanel";
import PlaneamentoMainPanel from "../views/planeamento/PlaneamentoMainPanel";

import ArtigosModule from "../views/artigos/ArtigosModule";
import ArtigosMainList from "../views/artigos/ArtigosMainList";
import RelatorioTestesMainPanel from "../views/folhasobra/RelatorioTestesMainPanel";
import ContratosModule from "../views/contratos/ContratosModule";
import ContratosMainList from "../views/contratos/ContratosMainList";
import ContratoMainPanel from "../views/contratos/ContratoMainPanel";
import ServicosMainPanel from "../views/servicos/ServicosMainPanel";
import FolhaObraFinalPanel from "../views/folhasobra/FolhaObraFinalPanel";
import RelatorioTestsFinalPanel from "../views/folhasobra/RelatorioTestsFinalPanel";
import AgenciasMainList from "../views/agencias/AgenciasMainList";
import AgenciasModule from "../views/agencias/AgenciasModule";
import AgenciasMainPanel from "../views/agencias/CriarAgenciasPanel";
import EditarAgenciaPanel from "../views/agencias/EditarAgenciaPanel";
import UsersModule from "../views/admin/users/UsersModule";
import UsersMainList from "../views/admin/users/UsersMainList";
import ParametrosMainContainer from "../views/admin/parametros/ParametrosMainContainer";
import UserArea from "../views/user/UserArea";



Vue.use(VueRouter)

const routes = [

    {
        path: jsRoutes.controllers.Application.login().url,
        component: LoginPage,
    },
    {
        path: jsRoutes.controllers.PlaneamentoController.index().url,
        name: 'planeamento',
        component: PlaneamentoMainPanel,
        meta: {
            module: 'planeamento'
        }
    },
    {
        path: jsRoutes.controllers.PedidoController.index().url,
        component: PedidosModule,
        meta: {
            module: 'pedidos'
        },
        children: [
            {
                path: '',
                name: 'pedidosMain',
                component: PedidosMainList
            },
            {
                path: 'criar',
                name: 'pedidoCreate',
                component: CriarPedidoPanel
            },
            {
                path: ':pedidoId/agendar',
                name: 'pedidoAgendar',
                component: AgendarPedidoPanel
            },
            {
                path: ':pedidoId/editar',
                name: 'pedidoEditar',
                component: EditarPedidoPanel
            },

        ],

    },

    {
        path: jsRoutes.controllers.FolhaObraController.index().url,
        component: FolhasObraModule,
        meta: {
            module: 'folhasobra'
        },
        children: [
            {
                path: '',
                name: 'folhasobrasMain',
                meta: {
                    module: 'folhasobra'
                },
                component: FolhasObraMainList
            },
            {
                path: ':foId/editar',
                name: 'folhasobrasEditar',
                meta: {
                    module: 'folhasobra'
                },
                component: FolhaObraMainPanel
            },
            {
                path: ':foId/relatorio-testes',
                name: 'relatorioTestes',
                meta: {
                    module: 'folhasobra'
                },
                component: RelatorioTestesMainPanel
            },
            {
                path: ':foId/final',
                name: 'finalizarFolhaObra',
                meta: {
                    module: 'folhasobra'
                },
                component: FolhaObraFinalPanel
            },
            {
                path: ':foId/relatorio-testes/final',
                name: 'relatorioTestesFinal',
                meta: {
                    module: 'folhasobra'
                },
                component: RelatorioTestsFinalPanel
            },

        ]
    },

    {
        path: jsRoutes.controllers.ArtigoController.index().url,
        component: ArtigosModule,
        meta: {
            module: 'artigos'
        },
        children: [
            {
                path: '',
                name: 'artigosMain',
                component: ArtigosMainList
            },
        ]
    },

    {
        path: jsRoutes.controllers.ContratosController.index().url,
        component: ContratosModule,
        meta: {
            module: 'contratos'
        },
        children: [
            {
                path: '',
                name: 'contratosMain',
                component: ContratosMainList
            },
            {
                path: 'criar',
                name: 'contratosCriar',
                component: ContratoMainPanel
            },
            {
                path: ':contratoId/editar',
                name: 'contratosEditar',
                component: ContratoMainPanel
            },
        ]
    },
    {
        path: jsRoutes.controllers.ServicosController.index().url,
        name: 'servicosMain',
        meta: {
            module: 'servicos'
        },
        component: ServicosMainPanel,
    },

    {
        path: jsRoutes.controllers.ClienteAdminController.index().url,
        component: AgenciasModule,
        meta: {
            module: 'agencias'
        },
        children: [
            {
                path: '',
                name: 'agenciasMain',
                component: AgenciasMainList
            },
            {
                path: 'criar',
                name: 'agenciasCriar',
                component: AgenciasMainPanel
            },
            {
                path: ':agenciaId/editar',
                name: 'agenciasEditar',
                component: EditarAgenciaPanel
            }
        ]
    },
    {
        path: jsRoutes.controllers.AdminController.users().url,
        component: UsersModule,
        meta: {
            module: 'admin'
        },
        children: [
            {
                path: '',
                name: 'UsersMainList',
                component: UsersMainList
            },
        ]
    },
    {
        path: jsRoutes.controllers.AdminController.configsMain().url,
        name: 'ParametrosMainContainer',
        meta: {
            module: 'admin'
        },
        component: ParametrosMainContainer

    },

    {
        path: jsRoutes.controllers.UserController.index().url,
        name: 'userArea',
        meta: {
            module: 'user'
        },
        component: UserArea

    },



]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

