<template>


    <div class="card">

        <div class="card-header">
            <div class="row">
                <div class="col-7">
                    <button class="btn btn-link"
                            data-toggle="collapse"
                            :data-target="'.' + classId" role="button"
                            aria-expanded="false"
                            :aria-controls="classId">
                        <h5>
                            {{ title }}
                        </h5>
                    </button>
                </div>
                <div class="col-1 collapse" align="center" :class="classId">
                    <label class="header-label">{{ messages('folhasobra.preventiva.panel.qtd.title') }}</label>
                </div>
                <div class="col-1 collapse" align="left" :class="classId">
                    <label class="header-label">{{ messages('relatorio.testes.header.result') }}</label>
                </div>
                <div class="offset-1 col collapse" :class="classId">
                    <label class="header-label">{{ messages('label.observacoes') }}</label>
                </div>
            </div>
        </div>

        <div class="collapse" :class="classId">
            <ul class="list-group">
                <li class="list-group-item" v-for="item in listItems" :key="item.codigo">
                    <div class="row">
                        <div class="col-7">
                            <p>
                                {{ item.codigo }} - {{ item.descricao }}
                            </p>
                        </div>
                        <div class="col-1">
                            <input type="text"
                                   class="form-control"
                                   v-model="item.quantidade">
                        </div>
                        <div class="col-auto">
                            <button class="btn"
                                    @click="item.result = changeStatus(item.result)"
                                    :class="getResultClass(item.result)">
                                <font-awesome-icon
                                    :icon="getResultIcon(item.result)"
                                    size="lg">
                                </font-awesome-icon>
                            </button>
                        </div>

                        <div class="col">
                            <input type="text" class="form-control" v-model="item.observacoes">
                        </div>

                    </div>

                </li>
            </ul>
        </div>

    </div>

</template>

<script>
import {messagesMixin} from "@/mixins/messagesMixin";
import {
    changeStatus,
    getButtonStyleForBool as getResultClass,
    getIconForBool as getResultIcon
} from "@/compositionfn/utils";


export default {
    name: "PreventivaResultadosPanel",
    mixins: [messagesMixin],
    props: ['classId', 'title', 'listItems'],
    setup() {
        return {
            getResultIcon,
            getResultClass,
            changeStatus
        }
    },

    /*validations:{
        listItems: {
            $each:{
                result:{
                    resultValid: function (val){
                        return val !== null
                    }
                }
            }
        }
    },*/

    methods: {

        isResultsValid() {
            /*this.$v.$touch()
            return !this.$v.listItems.$invalid*/
            return true
        }
    }


}
</script>

<style scoped>

</style>
