<template>

    <div class="row border-top pt-2">

        <div class="col-12">

            <div class="card-body">

                <div class="row">

                    <div class="col-12">
                        <h4>{{ messages("label.equipament") }}</h4>
                    </div>

                    <div class="col-6">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{ messages("label.marca") }}:</label>
                            <div class="col">
                                <input type="text"
                                       :class="{'is-invalid': $v.objectData.equipamentoMarca.$error}"
                                       class="form-control " v-model="objectData.equipamentoMarca">
                                <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                            </div>
                        </div>

                        <div class="form-row form-group">
                            <label class="col-form-label">{{ messages("label.modelo") }}:</label>
                            <div class="col">
                                <input type="text"
                                       :class="{'is-invalid': $v.objectData.equipamentoModelo.$error}"
                                       class="form-control" v-model="objectData.equipamentoModelo">
                                <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{ messages("label.num.serie") }}:</label>
                            <div class="col">
                                <input type="text"
                                       :class="{'is-invalid': $v.objectData.equipamentoNumSerie.$error}"
                                       class="form-control" v-model="objectData.equipamentoNumSerie">
                                <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <outros-tipos-panel ref="outrosTiposPanel"></outros-tipos-panel>

                <material-aplicado-panel ref="materialsPanel" hidden></material-aplicado-panel>


            </div>

        </div>

        <!-- CENTRAL DE DETECÇÃO E ALARME -->
        <div class="col-12">
            <preventiva-resultados-panel :title="messages('folhasobra.preventiva.panel.alarmes.title')"
                                         class-id="preventiva"
                                         ref="centralDetecaoPanel"
                                         :list-items="centralDetecaoResultItems"></preventiva-resultados-panel>
        </div>


        <!-- ELEMENTOS PERIFÉRICOS -->
        <div class="col-12">
            <preventiva-resultados-panel :title="messages('folhasobra.preventiva.panel.perifericos.title')"
                                         class-id="perifericos"
                                         ref="elementosPerifericosPanel"
                                         :list-items="perifericosResultItems"></preventiva-resultados-panel>
        </div>

    </div>
</template>

<script>

import {computed, ref} from '@vue/composition-api'
import {required, requiredIf} from 'vuelidate/lib/validators'

import {messagesMixin} from "@/mixins/messagesMixin";
import MaterialAplicadoPanel from "./MaterialAplicadoPanel";
import PreventivaResultadosPanel from "./PreventivaResultadosPanel";
import OutrosTiposPanel from "@/views/folhasobra/components/OutrosTiposPanel";

export default {
    name: "PreventivaPanel",
    components: {OutrosTiposPanel, PreventivaResultadosPanel, MaterialAplicadoPanel},
    mixins: [messagesMixin],

    setup(props, {root: {$store}}) {
        const materialsPanel = ref(null)
        const centralDetecaoPanel = ref(null)
        const elementosPerifericosPanel = ref(null)
        //
        const outrosTiposPanel = ref(null)
        //


        const objectData = computed(() => $store.getters['folhasobra/getPreventivaData']);
        const centralDetecaoResultItems = computed(() => $store.getters['folhasobra/getCentralDetecaoResultItems'])
        const perifericosResultItems = computed(() => $store.getters['folhasobra/getPerifericosResultItems'])

        function validateResults() {
            // console.log('PreventivaPanel validateResults')
            validateResultsCorretiva()
            return outrosTiposPanel.value.validateResults()
        }

        function validateResultsCorretiva() {
            return outrosTiposPanel.value.isDataValid()
        }

        return {
            materialsPanel,
            centralDetecaoPanel,
            elementosPerifericosPanel,
            centralDetecaoResultItems,
            perifericosResultItems,
            objectData,
            validateResults,
            outrosTiposPanel,
        }

    },

    validations: {
        objectData: {
            equipamentoMarca: {
                required
            },
            equipamentoModelo: {
                required
            },
            equipamentoNumSerie: {
                required
            },
        },
    },

    methods: {
        isDataValid() {
            this.$v.$touch()
            const centralDetecaoResult = this.$refs.centralDetecaoPanel.isResultsValid()
            const elementosPerifericosResult = this.$refs.elementosPerifericosPanel.isResultsValid()
            const dadosCorretiva = this.$refs.outrosTiposPanel.$v
            return !this.$v.objectData.$invalid && centralDetecaoResult && elementosPerifericosResult && !dadosCorretiva.$invalid
        },

    }


}
</script>

<style scoped>

.header-label {
    text-align: center;
    width: 100%;
    margin-top: 1em;
}


</style>
