<template>

    <div class="card">

        <div class="card-header">
            <h4>
                <font-awesome-icon icon="user" size="2x"></font-awesome-icon>
                &nbsp;{{ messages("label.userarea") }}
            </h4>
        </div>

        <ul class="list-group">

            <!--@* BUTTON BAR *@-->
            <li class="list-group-item">
                <div class="row">
                    <div class="col">
                        <button v-on:click.prevent="editData = true"
                                class="btn btn-primary">
                            <font-awesome-icon icon="pencil-alt"></font-awesome-icon>&nbsp;
                            {{ messages("button.edit") }}
                        </button>
                        <button v-on:click.prevent="editPassword = true"
                                class="btn btn-warning">
                            <font-awesome-icon icon="pencil-alt"></font-awesome-icon>&nbsp;
                            {{ messages("button.changepw") }}
                        </button>
                    </div>
                </div>
            </li>

            <!--@* DATA AREA *@-->
            <li class="list-group-item">


                <div class="form-group form-row">
                    <label class="col-md-auto col-form-label">
                        {{ messages("label.username") }}:
                    </label>
                    <div class="col">
                        <input v-model="userData.username" class="form-control-plaintext" readonly>
                    </div>

                    <label class="col-md-auto col-form-label">
                        {{ messages("label.user.since") }}:
                    </label>
                    <div class="col">
                        <input v-model="userData.creationDate" class="form-control-plaintext" readonly>
                    </div>

                </div>


                <div class="form-group form-row">
                    <label class="col-md-auto col-form-label">{{ messages("label.name") }}:</label>
                    <div class="col">
                        <input v-model="userData.name" class="form-control"
                               :class="{'is-invalid': $v.userData.name.$error }"
                               :disabled="!editData">
                        <span class="invalid-feedback">{{ messages("error.field.required") }}</span>
                    </div>

                    <label class="col-md-auto col-form-label">{{ messages("label.email") }}:</label>
                    <div class="col">
                        <input v-model="userData.email"
                               class="form-control"
                               :class="{'is-invalid': $v.userData.email.$error }"
                               :disabled="!editData">
                        <span v-show="!$v.userData.email.required"
                              class="invalid-feedback">{{ messages("error.field.required") }}</span>
                        <span v-show="!$v.userData.email.email"
                              class="invalid-feedback">{{ messages("error.field.email") }}</span>
                    </div>

                </div>


                <!-- SIGNATURE -->
                <!--<div class="row" v-show="editData">
                    <div class="col">
                        <signature-pad @signed="setUserSignature"></signature-pad>
                    </div>
                </div>-->


               <!-- <div class="row" v-show="!editData && !!userData.userSignature">
                    <div class="col">
                         <img :src="userData.userSignature" height="100">
                    </div>
                </div>-->


                <div class="row justify-content-end" v-show="editData">
                    <div class="col-auto">
                        <button @click="editData = false"
                                class="btn btn-outline-secondary">
                            {{ messages("button.cancel") }}
                            <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        </button>
                        <button @click="updateUser"
                                class="btn btn-primary">
                            {{ messages("button.save") }}
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </button>
                    </div>
                </div>

            </li>

            <!--@* PASSWORD AREA *@-->
            <li class="list-group-item" v-show="editPassword">

                <div class="row">
                    <div class="col-lg-12">

                        <form class="form-horizontal">

                            <div class="form-row">
                                <label class="col-2 text-right">{{ messages("label.password") }}</label>
                                <div class="col-6">
                                    <input v-model="password.pass_1"
                                           :class="{'is-invalid': $v.password.pass_1.$error }"
                                           type="password"
                                           class="form-control">
                                    <small v-show="!$v.password.pass_1.minLength" class="invalid-feedback">
                                        {{ messages("password.help") }}
                                    </small>
                                    <small v-show="!$v.password.pass_1.required" class="invalid-feedback">
                                        {{ messages("error.field.required") }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-row mt-3">
                                <label class="col-2 text-right">{{ messages("label.password.repeat") }}</label>
                                <div class="col-6">
                                    <input v-model="password.pass_2"
                                           :class="{'is-invalid': $v.password.pass_2.$error }"
                                           type="password"
                                           class="form-control">
                                    <small v-show="!$v.password.pass_2.required" class="invalid-feedback">
                                        {{ messages("error.field.required") }}
                                    </small>
                                    <small v-show="!$v.password.pass_2.minLength" class="invalid-feedback">
                                        {{ messages("password.help") }}
                                    </small>
                                    <small v-show="!$v.password.pass_2.sameAsPassword"
                                           class="invalid-feedback">
                                        {{ messages("error.field.password.mismatch") }}
                                    </small>
                                </div>
                            </div>

                            <div class="row mt-3 justify-content-end">
                                <div class="col-auto">
                                    <button @click.prevent="editPassword = false"
                                            type="button"
                                            class="btn btn-outline-secondary">
                                        {{ messages("button.cancel") }}
                                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                                    </button>
                                    <button @click.prevent="updatePassword"
                                            type="button"
                                            class="btn btn-primary">
                                        {{ messages("button.save") }}
                                        <font-awesome-icon icon="save"></font-awesome-icon>
                                    </button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </li>

        </ul>

    </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import {email, minLength, required, sameAs} from 'vuelidate/lib/validators'
import {messagesMixin} from "../../mixins/messagesMixin";
import SignaturePad from "../../components/SignaturePad";

export default {
    name: "UserArea",
    //components: {SignaturePad},
    mixins: [messagesMixin],

    data() {
        return {
            user: {
                name: "",
                email: "",
            },
            password: {
                pass_1: "",
                pass_2: "",
            },
            editData: false,
            editPassword: false,
            userId: 0,

            userSignature: null,
            updateSignature: false

        }

    },

    computed: {
        ...mapGetters({
            loggedUserId: 'getLoggedUserId'
        }),

        ...mapGetters('user', {
            userData: 'getUserData'
        })
    },

    watch: {

        loggedUserId() {
            this.loadUser()
        },

        editPassword(val) {
            if (val) {
                this.$v.$reset()
                this.password = {
                    pass_1: "",
                    pass_2: "",
                };
            }

        },
        editData(val) {
            if (val) {
                this.$v.$reset()
            }

        },

    },

    validations: {

        userData: {
            name: {
                required
            },
            email: {
                required,
                email
            }
        },

        password: {
            pass_1: {
                required,
                minLength: minLength(6)
            },
            pass_2: {
                required,
                minLength: minLength(6),
                sameAsPassword: sameAs('pass_1')
            },
        },

    },

    methods: {

        ...mapActions('user', {
            loadUser: 'loadUser',
            doUpdateUser: 'updateUserData',
            doUpdatePassword: 'updateUserPassword'
        }),

        updateUser() {
            this.$v.$touch()
            if (this.$v.userData.$invalid) {
                // console.error('data error')
                return
            }
            if(this.updateSignature){
                this.userData.userSignature = this.userSignature
            }
            this.doUpdateUser(this.userData)
                .then(() => {
                    this.editData = false;
                })
        },

        updatePassword() {

            this.$v.$touch()
            if (this.$v.password.$invalid) {
                // console.error('updatePassword - data error')
                return
            }

            this.userData.password = this.password.pass_1;

            this.doUpdatePassword(this.userData)
                .then(() => {
                    this.editPassword = false;
                })
        },

        setUserSignature(ev) {
            // console.log('setUserSignature', ev)
            if(!ev){
                // console.log('setUserSignature - no signature!')
                this.updateSignature = false
                return
            }

            this.updateSignature = true
            this.userSignature = ev

        },

    },

}
</script>

<style scoped>

</style>
