<template>

  <!-- -->
  <!-- -->
  <div class="row">

    <div class="col-12">
      <div class="row">
        <div class="col text-center" v-for="day in weekDays" :key="day.number">
          <strong>{{ day }}</strong>
        </div>
      </div>

    </div>

    <div class="col-12">
      <div class="row" v-for="(week, idx) in weeks" :key="week + idx">
        <div class="col text-center"
             :class="day.styles"
             v-for="day in week"
             :key="day.number"
             @click="selectDay(day.date)">
          {{ day.number }}
        </div>
      </div>
    </div>

  </div>


</template>

<script>


  export default {
    name: "DayPicker",
    props: {
      days: {
        type: Array
      }
    },

    computed: {

      weeks() {
        var result = []

        this.days.forEach((d) => {
          /*
        d = {
              number: currentDay.date(),
              isValid: true,
              weekNumber: weekNumber,
              isWeekend: true,
              isToday: true,
               date: currentDay.format(DATE_PATTERN),
               styles: []
            }
         */

          if (result[d.weekNumber]) {
            result[d.weekNumber].push(d)
          } else {
            result[d.weekNumber] = []
            result[d.weekNumber].push(d)
          }

        })


        return result
      }

    },

    data() {
      return {
        weekDays: ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
      }
    },

    methods: {
      selectDay(date) {
        this.$emit('day-selected', date)
      }
    }


  }
</script>

<style scoped>

</style>
