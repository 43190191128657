<template>

  <div class="col">
    <div class="input-group mb-2" style="width: 160px;">
      <input type="text" class="form-control" :value="date" ref="displayInput" readonly>
      <div class="input-group-append">
        <button class="input-group-text" v-on:click="show" :disabled="disabled">
          <font-awesome-icon icon="calendar-alt"></font-awesome-icon>
        </button>
      </div>

    </div>

    <div v-show="isShow" class="card date-picker-container" ref="datePickerContainer"
         :style="'width: 360px; top:' + top + ';'">
      <div class="row justify-content-end">
        <div class="col-auto">
          <button type="button" class="close" v-on:click="hide" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        </div>
      </div>
      <div class="card-body">
        <month-selector :month="currentMonth" @dateChanged="monthChanged"></month-selector>
        <day-picker :days="monthDays" @day-selected="selectDay"></day-picker>
      </div>

    </div>

  </div>

  <!--</div>-->


</template>

<script>

    import 'moment'
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
    import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
    import MonthSelector from './MonthSelector'
    import DayPicker from './DayPicker'

    library.add(faCalendarAlt)

    const MONTH_PATTERN = 'MM-YYYY'
    const DATE_PATTERN = 'DD-MM-YYYY'

    export default {
        name: "DatePicker",

        components: {
            FontAwesomeIcon,
            MonthSelector,
            DayPicker,
        },

        props: {
            date: {
                type: String,
                default() {
                    return moment().format(DATE_PATTERN)
                }
            },
            disabled: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },

        computed: {

            monthDays() {
                let values = []

                let today = moment()
                let firstDayOfMonth = moment(this.currentMonth, MONTH_PATTERN).startOf('month')
                let firstWeeksDay = moment(firstDayOfMonth).startOf('isoWeek')

                let selectedMoment = moment(this.date, DATE_PATTERN)

                // console.log("\nfirstDayOfMonth: %s\nfirstWeeksDay: %s", firstDayOfMonth.format('DD-MM-YYYYY'), firstWeeksDay.format('DD-MM-YYYYY'))

                let currentDay = moment(firstWeeksDay)
                let sameMonth = true
                let weekNumber = 1

                while (sameMonth) {
                    for (var i = 0; i < 7; i++) {
                        var day = {
                            number: currentDay.date(),
                            isValid: currentDay.isSame(firstDayOfMonth, 'month'),
                            weekNumber: weekNumber,
                            isWeekend: currentDay.isoWeekday() === 6 || currentDay.isoWeekday() === 7,
                            isToday: currentDay.isSame(today, 'day'),
                            date: currentDay.format(DATE_PATTERN),
                            isSelected: currentDay.isSame(selectedMoment, 'day'),
                            styles: [],
                        }

                        if (day.isSelected) {
                            day.styles.push('bg-primary')
                            day.styles.push('text-white')
                        } else if (day.isToday) {
                            day.styles.push('bg-warning')
                            day.styles.push('text-white')
                        } else if (!day.isValid) {
                            day.styles.push('text-muted')
                        } else if (day.isWeekend) {
                            day.styles.push('bg-light')
                        }

                        values.push(day)
                        currentDay.add(1, 'day')
                    }
                    weekNumber++
                    if (currentDay.isAfter(firstDayOfMonth, 'month')) {
                        sameMonth = false
                    }
                }
                // console.log("firstDayOfMonth %o", firstDayOfMonth)

                return values
            },

        },

        data() {
            return {
                currentMonth: moment(this.date, DATE_PATTERN).format(MONTH_PATTERN),
                isShow: false,
                top: 0,
            }
        },

        beforeCreate() {
            moment.locale('pt'); // 'pt'
        },

        methods: {

            monthChanged($event) {
                // console.log('dateChanged -> %s', moment($event).format(MONTH_PATTERN))
                this.currentMonth = moment($event).format(MONTH_PATTERN)
            },

            selectDay($event) {
                this.isShow = false
                this.$emit('day-selected', $event)
                this.$emit('update:date', $event)
            },

            show() {
                // console.log("show")
                let value = this.$refs.displayInput.getBoundingClientRect().bottom + 5;
                this.top = value + 'px';
                this.isShow = true;
                let self = this;
                this.$nextTick(() => {
                    if (self.$refs.datePickerContainer.getBoundingClientRect().right > window.innerWidth) {
                        self.$refs.datePickerContainer.style.right = '15px';
                    }
                });
                window.onscroll = () => {
                    let value = self.$refs.displayInput.getBoundingClientRect().bottom + 5;
                    self.top = value + 'px';
                }
            },

            adjustPosistion() {

            },

            hide() {
                // console.log("hide")
                this.isShow = false
            },

        },

    }

</script>

<style scoped>

  .card {
    position: fixed;
    z-index: 10;
    box-shadow: 2px 2px 6px 1px grey;
    border-width: 2px;
  }

  .input-group {
    width: 250px;
  }

  div.col {
    padding: 0;
  }

  button.close {
    margin-right: 5px;
  }

</style>
