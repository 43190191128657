<template>
    <div class="card">
        <div class="card-header">
            <h3>{{messages("panel.contratos.title")}}</h3>
        </div>

        <ul class="list-group">

            <!-- ACTION BUTTON BAR  -->
            <li class="list-group-item">

                <router-link tag="button"
                             :to="{name: 'contratosCriar'}"
                             class="btn btn-success">
                    {{ messages('button.criar.contrato') }}&nbsp;
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                </router-link>

            </li>

            <table-list :list="state.contratos" :page.sync="state.contratosPage">
                <template #header>
                    <th scope="col">{{messages('label.contract.number')}}</th>
                    <th scope="col">{{messages('label.description')}}</th>
                    <th class="text-center">{{messages('label.generic.contract')}}</th>
                    <th class="text-center">{{messages('label.date')}}</th>
                    <th scope="col"></th>
                </template>
                <tbody>
                <tr v-for="contrato in state.contratosPage" :key="contrato.contratoId">
                    <th scope="row">
                        {{ contrato.codigo }}
                    </th>
                    <td>{{ contrato.designacao }}</td>
                    <td class="text-center">
                        <font-awesome-icon :icon="getIcon(contrato.contratoDefault)"
                        :class="getText(contrato.contratoDefault)"></font-awesome-icon>
                    </td>
                    <td class="text-center">{{ contrato.data }}</td>
                    <td class="text-right">

                        <router-link tag="button"
                                     class="btn btn-outline-primary btn-sm"
                                     data-toggle="tooltip" data-placement="left"
                                     :title="messages('tooltip.view.contract')"
                                     :to="{name: 'contratosEditar', params:{contratoId: contrato.id}}">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                        </router-link>

                    </td>
                </tr>
                </tbody>

            </table-list>

        </ul>
    </div>


</template>

<script>
    import {computed, onMounted, reactive} from '@vue/composition-api'
    import {getIconForBool, getTextStyleForBool} from '../../compositionfn/utils'

    import {messagesMixin} from "../../mixins/messagesMixin";
    import TableList from "../../components/TableList";

    export default {
        name: "ContratosMainList",
        components: {TableList},
        mixins: [messagesMixin],

        setup(props, {root: {$store}}) {

            onMounted(() => {
                $store.dispatch('contratos/loadContratosList')
            })


            const state = reactive({
                filterData: {
                    dateFrom: "",
                    dateTo: "",
                    tipo: null,
                    estado: null,
                },

                contratos: computed(() => $store.getters['contratos/getContratosList']),
                contratosPage: [],
            })

            const getIcon = (result) => getIconForBool(result)

            const getText = (result) => getTextStyleForBool(result)

            return {
                state,
                getIcon,
                getText
            }

        },


    }
    /*

    created: function () {
        // console.log("contratosMainContainer created");

        this.filterData.dateFrom = moment().subtract(1, 'months').format('DD-MM-YYYY');
        this.filterData.dateTo = moment().format('DD-MM-YYYY');
        this.loadContratos();
    },

    data: {

        selectedPedido: {},

        //UI aux
        itemsToShow: 10,
        selectedPage: 1,

    },

    computed: {
        contratosPage: function () {
            var firstItem = (this.selectedPage - 1) * this.itemsToShow;
            var lastItem = this.itemsToShow + firstItem;
            return this.contratos.slice(firstItem, lastItem);
        },
    },

    methods: {

        loadContratos: function(){
            loadingVm.show();
            var url = jsRoutes.controllers.ContratosController.loadContratos().url;

            this.$http.post(url, this.filterData).then(function (response) {
                this.contratos = response.body;
                loadingVm.hide();
                this.$nextTick(function () {
                    $('[data-toggle="tooltip"]').tooltip();
                });
            }, function (error) {
                loadingVm.hide();
                showError(Messages('error.modal.title'), error.body);
            });
        },

        resetFilter: function () {
            this.filterData.dateFrom = moment().subtract(1, 'months').format('DD-MM-YYYY');
            this.filterData.dateTo = moment().format('DD-MM-YYYY');
            this.filterData.tipo = null;
            this.filterData.estado = null;
            this.loadPedidos();
        },

        getAgendarRef: function (id) {
            return '';//jsRoutes.controllers.PedidoController.agendarPedido(id).url;
        },

        getVerRef: function (id) {
            return jsRoutes.controllers.ContratosController.editarContrato(id).url;
        },

    },


});
     */

</script>

<style scoped>

</style>
