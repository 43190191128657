/**
 *
 * Created by Filipe - 09/08/2020
 *
 */
import axios from 'axios'

const state = {
    userData: {},

}

const getters = {
    getUserData: state => state.userData,


}

const mutations = {
    setUserData(state, data) {
        state.userData = data
    },

}

const actions = {

    loadUser({commit, rootGetters}) {
        commit('showMask', true, {root: true})

        const url = jsRoutes.controllers.UserController.loadUser(rootGetters.getLoggedUserId).url;

        return axios.get(url)
            .then((response) => {
                commit('setUserData', response.data)
            })
            .catch((error) => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })
    },

    updateUserData({commit, dispatch}, data) {
        commit('showMask', true, {root: true})

        const url = jsRoutes.controllers.UserController.updateUser().url;

        return axios.post(url, data)
            .then((response) => {
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages("success.msg.generic"),
                    type: 'success'
                }, {root: true});
                dispatch('loadUser')
                return response
            })
            .catch((error) => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })

    },


    updateUserPassword({commit, dispatch}, data) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.UserController.updatePassword().url;

        return axios.post(url, data)
            .then((response) => {
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages("success.msg.generic"),
                    type: 'success'
                }, {root: true});
                dispatch('loadUser')
                return response
            })
            .catch((error) => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })

    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
