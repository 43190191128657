<template>


  <div class="row justify-content-center">
    <div class="col-3 text-right">

      <button class="btn btn-outline-primary" @click="decreaseYear" >
        <font-awesome-icon icon="angle-double-left"></font-awesome-icon>
      </button>
      <button class="btn btn-outline-primary" @click="decreaseMonth">
        <font-awesome-icon icon="angle-left"></font-awesome-icon>
      </button>

    </div>
    <div class="col-3 text-center">
      {{ month }}
    </div>

    <div class="col-3">
      <button class="btn btn-outline-primary" @click="increaseMonth">
        <font-awesome-icon icon="angle-right"></font-awesome-icon>
      </button>
      <button class="btn btn-outline-primary" @click="increaseYear">
        <font-awesome-icon icon="angle-double-right"></font-awesome-icon>
      </button>
    </div>

  </div>


</template>

<script>

  import {library} from '@fortawesome/fontawesome-svg-core'
  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
  import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons'

  library.add(faAngleDoubleLeft)
  library.add(faAngleDoubleRight)
  library.add(faAngleLeft)
  library.add(faAngleRight)


  const MONTH_PATTERN = 'MM-YYYY'

  // Vue.component('font-awesome-icon', FontAwesomeIcon)

  export default {
    name: "MonthBar",

    components: {
      FontAwesomeIcon
    },

    props: {
      month: {
        type: String
      }
    },

    data() {

      return {
        currentMonth: "hoje"
      }

    },

    methods: {

      increaseMonth() {
        var m = moment(this.month, MONTH_PATTERN).add(1, 'month')
        this.$emit('dateChanged', m)
      },

      increaseYear() {
        var m = moment(this.month, MONTH_PATTERN).add(1, 'year')
        this.$emit('dateChanged', m)

      },

      decreaseMonth() {
        var m = moment(this.month, MONTH_PATTERN).subtract(1, 'month')
        this.$emit('dateChanged', m)

      },

      decreaseYear() {
        var m = moment(this.month, MONTH_PATTERN).subtract(1, 'year')
        this.$emit('dateChanged', m)
      },

    },


  }


</script>

<style scoped>

</style>
