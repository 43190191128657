<template>

    <div class="card">
        <div class="card-header">
            <h3>{{messages("panel.folhasobra.title")}}</h3>
        </div>

        <ul class="list-group">

            <!-- FILTER -->
            <li class="list-group-item">

                <div class="row">
                    <div class="col-auto">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{messages("label.date.from")}}</label>
                            <div class="col-auto">
                                <date-picker :date="filterData.dateFrom"
                                             v-on:day-selected="filterData.dateFrom = $event"></date-picker>
                            </div>
                            <label class="col-form-label">{{messages("label.date.to")}}</label>
                            <div class="col-auto">
                                <date-picker :date="filterData.dateTo"
                                             v-on:day-selected="filterData.dateTo = $event"></date-picker>
                            </div>
                        </div>

                    </div>

                    <div class="col">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{messages("label.client")}}</label>
                            <div class="col">
                                <select class="custom-select" v-model="filterData.cliente">
                                    <option :value="null">-- {{messages("label.client")}} --</option>
                                    <option :value="cliente.id" :key="cliente.id" v-for="cliente in clientes">{{ cliente.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col-lg-6 col-xl-4">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{messages("label.estado.fo")}}</label>
                            <div class="col">
                                <select class="custom-select" v-model="filterData.estado">
                                    <option :value="null">-- {{messages("label.estado.fo")}} --</option>
                                    estadoFo
                                    <option v-for="estado in estadosFo" :value="estado.id" :key="estado.id">
                                        {{messages(estado.key)}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <profiles-allowed :authorized-profiles="['admin','backoffice']" class="col-lg-6 col-xl-4">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{messages("label.tecnico")}}</label>
                            <div class="col">
                                <select class="custom-select" v-model="filterData.tecnico">
                                    <option :value="null">-- {{messages("label.tecnico")}} --</option>
                                    <option :value="tec.id" :key="tec.id" v-for="tec in tecnicos">{{ tec.text }}</option>
                                </select>
                            </div>
                        </div>
                    </profiles-allowed>

                    <div class="col-lg-6 col-xl-4">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{messages("label.tipo.fo")}}</label>
                            <div class="col">
                                <select class="custom-select" v-model="filterData.tipo">
                                    <option :value="null">-- {{messages("label.tipo.fo")}} --</option>
                                    <option v-for="tipo in tipos" :value="tipo.id" :key="tipo.id">{{ messages(tipo.key) }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row justify-content-center mt-4">
                    <button class="btn btn-outline-secondary"
                            @click="resetFilter">
                        {{messages("button.clean")}}
                    </button>
                    <button class="btn btn-primary ml-2"
                            @click="loadFolhasObra()">
                        {{messages("button.search")}}
                    </button>
                </div>

            </li>

            <!-- BUTTON BAR -->
            <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-auto">
                            <button class="btn btn-info"
                            @click="showFaturacaoModal">
                                {{messages("button.export.billing.info")}}&nbsp;
                                <font-awesome-icon icon="file-excel"></font-awesome-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </profiles-allowed>

            <table-list :list="folhasObra" :page.sync="foPage">
                <template #header>
                    <th scope="col">#</th>
                    <th scope="col">{{messages("label.client.name")}}</th>
                    <th scope="col">{{messages("label.date")}}</th>
                    <th scope="col">{{messages("label.estado.fo")}}</th>
                    <th scope="col">{{messages("label.tipo.fo")}}</th>
                    <th scope="col">{{messages("label.pedido.refexterna.abrv")}}</th>
                    <th scope="col">{{messages("label.client.request.number")}}</th>
                    <th scope="col"></th>
                </template>
                <tbody>
                <tr v-for="folha in foPage" :key="folha.referenciaFolhaObra">
                    <th scope="row">{{ folha.referenciaFolhaObra }}</th>
                    <td>{{ folha.nomeCliente }}</td>
                    <td>{{ folha.dataRegisto }}</td>
                    <td :class="getEstadoStyle(folha)">{{ messages(folha.estadoFolhaObraKey)}}</td>
                    <td>{{ messages(folha.tipoPedidoKey)}}</td>
                    <td>{{ folha.referenciaSage }}</td>
                    <td>{{ folha.nPedidoCliente }}</td>
                    <td class="text-right">

                        <button class="btn btn-outline-success btn-sm mr-1"
                                v-show="folha.hasReport"
                                data-toggle="tooltip" data-placement="left"
                                @click="downloadRelatorioFolhaObra(folha)"
                                :title="messages('tooltip.download.folhaobra')">
                            <font-awesome-icon icon="file-pdf"></font-awesome-icon>
                        </button>

                        <button class="btn btn-outline-warning btn-sm mr-1"
                                v-show="folha.hasRelatorioTestesFile"
                                @click="downloadRelatorioTestes(folha)"
                                data-toggle="tooltip" data-placement="left"
                                :title="messages('tooltip.download.test.report')">
                            <font-awesome-icon icon="file-pdf"></font-awesome-icon>
                        </button>

                        <button class="btn btn-outline-primary btn-sm mr-1"
                                data-toggle="tooltip" data-placement="left"
                                @click="redirectViewFolhaObra(folha)"
                                :title="messages('tooltip.edit.folhaobra')">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                        </button>

                        <profiles-allowed :authorized-profiles="['admin', 'backoffice']">

                            <button class="btn btn-outline-danger btn-sm mr-1"
                                    v-show="canBeCanceled(folha)"
                                    @click="cancelarFolhaObra(folha)"
                                    data-toggle="tooltip" data-placement="left"
                                    :title="messages('tooltip.cancel.folhaobra')">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </button>

                        </profiles-allowed>
                    </td>
                </tr>
                </tbody>
            </table-list>

        </ul>

        <faturacao-filter-modal ref="faturacaoModal"></faturacao-filter-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'

    import {messagesMixin} from "../../mixins/messagesMixin";
    import {setDownloadFrame} from "../../utils";
    import TableList from "../../components/TableList";
    import FaturacaoFilterModal from "./components/FaturacaoFilterModal";

    export default {
        name: "FolhasObraMainList",

        components: {
            FaturacaoFilterModal,
            TableList
        },

        mixins: [messagesMixin],

        mounted() {

            this.loadClientes();
            this.loadTecnicos();
            this.loadFolhasObra();
        },

        data() {
            return {
                filterData: {
                    dateFrom: moment().subtract(1, 'months').format('DD-MM-YYYY'),
                    dateTo: moment().format('DD-MM-YYYY'),
                    cliente: null,
                    tipo: null,
                    estado: null,
                    tecnico: null,
                },
                foPage: [],
            }
        },

        watch: {

            userId() {
                this.loadFolhasObra()
            }
        },

        computed: {

            ...mapGetters({
                tecnicos: 'getTecnicosSelect',
                clientes: 'getAgenciasAtivasSelect',
                userId: 'getLoggedUserId',
                userProfile: 'getUserProfile'
            }),
            ...mapGetters('folhasobra', {
                folhasObra: 'getFolhasObraList',
                estadosFo: 'getEstados'
            }),
            ...mapGetters('pedidos', {
                tipos: 'getTiposPedido'
            }),

            isTecnico() {
                return this.userProfile === 'tecnico'
            }

        },

        beforeRouteLeave(to, from, next) {
            // console.log('beforeRouteLeave')
            $('[data-toggle="tooltip"]').tooltip('hide');
            next()
        },

        methods: {

            ...mapActions({
                loadClientes: 'loadAgenciasAtivasSelect',
                loadTecnicos: 'loadTecnicosSelect',
            }),

            ...mapActions('folhasobra', {
                doLoadFolhasObra: 'loadFolhasObraList',
                docancelarFolhaObra: 'cancelarFo'
            }),

            ...mapMutations({
                dialog: 'setDialogProps'
            }),

            loadFolhasObra() {
                if (this.isTecnico) {
                    this.filterData.tecnico = this.userId
                }
                this.doLoadFolhasObra(this.filterData)
                    .then(response => {
                        this.$nextTick(function () {
                            $('[data-toggle="tooltip"]').tooltip();
                        });
                    })
            },

            resetFilter() {
                this.filterData.dateFrom = moment().subtract(1, 'months').format('DD-MM-YYYY');
                this.filterData.dateTo = moment().format('DD-MM-YYYY');
                this.filterData.tipo = null;
                this.filterData.estado = null;
                this.filterData.cliente = null;
                this.filterData.tecnico = null;
                this.loadFolhasObra();
            },

            getEstadoStyle(folhaObra) {
                switch (folhaObra.estadoFolhaObra) {
                    case 'CANCELADO':
                        return 'text-danger'
                    case 'AGENDADO':
                        return 'text-primary'
                    case 'ABERTO':
                        return 'text-success'
                    case 'SERVICO_CONCLUIDO':
                        return 'text-info'
                    default:
                        return ''
                }
            },

            downloadRelatorioFolhaObra(folhaObra) {
                // console.log('downloadRelatorioFolhaObra')
                const url = jsRoutes.controllers.FolhaObraController.downloadRelatorioFolhaObra(folhaObra.id).url;
                setDownloadFrame(url)
            },

            downloadRelatorioTestes(folhaObra) {
                // console.log('downloadRelatorioTestes')
                const url = jsRoutes.controllers.FolhaObraController.downloadRelatorioTestes(folhaObra.id).url;
                setDownloadFrame(url)
            },

            cancelarFolhaObra(folhaObra) {

                this.dialog({
                    show: true,
                    message: this.messages('confirm.msg.cancel.fo', folhaObra.referenciaFolhaObra),
                    successStyle: 'btn-danger',
                    callback: () => {
                        this.docancelarFolhaObra(folhaObra.id)
                            .then(() => {
                                this.loadFolhasObra()
                            })
                    }
                })
            },

            canBeCanceled(folhaObra) {
                return folhaObra.estadoFolhaObra === 'ABERTO'
            },

            redirectViewFolhaObra(folhaObra) {
                // console.log('redirectViewFolhaObra', folhaObra)
                if (folhaObra.estadoFolhaObra === 'ABERTO') {
                    this.$router.push({name: 'folhasobrasEditar', params: {foId: folhaObra.id}})
                    location.reload()
                } else {
                    this.$router.push({name: 'finalizarFolhaObra', params: {foId: folhaObra.id}})
                    location.reload()
                }
            },

            showFaturacaoModal(){
                this.$refs.faturacaoModal.showModal()
            },
        },
    }
</script>

<style scoped>

</style>
