<template>

    <div class="card">

        <div class="card-header">

            <div class="row">
                <div class="col">
                    <h3>{{ messages("panel.folhasobra.edit.title") }} - {{ folhaObra.referenciaFolhaObra }}</h3>
                </div>
                <div class="col-auto">

                    <button class="btn btn-outline-secondary" @click="navigateBack">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{ messages("button.back") }}
                    </button>

                </div>
            </div>


        </div>

        <div class="card-body">

            <!-- HEADER FO -->
            <div class="form-row form-group">
                <label class="col-form-label">{{ messages("label.date") }}:</label>

                <div class="col">
                    <p class="form-control-plaintext">{{ folhaObra.dataRegisto }}</p>
                </div>

                <label class="col-form-label">{{ messages("label.tipo.fo") }}:</label>
                <div class="col">
                    <p class="form-control-plaintext font-weight-bold">{{ messages(folhaObra.tipoPedidoKey) }}</p>
                </div>


                <!-- BUTTONS -->
                <div class="col-auto">

                    <router-link class="btn btn-warning btn-sm mr-2"
                                 :to="{name: 'relatorioTestes', params: {foId: folhaObra.id }}">
                        {{ messages('folhasobra.btn.test.report') }}
                    </router-link>

                    <button class="btn btn-outline-success btn-sm mr-2"
                            v-show="folhaObra.hasReport"
                            data-toggle="tooltip" data-placement="left"
                            @click="downloadRelatorio"
                            :title="messages('tooltip.download.folhaobra')">
                        <font-awesome-icon icon="file-pdf"></font-awesome-icon>
                    </button>

                    <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                        <button class="btn btn-outline-danger btn-sm mr-2"
                                v-show="canBeCanceled"
                                data-toggle="tooltip" data-placement="left"
                                @click="cancelarFolhaObra"
                                :title="messages('tooltip.cancel.folhaobra')">
                            <font-awesome-icon icon="times"></font-awesome-icon>
                        </button>
                    </profiles-allowed>

                </div>

            </div>

            <div class="form-row form-group">
                <label class="col-form-label col-auto">{{ messages("label.client") }}:</label>
                <label class="col-form-label col-auto bg-secondary text-light rounded">
                    {{ folhaObra.codigoCliente }}</label>
                &nbsp;
                <label class="col-form-label col font-weight-bold">{{ folhaObra.nomeCliente }}</label>

                <label class="col-form-label col-auto">{{ messages("label.estado.fo") }}:</label>
                <div class="col">
                    <p class="form-control-plaintext" :class="getEstadoStyle">{{
                            messages(folhaObra.estadoFolhaObraKey)
                        }}</p>
                </div>

                <div class="col-auto">
                    <button class="btn btn-sm btn-outline-info rounded-circle"
                            @click="showInfos">
                        <font-awesome-icon icon="question-circle"></font-awesome-icon>
                    </button>
                </div>

                <ack-modal :show.sync="showInfosConf"
                           :title="messages('label.confidential.info')"
                           :message="folhaObra.observacoesConfidenciais"></ack-modal>
            </div>

            <!-- TECNICO -->
            <div class="form-row form-group">
                <label class="col-form-label col-auto">{{ messages("label.tecnico") }}:</label>
                <label class="col-form-label col font-weight-bold">{{ folhaObra.tecnicoNome }}</label>

                <label class="col-form-label col-auto">{{ messages("label.client.request.number") }}:</label>
                <div class="col-auto">
                    <!--<p class="form-control-plaintext font-weight-bold">{{
                            folhaObra.nPedidoCliente
                        }}</p>-->
                    <textarea class="form-control" rows="1" :readonly="isTecnico"
                            v-model ="folhaObra.npedidoCliente">
                    </textarea>

                </div>

            </div>

            <!-- DESCRIÇÃO PEDIDO -->

            <div class="orm-row form-group">

                <div class="form-row form-group mt-3">
                    <label class="col-form-label">{{ messages("panel.fo.tec.descricao.avaria.title") }}:</label>
                    <div class="col">
                    <textarea class="form-control" rows="3" :readonly="isTecnico"
                              v-model="folhaObra.descricaoAvaria">
                    </textarea>
                    </div>
                </div>

            </div>
            <!-- TEMPOS -->
            <div class="form-row form-group">
                <label class="col-form-label col-auto text-right mr-2">
                    {{ messages("label.date.start") }}:
                </label>

                <div class="col-auto">
                    <div class="row">
                        <date-picker :date.sync="folhaObra.dataInicioReal"></date-picker>
                                    <!-- :disabled="!isTecnico && !isAdmin"></date-picker>-->
                        <time-picker :time.sync="folhaObra.horaInicioReal"></time-picker>
                                    <!-- :disabled="!isTecnico && !isAdmin"></time-picker>-->
                    </div>
                </div>

                <label class="col-form-label col-2 text-right mr-2">
                    {{ messages("label.date.end") }}:
                </label>
                <div class="col-auto">
                    <div class="row">
                        <date-picker :date.sync="folhaObra.dataFimReal"></date-picker>
                                    <!-- :disabled="!isTecnico && !isAdmin"></date-picker>-->
                        <time-picker :time.sync="folhaObra.horaFimReal"></time-picker>
                                    <!-- :disabled="!isTecnico && !isAdmin"></time-picker>-->
                    </div>
                </div>

                <div class="col-2 text-center">
                    <span class="font-weight-bold">Total</span><br>
                    <span>{{ totalHoras }} h</span>
                </div>

                <div class="col-12">
                    <p class="text-warning">{{ messages('labe.fo.period.extra') }}
                        <!--Checkbox periodo extra-->
                        <input type="checkbox" id="pe" v-model="folhaObra.periodoExtra">
                    </p>
                </div>

            </div>

            <p v-show="!$v.folhaObra.periodoDatas && $v.folhaObra.$error" class="text-center text-danger">
                {{ messages('error.field.begin.date.older.end.date') }}
            </p>




        </div>

        <component :is="folhaObra.tipoFolhaObra" ref="dataPanel"></component>

        <div class="card-body">

            <div class="form-row form-group mt-3">
                <label class="col-form-label">{{ messages("label.observacoes") }}:</label>
                <div class="col">
                    <textarea class="form-control" rows="3"
                              v-model="folhaObra.observacoes"></textarea>
                </div>
            </div>

        </div>

        <div class="card-footer text-right">

            <button class="btn btn-outline-secondary mr-2" @click="navigateBack">
                <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                {{ messages("button.cancel") }}
            </button>

           <!-- <button class="btn btn-success mr-2" v-if="isTecnico"
                    @click="closeFolhaObra" >
                {{ messages("button.finish.fo") }}
                <font-awesome-icon :icon="['far', 'check-circle']"></font-awesome-icon>
            </button>-->

            <button class="btn btn-primary mr-2"
                    @click="editarFolhaObra">
                {{ messages("button.update.fo") }}
                <font-awesome-icon icon="save"></font-awesome-icon>
            </button>

            <button class="btn btn-success mr-2" v-if=checkOwn()
                    @click="closeFolhaObra" >
                {{ messages("button.finish.fo") }}
                <font-awesome-icon :icon="['far', 'check-circle']"></font-awesome-icon>
            </button>
            <!--<button class="btn btn-success mr-2" v-if="iscoordenacao"
                    @click="closeFolhaObra" >
                {{ messages("button.finish.fo") }}
                <font-awesome-icon :icon="['far', 'check-circle']"></font-awesome-icon>
            </button>-->

        </div>

    </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

import {messagesMixin} from "@/mixins/messagesMixin";
import PreventivaPanel from "./components/PreventivaPanel";
import OutrosTiposPanel from "./components/OutrosTiposPanel";
import AckModal from "../../components/utils/AckModal";
import folhasobra from "@/store/modules/folhasobra";

export default {
    name: "FolhaObraMainPanel",
    components: {
        AckModal,
        'PREVENTIVA': PreventivaPanel,
        'OUTROS_TIPOS': OutrosTiposPanel,
    },
    mixins: [messagesMixin],
    data() {
        return {
            showInfosConf: false,
            isFromPlaneamento: false,
        }
    },

    mounted() {
        // console.log("FolhaObraMainPanel mounted", this.$route.params.foId)
        this.loadFolhaObra(this.$route.params.foId)
        this.$v.$reset()
    },

    beforeRouteEnter(to, fromRoute, next) {
        if (fromRoute.meta.module && fromRoute.meta.module === 'planeamento') {
            // console.debug('arrived from planeamento!!!')
            next((vm) => {
                vm.isFromPlaneamento = true
            })
            return
        }
        next()
    },

    computed: {
        ...mapGetters({
            isTecnico: 'isTecnico',
            isAdmin: 'isAdmin',
            iscoordenacao: 'iscoordenacao',
            isOwn: 'getLoggedUserId'
        }),

        ...mapGetters('folhasobra', {
            folhaObra: 'getSelectedFolhaObra'
        }),

        getEstadoStyle() {
            switch (this.folhaObra.estadoFolhaObra) {
                case 'CANCELADO':
                    return 'text-danger'
                case 'AGENDADO':
                    return 'text-primary'
                case 'ABERTO':
                    return 'text-success'
                default:
                    return ''
            }
        },

        canBeCanceled() {
            return this.folhaObra.estadoFolhaObra === 'ABERTO'
        },

        totalHoras() {
            // console.log('total horas')
            let inicioData = moment(`${this.folhaObra.dataInicioReal} ${this.folhaObra.horaInicioReal}`, 'DD-MM-YYYY hh:mm')
            // console.log('total horas - inicioData', inicioData)
            if (!inicioData) {
                return '---'
            }
            let fimData = moment(`${this.folhaObra.dataFimReal} ${this.folhaObra.horaFimReal}`, 'DD-MM-YYYY hh:mm')
            if (fimData.isBefore(inicioData, 'minutes')) {
                return '---'
            }
            const minutesDuration = moment.duration(fimData.diff(inicioData)).as('minutes');
            // console.log('minutesDuration', minutesDuration)
            if (isNaN(minutesDuration)) {
                return '---'
            }
            let finalValue = 0
            if (minutesDuration <= 60) {
                finalValue = 1
            } else {
                let remainder = minutesDuration % 60
                finalValue = Math.floor(minutesDuration / 60)
                // console.log('remainder: %s - finalValue: %s', remainder, finalValue)
                if (remainder > 0) {
                    remainder = Number(remainder > 30 ? 1 : 0.5)
                    finalValue = Number(finalValue) + remainder
                }
            }

            return finalValue

        },

    },

    watch: {
        folhaObra(val) {
            if (val) {
                this.checkPeriodoExtra();
            }
        },

        isTecnico(val) {
            if (val) {
                this.checkPeriodoExtra()
            }
        },
        iscoordenacao(val) {
            if (val) {
                this.checkPeriodoExtra()
            }
        },

        periodoExtra(val){
            this.marcarPeriodoExtra(val);
        }

    },

    validations: {

        folhaObra: {
            periodoDatas: (value) => {
                let inicioData = moment(`${value.dataInicioReal} ${value.horaInicioReal}`, 'DD-MM-YYYY hh:mm')
                let fimData = moment(`${value.dataFimReal} ${value.horaFimReal}`, 'DD-MM-YYYY hh:mm')
                // console.log('periodoDatas', value)
                return inicioData.isBefore(fimData, 'minutes')
            },

        }
    },

    methods: {

        ...mapActions('folhasobra', {
            loadFolhaObra: 'loadFolhaObraData',
            doEditarFolhaObra: 'editFolhaObra',
            endFolhaObra: 'closeFolhaObra',
            docancelarFolhaObra: 'cancelarFo',
            marcarPeriodoExtra: 'marcarPeriodoExtra',

        }),

        ...mapMutations({
            dialog: 'setDialogProps',
            alert: 'addAlert'
        }),

        editarFolhaObra() {

            this.$v.$touch()

            let resultsInvalid = this.$refs.dataPanel.validateResults()
            if (this.$v.$invalid || resultsInvalid) {
                // console.error('form fill error')
                return
            }
            this.folhaObra.totalHoras = this.totalHoras


            /*alterar periodo extra*/
            const data = {
                id: this.folhaObra.id,
                valor: null,
            }
            if(this.folhaObra.periodoExtra){
                data.valor = true;
                this.marcarPeriodoExtra(data)
            }else{
                data.valor = false;
                this.marcarPeriodoExtra(data)
            }

            if (this.totalHoras > 10){
                var tmpFo =this.folhaObra
                const data = {
                    //id: this.folhaObra.id,
                    valor: null,
                }
                this.dialog({
                    show: true,
                    title: '    ',
                    message: this.messages('confirm.msg.exeded.hours.folha.obra'),
                    successStyle: 'btn-warning',
                    cancelText: 'Não',
                    callback: () => {
                        data.valor = true

                        this.doEditarFolhaObra(tmpFo)
                            .then(resp => {
                                this.navigateBack()
                            })
                    },
                    cancelCallback: () => {
                        data.valor = true
                        //var tmpFo =this.folhaObra
                        this.doEditarFolhaObra(tmpFo).then(resp =>{
                            this.loadFolhaObra(this.$route.params.foId)
                            //return
                        })
                        //return
                    },
                })

            }else {
                this.doEditarFolhaObra(this.folhaObra)
                    .then(resp => {
                        this.navigateBack()
                    })
            }

        },

        closeFolhaObra() {

            this.$v.$touch()
            let resultsValid = this.$refs.dataPanel.isDataValid()
            let resultsInvalid = this.$refs.dataPanel.validateResults() //valida material aplicado
            //console.log("EMAIL ", this.folhaObra.emailsEnvio)

            if (this.$v.$invalid || !resultsValid || resultsInvalid) {
                // console.error('data errors!')
                this.alert({
                    type:'error',
                    title: this.messages('error.modal.title'),
                    message: this.messages('error.message.folhaobra.erro.preenchimento'),
                    helpText: this.messages('error.message.folhaobra.erro.preenchimento.help'),
                })
                return
            }

            this.folhaObra.totalHoras = this.totalHoras

            if (this.totalHoras > 10){
                    var tmpFo =this.folhaObra
                    const data = {
                        //id: this.folhaObra.id,
                        valor: null,
                    }
                    this.dialog({
                        show: true,
                        title: '    ',
                        message: this.messages('confirm.msg.exeded.hours.folha.obra'),
                        successStyle: 'btn-warning',
                        cancelText: 'Não',
                        callback: () => {
                            data.valor = true
                            this.doEditarFolhaObra(tmpFo)
                                .then(resp => {
                                    this.$router.push({name: 'finalizarFolhaObra', params: {foId: this.folhaObra.id}})
                                })
                        },
                        cancelCallback: () => {
                            data.valor = true
                            this.doEditarFolhaObra(tmpFo).then(resp =>{
                                this.loadFolhaObra(this.$route.params.foId)
                                //return
                            })
                        },

                    })
                }else {
                    this.doEditarFolhaObra(this.folhaObra)
                        .then(resp => {
                            this.$router.push({name: 'finalizarFolhaObra', params: {foId: this.folhaObra.id}})
                        })
                }



            /*alterar periodo extra*/
            const data = {
                id: this.folhaObra.id,
                valor: null,
            }
            if(this.folhaObra.periodoExtra){
                data.valor = true;
                this.marcarPeriodoExtra(data)
            }else{
                data.valor = false;
                this.marcarPeriodoExtra(data)
            }

        },

        cancelarFolhaObra() {
            this.dialog({
                show: true,
                message: this.messages('confirm.msg.cancel.fo', this.folhaObra.referenciaFolhaObra),
                successStyle: 'btn-danger',
                callback: () => {
                    this.docancelarFolhaObra(this.folhaObra.id)
                        .then(() => {
                            this.loadFolhaObra(this.folhaObra.id)
                        })
                }
            })

        },

        downloadRelatorio() {
            const url = jsRoutes.controllers.FolhaObraController.downloadRelatorioFolhaObra(this.folhaObra.id).url;
            setDownloadFrame(url)
        },

        showInfos() {
            this.showInfosConf = true
        },

        checkPeriodoExtra() {
            // console.debug('checkPeriodoExtra %o isAdmin %s', this.folhaObra, this.isAdmin)
                if ((this.folhaObra.estadoFolhaObra === 'ABERTO' && this.isTecnico && this.folhaObra.periodoExtra === null)
                    || (this.folhaObra.estadoFolhaObra === 'ABERTO' && this.iscoordenacao && this.folhaObra.periodoExtra === null)){

                const data = {
                    id: this.folhaObra.id,
                    valor: null,
                }
                this.dialog({
                    show: true,
                    title: '    ',
                    message: this.messages('confirm.msg.extra.period.folha.obra'),
                    successStyle: 'btn-warning',
                    cancelText: 'Não',
                    callback: () => {
                        data.valor = true
                        this.marcarPeriodoExtra(data)
                    },
                    cancelCallback: () => {
                        data.valor = false
                        this.marcarPeriodoExtra(data)
                    }

                })
            }
        },

        navigateBack() {
            if (this.isFromPlaneamento) {
                this.$router.push({path: '/planeamento'})
            } else {
                this.$router.push({path: '/folhas-obra'})
            }
            this.isFromPlaneamento = false
        },

        checkOwn(){
            if(this.folhaObra.tecnicoId === this.isOwn){
                return true
            }
        },



    }


}
</script>

<style scoped>

</style>
