<template>
    <div class="card">

        <div class="card-header">

            <div class="row">

                <div class="col">
                    <h3> &nbsp;{{ messages("panel.pedidos.edit.title") + ' - ' + pedido.referenciaPedido }}</h3>
                </div>
                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/pedidos'}"
                                 class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.back")}}
                    </router-link>
                </div>

            </div>

        </div>


        <div class="card-body">

            <div class="row justify-content-end mb-2">

                <div class="col">
                    <div class="form-group form-row">
                        <label class="col-form-label">{{messages("label.estado.pedido")}}:</label>
                        <div class="col">
                            <p class="form-control-plaintext">
                                {{ messages(pedido.estadoPedidoKey) }}
                            </p>
                        </div>
                    </div>
                </div>

                <profiles-allowed :authorized-profiles="['admin', 'backoffice']">

                    <div class="col-auto" v-show="pedido.estado === 'ABERTO'">

                        <!--<router-link class="btn btn-success btn-sm"
                                     tag="button"
                                     :to="{name: 'pedidoAgendar', params: { pedidoId: pedido.pedidoId }}">
                            {{messages("button.book.pedido")}}&nbsp;
                            <font-awesome-icon icon="book"></font-awesome-icon>
                        </router-link>-->
                        <button class="btn btn-success btn-sm" v-on:click="agendarPedido">
                            {{messages("button.book.pedido")}}&nbsp;
                            <font-awesome-icon icon="book"></font-awesome-icon>
                        </button>

                        <button class="btn btn-outline-danger btn-sm"
                                data-toggle="tooltip" data-placement="left"
                                :title="messages('tooltip.cancel.request')"
                                v-on:click="cancelarPedido">
                            <font-awesome-icon icon="times"></font-awesome-icon>
                        </button>

                    </div>
                </profiles-allowed>


            </div>


            <div class="row">

                <div class="col">
                    <div class="form-group form-row">
                        <label class="col-form-label">{{messages("label.client")}}:</label>
                        <div class="col">
                            <p class="form-control-plaintext">{{ pedido.clienteNome }}</p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group form-row">
                        <label class="col-form-label">{{messages("label.client.request.number")}}</label>
                        <div class="col">
                            <input type="text"
                                   class="form-control"
                                   :class="{'is-invalid': $v.pedido.numeroPedidoCliente.$error }"
                                   :disabled="!isEditable"
                                   v-model="pedido.numeroPedidoCliente"
                                   :placeholder="messages('label.client.request.number')">
                            <span v-show="!$v.pedido.numeroPedidoCliente.required"
                                  class="invalid-feedback">{{ messages("error.field.required") }}</span>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col">

                    <div class="form-group form-row">
                        <label class="col-form-label">{{messages("label.order.by")}}</label>
                        <div class="col">
                            <input type="text" class="form-control"
                                   :disabled="!isEditable"
                                   v-model="pedido.pedidoPor"
                                   :placeholder="messages('label.order.by')">
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col">
                    <p class="text-warning"> {{messages("info.msg.request.email")}} </p>
                    <div class="form-group form-row">
                        <label class="col-form-label">{{messages("label.request.email")}}</label>
                        <div class="col">
                            <input type="text" class="form-control"
                                   :disabled="!isEditable"
                                   v-model="pedido.emailAlt"
                                   :placeholder="messages('label.request.email')">
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-7">
                    <div class="form-group">
                        <label class="col-form-label">{{messages("label.request.description")}}</label>
                        <textarea class="form-control"
                                  :class="{'is-invalid': $v.pedido.descricao.$error }" rows="8"
                                  :disabled="!isEditable"
                                  v-model="pedido.descricao"></textarea>
                        <span v-show="!$v.pedido.descricao.required" class="invalid-feedback">{{messages('error.field.required')}}</span>
                    </div>
                </div>


                <div class="col-5">

                    <div class="form-group row">
                        <label class="col-form-label mr-2">{{messages("label.request.date")}}</label>
                        <date-picker :date.sync="pedido.dataPedido" :disabled="!isEditable"></date-picker>
                        <time-picker :time.sync="pedido.horaPedido" :disabled="!isEditable"></time-picker>
                    </div>

                    <div class="form-group row">
                        <label class="col-form-label">{{messages("label.tipo.pedido")}}</label>
                        <div class="col">
                            <select class="form-control"
                                    :class="{'is-invalid': $v.pedido.tipo.$error }"
                                    :disabled="!isEditable"
                                    v-model="pedido.tipo">
                                <option :value="null">-- {{messages("label.tipo.pedido")}} --</option>
                                <option v-for="tipo in tiposPedido" :key="tipo.id" :value="tipo.id">{{messages(tipo.key)}}</option>
                            </select>
                            <span v-show="!$v.pedido.tipo.required" class="invalid-feedback">{{messages("error.field.required")}}</span>
                        </div>
                    </div>

                    <div class="form-group form-row">
                        <label class="col-form-label mr-2">{{messages("label.pedido.refexterna")}}</label>
                        <div class="col">
                            <input type="text" class="form-control"
                                   :disabled="!isEditable"
                                   v-model="pedido.referenciaSage">
                        </div>
                    </div>

                </div>

            </div>


            <div class="row">
                <div class="col-12">
                    <div class="form-group form-row">
                        <label class="col-form-label">{{messages("label.confidential.info")}}</label>
                        <div class="col">
                            <textarea class="form-control" rows="8"
                                      :disabled="!isEditable"
                                      v-model="pedido.observacoesConfidenciais"></textarea>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="card-footer">

            <div class="row justify-content-end">

                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/pedidos'}"
                                 class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.cancel")}}
                    </router-link>

                    <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                        <button class="btn btn-success" v-on:click="editarPedido">
                            {{messages("button.update.pedido")}}&nbsp;
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </button>
                    </profiles-allowed>

                </div>

            </div>

        </div>


    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {required} from 'vuelidate/lib/validators'

    import {messagesMixin} from "../../mixins/messagesMixin";
    import router from "@/router";

    export default {
        name: "EditarPedidoPanel",
        mixins: [messagesMixin],
        mounted() {
            this.loadPedido(this.$route.params.pedidoId)
                .then((resp) => {
                    this.pedido = resp.data
                })
        },

        data() {
            return {
                pedido: {
                    estadoPedidoKey: '',
                },
            }
        },

        computed: {
            ...mapGetters({
                userProfile: 'getUserProfile'
            }),

            ...mapGetters('pedidos', {
                clientes: 'getAgenciasAtivas',
                tiposPedido: 'getTiposPedido'
            }),

            isEditable() {
                return this.userProfile !== 'tecnico'
            }
        },

        validations: {
            pedido: {
                tipo: {
                    required,
                },
                descricao: {
                    required,
                },
                numeroPedidoCliente: {
                    required,
                }
            }
        },

        methods: {
            ...mapActions('pedidos', {
                loadPedido: 'loadPedido',
                doEditar: 'editarPedido',
                doCancelPedido: 'cancelPedido'
            }),

            ...mapMutations({
                dialog: 'setDialogProps'
            }),

            editarPedido() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.error("validation ERROR!");
                    return;
                }

                this.doEditar(this.pedido)
                    .then(resp => {
                        this.$router.push({path: '/pedidos'})
                    })
            },

            cancelarPedido() {
                this.dialog({
                    show: true,
                    message: this.messages('confirm.msg.cancel.request', this.pedido.referenciaPedido),
                    callback: () => {
                        this.doCancelPedido(this.pedido.pedidoId)
                            .then(() => {
                                this.loadDataList()
                                this.$router.push({path: '/pedidos'})
                            })
                    }
                })
            },

            agendarPedido(){
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.error("validation ERROR!");
                    return;
                }

                this.doEditar(this.pedido)
                    .then(resp => {
                        this.$router.push({path: '/pedidos/'+ this.pedido.pedidoId+'/agendar'})
                    })
            }

        }

    }
</script>

<style scoped>

</style>
