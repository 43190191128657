<template>

    <div class="wrapper">
        <button class="btn btn-sm btn-outline-secondary" v-on:click="clear">
            <font-awesome-icon icon="times"/>
        </button>
        <canvas ref="signPad" class="signature-pad" width=500 height=150 v-on:mouseup="sign"
                v-on:touchend="sign"></canvas>
    </div>


</template>

<script>

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import SignaturePad from 'signature_pad'

library.add(faTimes)

export default {
    components: {
        FontAwesomeIcon
    },

    mounted() {
        this.canvas = this.$refs.signPad
        this.signaturePad = new SignaturePad(this.canvas);
    },

    data() {
        return {
            canvas: null,
            signaturePad: null
        }
    },

    methods: {

        sign() {
            // console.log('sign')
            let data = this.signaturePad.toDataURL('image/png');
            this.$emit('signed', data)
        },

        clear() {
            this.signaturePad.clear();
            this.$emit('signed', '')
        }

    }

}


</script>

<style scoped>

div.wrapper {
    border: 1px solid #aaa;
    border-radius: 10px;
    position: relative;
    width: 502px;
    height: 200px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.signature-pad {
    position: absolute;
    left: 0;
    top: 40px;
    width: 500px;
    height: 150px;
    background-color: white;
    border-top: 1px solid gainsboro;
}

button {
    position: relative;
    float: right;
    margin-top: 5px;
    margin-right: 5px;
}

</style>
