<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: "ContratosModule",
        setup(props, ctx){
            // console.log('ContratosModule ctx', ctx)
        }
    }
</script>

<style scoped>

</style>
