<template>

    <div class="modal" tabindex="-1" role="dialog" ref="modalElement">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{state.title}}</h5>
                    <button type="button" class="close" @click="answerCancel" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p v-html="state.message"></p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-secondary"
                            @click="answerCancel">
                        {{ state.cancelText }}
                    </button>
                    <button class="btn "
                            :class="state.successStyle"
                            @click="answerOk">
                        {{ state.successText }}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
        import $ from 'jquery'
    import {computed, reactive, ref, watch} from '@vue/composition-api';
    import {messagesMixin} from "../../mixins/messagesMixin";

    export default {
        name: "DialogModal",
        mixins: [messagesMixin],
        setup(props, {root: {$store}}) {
            // console.log('dialog modal $store', $store)
            const modalElement = ref(null)

            const state = reactive({
                title: computed(() => $store.getters.getDialogProps.title),
                successStyle: computed(() => $store.getters.getDialogProps.successStyle),
                message: computed(() => $store.getters.getDialogProps.message),
                successCallback: computed(() => $store.getters.getDialogProps.successCallback),
                cancelCallback: computed(() => $store.getters.getDialogProps.cancelCallback),
                successText: computed(() => $store.getters.getDialogProps.successText),
                cancelText: computed(() => $store.getters.getDialogProps.cancelText),
            })

            const show = computed(() => $store.getters.getDialogProps.show)
            watch(show, (val) => {
                val ? $(modalElement.value).modal('show') : $(modalElement.value).modal('hide')
            })

            function answerOk() {
                /*if (!!state.successCallback) {*/
                var checkSuccessCallback = !!state.successCallback;
                if(checkSuccessCallback){
                    state.successCallback()
                }
                $store.commit('setDialogProps', {show: false})
            }

            function answerCancel() {
                /*if (!!state.cancelCallback) {*/
                var checkCancelCallback = !!state.cancelCallback;
                if(checkCancelCallback){
                    state.cancelCallback()
                }
                $store.commit('setDialogProps', {show: false})
            }

            return {
                state,
                modalElement,
                answerOk,
                answerCancel
            }


        }


    }
</script>

<style scoped>

</style>
