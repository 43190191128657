<template>
    <div class="modal" tabindex="-1" role="dialog" ref="addItemModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ messages('add.material.modal.title') }}</h5>
                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="form-row form-group">
                        <input type="text" class="form-control"
                               v-model="state.filterText"
                               :placeholder="messages('label.placeholder.filter')">
                    </div>

                    <hr>

                    <div class="form-row form-group">
                        <select class="form-control" v-model="state.selectedId">
                            <option :value="null">{{ messages("label.select.item") }}</option>
                            <option :value="item.id" v-for="item in state.listItems" :key="item.id">
                                {{ item.text }}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-secondary"
                            @click="closeModal">
                        {{ messages("button.cancel") }}
                    </button>
                    <button type="button"
                            @click="addArtigo"
                            class="btn btn-primary">
                        {{ messages('button.add') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, onMounted, reactive, ref} from "@vue/composition-api";
import {messagesMixin} from "../../../mixins/messagesMixin";

export default {
    name: "MaterialAplicadoModal",
    mixins: [messagesMixin],

    props: ['list'],

    setup(props, {root: {$store}, emit}) {

         /*console.log("AddServicoModal",ctx)*/


        /*onMounted(() => {
            $store.dispatch('artigos/loadArtigosSelect')
        })*/


        const addItemModal = ref(null)

        const state = reactive({
            selectedId: null,
            filterText: '',
            listItems: computed(() => props.list.filter(value => value.text.toLowerCase().includes(state.filterText.toLowerCase())))
        })

         const addArtigo = () => {
            const selectedArtigo = state.listItems.find(val => val.id === state.selectedId)
            const data = {
                folhaObraId: null,
                artigoId: state.selectedId,
                descricao: selectedArtigo.text,
                origem: null,
                quantidade: null,
                precoDisplay: selectedArtigo.preco
            }
             emit('added', data)
             closeModal()
         }

        function showModal() {
            state.selectedId = null
            state.filterText = ''
            $(addItemModal.value).modal('show')
        }

        function closeModal() {
            $(addItemModal.value).modal('hide')
        }


        return {
            addItemModal,
            state,
            showModal,
            closeModal,
            addArtigo
        }
    }
}
</script>

<style scoped>

</style>
