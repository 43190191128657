<template>
    <div :class="state.classStyle">
        <slot v-if="state.showContent"></slot>
    </div>
</template>

<script>

    import {computed, reactive} from '@vue/composition-api'


    export default {

        name: "ProfilesAllowed",

        props: {

            authorizedProfiles: {
                type: Array,
                required: true
            },

        },

        setup(props, {root: {$store, $el}} /*ctx*/) {
            // console.log('ProfilesAllowed setup ctx', ctx)
            const state = reactive({
                showContent: computed(() => {
                    return props.authorizedProfiles.includes($store.getters['getUserProfile'])
                }),
                classStyle: computed(() => {
                    return (state.showContent ? 'restrictor' : 'restrictor-none')
                })
            })

            return {
                state
            }

        },
    }
</script>

<style scoped>
    .restrictor {
        display: inline;
    }

    .restrictor-none {
        display: none;
    }


</style>
