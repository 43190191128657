<template>
    <div class="card">

        <div class="card-header">
            <h3>{{messages("panel.pedidos.title")}}</h3>
        </div>

        <ul class="list-group">

            <!-- FILTRO -->
            <li class="list-group-item">

                <div class="form-row form-group">
                    <label class="col-form-label">{{messages("label.date.from")}}</label>
                    <div class="col-auto">
                        <date-picker :date="filterData.dateFrom"
                                     v-on:day-selected="filterData.dateFrom = $event"></date-picker>
                    </div>
                    <label class="col-form-label">{{messages("label.date.to")}}</label>
                    <div class="col-auto">
                        <date-picker :date="filterData.dateTo"
                                     v-on:day-selected="filterData.dateTo = $event"></date-picker>
                    </div>

                    <div class="col">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{messages("label.client")}}</label>
                            <div class="col">
                                <select class="custom-select" v-model="filterData.cliente">
                                    <option :value="null">-- {{messages("label.client")}} --</option>
                                    <option :value="cliente.id" :key="cliente.id" v-for="cliente in clientes">{{ cliente.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row mt-3">
                    <div class="col">
                        <div class="form-row">
                            <label class="col-form-label">{{messages("label.tipo.pedido")}}</label>
                            <div class="col">
                                <select class="custom-select" v-model="filterData.tipo">
                                    <option :value="null">-- {{messages("label.tipo.pedido")}} --</option>
                                    <option v-for="tipo in tiposPedido" :value="tipo.id" :key="tipo.id">{{messages(tipo.key)}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-row">
                            <label class="col-form-label">{{messages("label.estado.pedido")}}</label>
                            <div class="col">
                                <select class="custom-select" v-model="filterData.estado">
                                    <option :value="null">-- {{messages("label.estado.pedido")}} --</option>
                                    <option v-for="estado in estados" :value="estado.id" :key="estado.id">{{messages(estado.key)}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>


                </div>


                <div class="row justify-content-center mt-4">
                    <button class="btn btn-outline-secondary" @click="resetFilter()">{{ messages("button.clean") }}
                    </button>
                    <button class="btn btn-primary" style="margin-left: 15px;" @click="loadDataList()">
                        {{messages("button.search")}}
                    </button>
                </div>
            </li>

            <!-- ACTION BUTTON BAR -->
            <profiles-allowed :authorized-profiles="['admin', 'backoffice']">
                <li class="list-group-item">
                    <router-link tag="button"
                                 :to="{name: 'pedidoCreate'}"
                                 class="btn btn-success">
                        {{ messages('button.criar.pedido') }}&nbsp;
                        <font-awesome-icon icon="plus"></font-awesome-icon>
                    </router-link>
                </li>
            </profiles-allowed>

            <table-list :list="pedidos" :page.sync="pedidosPage">
                <template #header>
                    <th scope="col">#</th>
                    <th scope="col">{{messages("label.client.name")}}</th>
                    <th scope="col">{{messages("label.tipo.pedido")}}</th>
                    <th scope="col">{{messages("label.date")}}</th>
                    <th scope="col">{{messages("label.estado.pedido")}}</th>
                    <th scope="col">{{messages("label.pedido.refexterna.abrv")}}</th>
                    <th scope="col">{{messages("label.client.request.number")}}</th>
                    <th scope="col"></th>
                </template>

                <tbody>
                <tr v-for="pedido in pedidosPage" :key="pedido.referenciaPedido">
                    <th scope="row">
                        {{ pedido.referenciaPedido }}
                    </th>
                    <td>{{ pedido.clienteNome }}</td>
                    <td>{{ messages(pedido.tipoPedidoKey) }}</td>
                    <td>{{ pedido.dataRegisto }}</td>
                    <td :class="getEstadoStyle(pedido)">
                        {{ messages(pedido.estadoPedidoKey) }}
                    </td>
                    <td>{{ pedido.referenciaSage }}</td>
                    <td>{{ pedido.numeroPedidoCliente }}</td>
                    <td class="text-right">


                        <button class="btn btn-outline-primary btn-sm mr-1"
                                data-toggle="tooltip"
                                data-placement="left"
                                :title="messages('tooltip.view.request')"
                                @click="showPedidoInfo(pedido)">
                            <font-awesome-icon icon="search"></font-awesome-icon>
                        </button>


                        <profiles-allowed :authorized-profiles="['admin', 'backoffice']">

                            <router-link class="btn btn-outline-success btn-sm  mr-1"
                                         v-show="pedido.estado === 'ABERTO'"
                                         tag="button"
                                         :to="{name: 'pedidoAgendar', params: { pedidoId: pedido.pedidoId }}"
                                         data-toggle="tooltip"
                                         data-placement="left"
                                         data-trigger="hover"
                                         :title="messages('tooltip.book.request')">
                                <font-awesome-icon icon="book"></font-awesome-icon>
                            </router-link>

                            <button class="btn btn-outline-danger btn-sm  mr-1"
                                    v-show="pedido.estado === 'ABERTO'"
                                    data-toggle="tooltip" data-placement="left"
                                    :title="messages('tooltip.cancel.request')"
                                    v-on:click="cancelPedido(pedido)">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </button>

                        </profiles-allowed>

                    </td>
                </tr>
                </tbody>
            </table-list>

        </ul>

        <info-pedido-modal ref="pedidoModal" :selected-pedido="selectedPedido"></info-pedido-modal>


    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import moment from 'moment'

    import {messagesMixin} from "../../mixins/messagesMixin";
    import TableList from "../../components/TableList";
    import InfoPedidoModal from "./InfoPedidoModal";

    export default {
        name: "PedidosMainList",
        components: {InfoPedidoModal, TableList},
        mixins: [messagesMixin],

        mounted() {
            this.loadDataList()
        },

        data() {
            return {

                filterData: {
                    tipo: null,
                    cliente: null,
                    dateFrom: moment().subtract(1, 'months').format('DD-MM-YYYY'),
                    dateTo: moment().format('DD-MM-YYYY'),
                    estado: null,
                    tecnico: null,
                },

                pedidosPage: [],

                selectedPedido: {
                    estadoPedidoKey: '',
                    estado: ''
                },

            }
        },

        computed: {
            ...mapGetters('pedidos', {
                tiposPedido: 'getTiposPedido',
                estados: 'getEstadosPedido',
                pedidos: 'getPedidosList',
            }),

            ...mapGetters({
                clientes: 'getAgenciasAtivasSelect'
            }),
        },

        beforeRouteLeave(to, from, next) {
            // console.log('beforeRouteLeave')
            $('[data-toggle="tooltip"]').tooltip('hide');
            next()
        },

        methods: {

            ...mapActions('pedidos', {
                loadPedidos: 'loadPedidos',
                doCancelPedido: 'cancelPedido'
            }),

            ...mapMutations({
                dialog: 'setDialogProps'
            }),


            loadDataList() {
                this.loadPedidos(this.filterData)
                    .then(() => {
                        this.$nextTick(function () {
                            $('[data-toggle="tooltip"]').tooltip();
                        });
                    })
            },

            resetFilter: function () {
                this.filterData.dateFrom = moment().subtract(1, 'months').format('DD-MM-YYYY');
                this.filterData.dateTo = moment().format('DD-MM-YYYY');
                this.filterData.tipo = null;
                this.filterData.estado = null;
                this.loadPedidos(this.filterData);
            },

            cancelPedido: function (pedido) {
                // console.log("cancelPedido %o", pedido);
                const self = this;

                this.dialog({
                    show: true,
                    message: this.messages('confirm.msg.cancel.request', pedido.referenciaPedido),
                    callback: () => {
                        this.doCancelPedido(pedido.pedidoId)
                            .then(() => {
                                this.loadDataList()
                            })
                    }
                })

            },

            showPedidoInfo: function (pedido) {
                if (pedido.estado === 'ABERTO') {
                    this.$router.push({name: 'pedidoEditar', params: {pedidoId: pedido.pedidoId}})
                    return;
                }

                this.selectedPedido = pedido;
                this.$refs.pedidoModal.showModal()
            },

            getEstadoStyle(pedido) {
                switch (pedido.estado) {
                    case 'CANCELADO':
                        return 'text-danger'
                    case 'AGENDADO':
                        return 'text-primary'
                    case 'ABERTO':
                        return 'text-success'
                    default:
                        return ''
                }
            },

        }


    }
</script>

<style scoped>

</style>
