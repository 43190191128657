<template>
  <router-view></router-view>
</template>

<script>
  import {onMounted} from '@vue/composition-api'

  export default {
    name: "PedidosModule",

    setup(props, {root: {$store}}) {

      onMounted(() => {
        $store.dispatch('loadAgenciasAtivasSelect')
      })

    }
  }
</script>

<style scoped>

</style>
