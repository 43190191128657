@import models.enums.Unidades
<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="myModal" ref="artigoModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="alterMode">{{messages("tooltip.edit.artigo")}}</h5>
                    <h5 class="modal-title" v-if="!alterMode">{{messages("tooltip.create.artigo")}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                </div>
                <div class="modal-body">


                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.code")}}</label>
                        <div class="col">
                            <input type="text" class="form-control"
                                   v-model="artigo.codigo"
                                   :readonly="alterMode"
                                   :class="{'is-invalid': $v.artigo.codigo.$error || codeRepeated}"
                                   :placeholder="messages('label.code')">
                            <span v-show="!$v.artigo.codigo.required"
                                  class="invalid-feedback">{{messages("error.field.required")}}</span>
                            <span v-show="!$v.artigo.codigo.maxLength"
                                  class="invalid-feedback">{{messages("error.field.maxlength", $v.artigo.codigo.$params.maxLength.max)}}</span>
                            <span v-show="codeRepeated"
                                  class="invalid-feedback">{{messages("error.code.repeated")}}</span>



                        </div>
                    </div>

                    <div class="col">
                        <div class="form-group row">
                            <label class="col-md-auto col-form-label">{{messages("label.artigo.name")}}</label>
                            <div class="col">
                                <input v-model="artigo.nome" class="form-control"
                                       :class="{'is-invalid': $v.artigo.nome.$error }"
                                       :placeholder="messages('label.artigo.name')">
                                <span v-show="!$v.artigo.nome.required"
                                      class="invalid-feedback">{{messages("error.field.required")}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="form-group row">
                            <label class="col-md-auto col-form-label">{{messages("label.artigo.descricao")}}</label>
                            <div class="col">
                                <input v-model="artigo.descricao" class="form-control"
                                       :placeholder="messages('label.artigo.descricao')">
                            </div>
                        </div>
                    </div>


                    <div class="col">
                        <div class="form-group row">
                            <label class="col-md-auto col-form-label">{{messages("label.artigo.preco")}}</label>
                            <div class="col">
                                <input v-model="artigo.preco" class="form-control"
                                       :class="{'is-invalid': $v.artigo.preco.$error }"
                                       :placeholder="messages('label.artigo.preco')">
                                <span v-show="!$v.artigo.preco.decimal"
                                      class="invalid-feedback">{{messages("error.field.decimals")}}</span>

                            </div>
                        </div>
                    </div>


                    <div class="col">
                        <div class="form-group row">
                            <label class="col-md-auto col-form-label">{{messages("label.artigo.unidade")}}</label>
                            <div class="col">
                                <select  v-model="artigo.unidade" class="form-control"
                                        :class="{'is-invalid': $v.artigo.unidade.$error }">
                                    <option>-- {{messages("label.artigo.unidade")}} --</option>
                                    <option v-for="unidade in unidades" :value="unidade.id" :key="unidade.id">{{messages(unidade.id)}}</option>
                                </select>
                                <span v-show="!$v.artigo.unidade.required"
                                      class="invalid-feedback">{{messages("error.field.required")}}</span>


                            </div>
                        </div>
                    </div>

                    <div class="custom-control custom-switch">
                        <input class="custom-control-input" id="active_item_switch" type="checkbox"
                               v-model="artigo.ativo">
                        <label class="custom-control-label" for="active_item_switch">
                            {{messages("label.active")}}
                        </label>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            v-on:click="refreshArtigos">
                        {{messages("button.close")}}
                    </button>
                    <button type="button" class="btn btn-primary" v-on:click="saveArtigo(artigo)">
                        {{messages("button.save")}}
                        <font-awesome-icon icon="save"></font-awesome-icon>
                    </button>

                </div>
            </div>

        </div>

    </div>
</template>

<script>

    import {ref} from '@vue/composition-api'
    import $ from 'jquery'
    import {maxLength, required, decimal} from 'vuelidate/lib/validators'
    import {messagesMixin} from "../../mixins/messagesMixin";

    export default {
        name: "ArtigoModal",
        mixins: [messagesMixin],
        props: {
            artigo: {
                type: Object,
                required: false,
                default() {
                    return {
                        codigo: '',
                        nome: '',
                        descricao: "",
                        ativo: true,
                        preco: '',
                        unidade: []
                    }
                }
            },
            alterMode: Boolean,

            artigosPage: Array,
            unidades: Array

        },


        setup(props, ctx) {

            const artigoModal = ref(null)


            function showModal() {
                $(artigoModal.value).modal('show')
            }

            function closeModal() {
                $(artigoModal.value).modal('hide')
            }

            return {
                artigoModal,
                showModal,
                closeModal
            }

        },

        validations: {
            artigo: {
                codigo: {
                    required,
                    maxLength: maxLength(50),
                },
                nome: {
                    required,
                    maxLength: maxLength(250)
                },
                preco: {
                    decimal,
                },
                unidade: {
                    required
                }
            }
        },

        data() {
            return{
                codeRepeated: false,
            }
        },


        methods: {

            refreshArtigos() {
                /*this.$emit('loadDataList')*/
               this.$router.go();
            },

            saveArtigo(artigo) {
                console.log('saveArtigo')
                this.codeRepeated=false;
                if(!this.alterMode){
                    this.checkArtigo();
                }
                this.$v.$touch();

                if (this.$v.$invalid)  {
                    return;
                }
                if (this.codeRepeated){
                    return;
                }

                this.$emit('saveArtigo', artigo)

                //this.$router.go();
            },

            checkArtigo(){
                for (var i = 0; i < this.artigosPage.length; i++) {
                    var repeated = this.artigo.codigo.localeCompare(this.artigosPage[i].codigo);
                    if (repeated === 0 && !this.editMode) {
                        this.codeRepeated = true;
                        return;
                    }
                }

            }
        }
    }

</script>

<style scoped>

</style>
