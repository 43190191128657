<template>
    <div class="card mt-5" id="clienteCreateContainer">

        <div class="card-body" v-cloak>

            <div class="row">

                <div class="col">
                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.client.code")}}</label>
                        <div class="col">
                            <input v-model="agencia.code" class="form-control"
                                   :readonly="agencia.code"
                                   :placeholder="messages('label.client.code')"
                                   :class="{'is-invalid': $v.agencia.code.$error}">
                            <span v-show="!$v.agencia.code.maxLength"
                                  class="invalid-feedback">{{messages("error.field.maxlength")}}</span>
                            <span v-show="!$v.agencia.code.required"
                                  class="invalid-feedback">{{messages("error.field.required", $v.agencia.code.$params.maxLength.max)}}</span>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.client.name")}}</label>
                        <div class="col">
                            <input v-model="agencia.nome" class="form-control"
                                   :placeholder="messages('label.client.name')"
                                   :class="{'is-invalid': $v.agencia.nome.$error}">
                            <span v-show="!$v.agencia.nome.maxLength"
                                  class="invalid-feedback">{{messages("error.field.maxlength")}}</span>
                            <span v-show="!$v.agencia.nome.required"
                                  class="invalid-feedback">{{messages("error.field.required", $v.agencia.nome.$params.maxLength.max)}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <!--NOVO EMAILS-->
                    <div class="form-group form-row">
                        <label class="col-form-label font-weight-bold">{{messages("label.email.var")}}</label>
                        &nbsp;&nbsp;
                        <button class="btn btn-success btn-sm rounded" v-show="listaEmails.length < 5" v-on:click="addEmailAddress">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="form-group form-row" v-for="(email,idx) in $v.listaEmails.$each.$iter" :key="email.id">
                        <div class="col">
                            <input type="text" v-model="email.text.$model"
                                   class="form-control"
                                   :class="{ 'is-invalid': email.$error }">
                            <span class="invalid-feedback">{{messages("error.field.email")}}</span>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-outline-danger btn-sm rounded"
                                    v-on:click="removeEmailAddress(idx)">
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <!--FIM NOVO EMAILS &ndash;&gt;-->
                </div>

                <div class="col">
                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.contact.name")}}</label>
                        <div class="col">
                            <input v-model="agencia.contato" class="form-control"
                                   :placeholder="messages('label.contact.name')">
                        </div>
                    </div>

                </div>

            </div>

            <div class="row">

                <div class="col">
                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.manager.name")}}</label>
                        <div class="col-6">
                            <input v-model="agencia.nomeResponsavel" class="form-control"
                                   :placeholder="messages('label.manager.name')">
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.client.zone")}}</label>
                        <div class="col">
                            <select v-model="agencia.zonaClienteId" class="form-control">
                                <option :value="null">&#45;&#45; {{messages("label.client.zone")}} &#45;&#45;</option>
                                <option :value="item.id" v-for="item in zonasAgenciasList " :key="item.id">{{item.text}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.address")}}</label>
                        <div class="col">
                            <textarea v-model="agencia.morada" rows="5" class="form-control"
                                      :placeholder="messages('label.address')"></textarea>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group row">
                        <label class="col-md-auto col-form-label">{{messages("label.observacoes")}}</label>
                        <div class="col">
                            <textarea v-model="agencia.observacoes" rows="5" class="form-control"
                                      :placeholder="messages('label.observacoes')"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-control custom-switch">
                <input class="custom-control-input" id="active_user_switch" type="checkbox" v-model="agencia.ativo">
                <label class="custom-control-label" for="active_user_switch">
                    {{messages("label.active")}}
                </label>
            </div>
        </div>


        <div class="card-footer">

            <div class="row justify-content-end">

                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/cliente-admin'}"
                                 class="btn btn-outline-secondary mr-2">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.cancel")}}
                    </router-link>

                    <button class="btn btn-primary mr-2" @click="saveAgencia()">
                        {{messages("button.update.cliente")}}&nbsp;
                        <font-awesome-icon icon="save"></font-awesome-icon>
                    </button>

                </div>
            </div>
        </div>


    </div>
</template>

<script>

    import {maxLength, required, email, helpers} from 'vuelidate/lib/validators'
    import {messagesMixin} from "../../mixins/messagesMixin";
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default {
        name: "AgenciasMainPanel",
        mixins: [messagesMixin],

        mounted() {
            this.loadAgencia(this.$route.params.agenciaId)
                .then((resp) => {
                    this.agencia=resp.data;
                    this.createListaEmails();
                })
        },

        data() {

            return {
                agencia: {
                    id: null,
                    nome: null,
                    zonaClienteId: null,
                    code: "",
                    ativo: true,
                    morada: '',
                    contato: '',
                    nomeResponsavel: '',
                    email: '',
                    contribuinte: '',
                    observacoes: '',

                },
                listaEmails: [],
                emailsList: [],
                pseudoId: 0,

            }
        },

        computed: {
            ...mapGetters({
                zonasAgenciasList: 'getZonasAgencias',
            }),
        },

        methods: {
            ...mapMutations({
                dialog: 'setDialogProps'
            }),

            ...mapActions('agencias', {
                loadAgencia: 'loadAgencia',
                updateAgencia: 'updateAgencia'
            }),

            saveAgencia: function () {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    //console.error("validation ERROR!");
                    return;
                }
                this.agencia.email ="";
                this.agencia.email = this.listaEmails.filter(value => !!value.text).map(value => value.text).join(';');
                this.updateAgencia(this.agencia).then(resp => {
                    this.$router.push({path: '/cliente-admin'})
                })


            },

            loadZonasAgencias(){
                this.zonasAgenciasList().then(() => {
                    this.$nextTick(function () {
                        $('[data-toggle="tooltip"]').tooltip();
                    });
                })
            },

            createListaEmails(){
                this.emailsList = this.agencia.email.split(';')
                this.emailsList.forEach(value=> {
                    this.listaEmails.push({id: this.pseudoId++, text: value})
                })
            },

            addEmailAddress(){
                this.listaEmails.push({id: this.pseudoId++, text: ''})
            },

            removeEmailAddress(idx) {
                //console.log('removeEmailAddress', idx)
                this.listaEmails.splice(idx, 1)
            },
        },

        validations: {
            agencia: {
                code: {
                    maxLength: maxLength(50),
                    required
                },
                nome: {
                    maxLength: maxLength(250),
                    required
                },
            },
            listaEmails: {
                $each: {
                    text: {
                        email
                    }
                }

            },
        }

    }
</script>

<style scoped>

</style>
