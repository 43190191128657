import { render, staticRenderFns } from "./PlaneamentoMainPanel.vue?vue&type=template&id=3d5842e4&scoped=true&"
import script from "./PlaneamentoMainPanel.vue?vue&type=script&lang=js&"
export * from "./PlaneamentoMainPanel.vue?vue&type=script&lang=js&"
import style0 from "./PlaneamentoMainPanel.vue?vue&type=style&index=0&id=3d5842e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d5842e4",
  null
  
)

export default component.exports