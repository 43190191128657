<template>

    <div class="col">
        <div class="btn-container">
            <button class="btn btn-outline-secondary btn-sm"
                    :disabled="disabled"
                    @click="changeHours(1)">
                <font-awesome-icon icon="arrow-alt-circle-up"/>
            </button>

            <button class="btn btn-outline-secondary btn-sm"
                    :disabled="disabled"
                    @click="changeHours(-1)">
                <font-awesome-icon icon="arrow-alt-circle-down"/>
            </button>
        </div>

        <div class="values-container">

            <input type="text" class="form-control"
                   :disabled="disabled"
                   v-model.number="hours">
            <label>:</label>
            <input type="text" class="form-control"
                   :disabled="disabled"
                   v-model.number="minutes">
        </div>

        <div class="btn-container">
            <button class="btn btn-outline-secondary btn-sm"
                    :disabled="disabled"
                    @click="changeMinutes(1)">
                <font-awesome-icon icon="arrow-alt-circle-up"/>
            </button>

            <button class="btn btn-outline-secondary btn-sm"
                    :disabled="disabled"
                    @click="changeMinutes(-1)">
                <font-awesome-icon icon="arrow-alt-circle-down"/>
            </button>
        </div>

    </div>

</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
    import {faArrowAltCircleDown, faArrowAltCircleUp} from '@fortawesome/free-solid-svg-icons'

    import moment from 'moment'

    library.add(faArrowAltCircleUp)
    library.add(faArrowAltCircleDown)

    export default {
        components: {
            FontAwesomeIcon
        },
        props: {
            time: {
                type: String,
                default: moment().format("HH:mm")
            },
            disabled: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },

        data() {
            return {
                hours: '',
                minutes: ''
            }
        },

        mounted() {
            this.hours = this.time.split(":")[0];
            this.minutes = this.time.split(":")[1];
        },

        /* computed: {
           hours() {
             return this.time.split(":")[0];
           },

           minutes() {
             return this.time.split(":")[1];
           }
         },*/

        watch: {

            hours(val, oldVal) {
                // console.log(val, oldVal)
                let newHours = Number(val)
                if (val > 23 || val < 0) {
                    newHours = 0
                }
                this.setTime(this.formatValue(newHours) + ':' + this.minutes)
            },

            minutes(val) {
                let newMinutes = Number(val)
                if (newMinutes > 59 || newMinutes < 0) {
                    newMinutes = 0
                }
                this.setTime(this.hours + ':' + this.formatValue(newMinutes))
            },
            time() {
                this.hours = this.time.split(":")[0];
                this.minutes = this.time.split(":")[1];
            }
        },

        methods: {

            changeHours(val) {
                let newHours = Number(this.hours)
                if (val > 0) {
                    if (newHours >= 23) {
                        newHours = '00';
                    } else {
                        newHours = (newHours + 1) + ''
                    }
                } else {
                    if (newHours <= 0) {
                        newHours = '23';
                    } else {
                        newHours = (newHours - 1) + ''
                    }
                }
                this.setTime(this.formatValue(newHours) + ':' + this.minutes)

            },

            changeMinutes(val) {
                let newMinutes = Number(this.minutes)
                if (val > 0) {
                    if (newMinutes >= 59) {
                        newMinutes = '00';
                    } else {
                        newMinutes = (newMinutes + 1) + ''
                    }
                } else {
                    if (newMinutes <= 0) {
                        newMinutes = '59';
                    } else {
                        newMinutes = (newMinutes - 1) + ''
                    }
                }
                this.setTime(this.hours + ':' + this.formatValue(newMinutes))
            },

            newHours() {

            },

            newMinutes() {
            },

            setTime(time) {
                this.$emit("change-time", time)
                this.$emit('update:time', time)
            },

            formatValue(val) {
                if (val >= 10) {
                    return val + '';
                }
                return '0' + val
            },

        },

    }

</script>

<style scoped>

    label {
        margin-top: 1px;
        font-size: 1.2rem;
    }

    button.btn-sm {
        padding: 0.1rem .5rem;
        font-size: .6rem;
        line-height: 1.4;
        border-radius: .2rem
    }

    div.btn-container {
        width: 30px;
        height: 2.25rem;
        display: grid;
        float: left;
    }

    div.values-container {
        height: 2.25rem;
        display: flex;
        float: left;
    }

    div.values-container input {
        margin-left: 4px;
        margin-right: 4px;
        width: 45px;
    }

    div.col {
        padding: 0;
        display: inline-flex;
        width: 173px;
    }

</style>
