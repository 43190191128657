<template>
  <div class="form-row">
    <label class="col-form-label">{{ title }}</label>
    <div class="col-auto">
      <select type="text" class="form-control" v-model="itemsShow" @change="changeItems">
        <option :value="10">10</option>
        <option :value="20">20</option>
        <option :value="50">50</option>
        <option :value="100">100</option>
      </select>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ItemsSelector",

    data() {
      return {
        itemsShow: 10,
        title: "",
      }
    },


    methods: {

      changeItems() {
        this.$emit('items-changed', this.itemsShow)
      }

    },

    created() {
      this.title = Messages("label.show.items.num")
    }
  }
</script>

<style scoped>

</style>
