<template>

  <div class="card">
    <ul class="list-group">
      <li class="list-group-item">
        <month-bar :month="currentMonth" @dateChanged="dateChanged"></month-bar>
      </li>
      <li class="list-group-item">
        <month :days="monthDays" :is-tecnico="isTecnico" @view-day="inspectDay"></month>
      </li>
    </ul>
  </div>


</template>

<script>

  import moment from 'moment'
  import Month from './Month'
  import MonthBar from './MonthBar'

  const MONTH_PATTERN = 'MM-YYYY'

  export default {
    name: "Calendar",

    components: {
      MonthBar,
      Month,

    },

    props: {

      listaeventos: Array,

      currentMonth:{
        type:String,
        default: moment().format(MONTH_PATTERN),
      },

      isTecnico:{
        type: Boolean
      }

    },

    computed: {

      monthDays() {
        let values = []

        let today = moment()
        let firstDayOfMonth = moment(this.currentMonth, MONTH_PATTERN).startOf('month')
        let firstWeeksDay = moment(firstDayOfMonth).startOf('isoWeek')

        // console.log("\nfirstDayOfMonth: %s\nfirstWeeksDay: %s", firstDayOfMonth.format('DD-MM-YYYYY'), firstWeeksDay.format('DD-MM-YYYYY'))

        let currentDay = moment(firstWeeksDay)
        let sameMonth = true
        let weekNumber = 1

        while (sameMonth) {
          for (var i = 0; i < 7; i++) {
            var day = {
              number: currentDay.date(),
              dayDate: moment(currentDay).format("DD-MM-YYYY"),
              isValid: currentDay.isSame(firstDayOfMonth, 'month'),
              weekNumber: weekNumber,
              isWeekend: currentDay.isoWeekday() === 6 || currentDay.isoWeekday() === 7,
              isToday: currentDay.isSame(today, 'day'),
              eventos: [],
            }

            this.listaeventos.forEach(val => {
              if (val.dataAgendamento === day.dayDate) {
                day.eventos.push(val)
              }
            })


            values.push(day)
            currentDay.add(1, 'day')
          }
          weekNumber++
          if (currentDay.isAfter(firstDayOfMonth, 'month')) {
            sameMonth = false
          }
        }
        // console.log("firstDayOfMonth %o", firstDayOfMonth)

        return values
      },

    },

    data() {
      return {

      }
    },

    methods: {
      dateChanged($event) {
        // console.log('dateChanged -> %s', moment($event).format(MONTH_PATTERN))
        // this.currentMonth = moment($event).format(MONTH_PATTERN)
        this.$emit('month-changed', moment($event).format(MONTH_PATTERN))
      },

      inspectDay(day){
        this.$emit('view-day', day)

      },

    },


  }
</script>

<style scoped>

</style>
