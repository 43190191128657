<template>
    <div class="modal" tabindex="-1" role="dialog" ref="addItemModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ messages('panel.contratos.add.servicos.btn') }}</h5>
                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="form-row form-group">
                        <select class="form-control"
                                v-model="state.selectedId">
                            <option :value="null">{{messages("label.select.item")}}</option>
                            <option :value="item.id" v-for="item in listItems" :key="item.id">{{ item.text }}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">{{messages("button.cancel")}}
                    </button>
                    <button type="button" class="btn btn-primary"
                            @click="addServico">
                        {{ messages('panel.contratos.add.servicos.btn') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed, onMounted, ref, reactive} from '@vue/composition-api'

    import {messagesMixin} from "../../../mixins/messagesMixin";

    export default {
        name: "AddServicoModal",
        mixins: [messagesMixin],
        props:['listItems'],

        setup(props, {root: {$store}, emit}) {

            // console.log("AddServicoModal",ctx)

            const addItemModal = ref(null)

            const state = reactive({
                selectedId: null,
            })

            const addServico = () => {
                emit('added', state.selectedId)
                closeModal()
            }


            function showModal() {
                state.selectedId = null
                $(addItemModal.value).modal('show')
            }

            function closeModal() {
                $(addItemModal.value).modal('hide')
            }


            return {
                addItemModal,
                state,
                showModal,
                closeModal,
                addServico
            }
        }
    }
</script>

<style scoped>

</style>
