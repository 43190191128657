<template>
    <div class="card-body border-top">

        <div class="form-group form-row">
            <div class="col-7">
                <h4>{{title}}</h4>
            </div>
            <label
                class="col-form-label col-1 font-weight-bold">{{messages('folhasobra.preventiva.panel.qtd.title')}}</label>
            <label class="col-form-label col text-center font-weight-bold">{{messages('label.observacoes')}}</label>
        </div>

        <div class="row" v-for="item in listItems" :key="item.id">

            <div class="col-7">
                {{item.codigo}} - {{item.descricao}}
            </div>

            <div class="col-1 text-center">
                <label class="col-form-label">{{item.quantidade}}</label>
            </div>

            <div class="col-1 text-center">
                <font-awesome-icon
                    :icon="getResultIcon(item.result)"
                    :class="getResultStyle(item.result)"
                    size="lg"></font-awesome-icon>
            </div>

            <div class="col">
                <label class="col-form-label">{{item.observacoes}}</label>
            </div>
        </div>

    </div>
</template>

<script>
    import {messagesMixin} from "../../../mixins/messagesMixin";

    import {getIconForBool, getTextStyleForBool} from "../../../compositionfn/utils";


    export default {
        name: "PreventivaFinalResultadoPanel",
        mixins: [messagesMixin],

        props: ['title', 'listItems'],


        setup() {

            const getResultIcon = (result) => getIconForBool(result)
            const getResultStyle = (result) => getTextStyleForBool(result)
            return {
                getResultIcon,
                getResultStyle
            }

        }

    }
</script>

<style scoped>

</style>
