/**
 *
 * Created by Filipe - 04/06/2020
 *
 */


export function getIconForBool(result) {
    if (result === null) {
        return ['far', 'circle']
    }
    return result ? ['far', 'check-circle'] : ['far', 'times-circle']
}

export function getButtonStyleForBool(result){
    if (result === null) {
        return 'btn-outline-secondary'
    }
    return result ? 'btn-outline-success' : 'btn-outline-danger'
}

export function getTextStyleForBool(result){
    if (result === null) {
        return 'text-secondary'
    }
    return result ? 'text-success' : 'text-danger'
}


export function changeStatus(status){
    if(status === null){
        return true
    }
    /*if(status){
        return false
    }*/
    return null
}


