<template>

    <div class="card">

        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <h4>{{messages("label.equipament")}}</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-row form-group">
                        <label class="col-form-label">{{messages("label.marca")}}:</label>
                        <label class="col-form-label col-auto font-weight-bold">{{objectData.equipamentoMarca}}</label>
                    </div>

                    <div class="form-row form-group">
                        <label class="col-form-label">{{messages("label.modelo")}}:</label>
                        <label class="col-form-label col-auto font-weight-bold">{{objectData.equipamentoModelo}}</label>

                    </div>
                </div>

                <div class="col-6">
                    <div class="form-row form-group">
                        <label class="col-form-label">{{messages("label.num.serie")}}:</label>
                        <label
                            class="col-form-label col-auto font-weight-bold">{{objectData.equipamentoNumSerie}}</label>
                    </div>
                </div>
            </div>

            <outros-tipos-final-panel ref="outrosTiposPanel"></outros-tipos-final-panel>



            <!--  material aplicado-->
            <material-aplicado-final-panel ref="materialsPanel" hidden></material-aplicado-final-panel>

        </div>

        <!-- CENTRAL DE DETECÇÃO E ALARME -->
        <preventiva-final-resultado-panel :title="messages('folhasobra.preventiva.panel.alarmes.title')" :list-items="centralDetecaoResultItems"></preventiva-final-resultado-panel>

        <!-- ELEMENTOS PERIFÉRICOS -->
        <preventiva-final-resultado-panel  :title="messages('folhasobra.preventiva.panel.perifericos.title')"  :list-items="perifericosResultItems"></preventiva-final-resultado-panel>





    </div>

</template>

<script>
    import {messagesMixin} from "../../../mixins/messagesMixin";
    import {computed, ref} from "@vue/composition-api";
    import PreventivaFinalResultadoPanel from "./PreventivaFinalResultadoPanel";
    import MaterialAplicadoFinalPanel from "@/views/folhasobra/components/MaterialAplicadoFinalPanel";
    import OutrosTiposFinalPanel from "@/views/folhasobra/components/OutrosTiposFinalPanel";

    export default {
        name: "PreventivaFinalPanel",
        components: {OutrosTiposFinalPanel, PreventivaFinalResultadoPanel, MaterialAplicadoFinalPanel},
        mixins: [messagesMixin],

        setup(props, {root: {$store}}) {

            const objectData = computed(() => $store.getters['folhasobra/getPreventivaData']);
            const centralDetecaoResultItems = computed(() => $store.getters['folhasobra/getCentralDetecaoResultItems'])
            const perifericosResultItems = computed(() => $store.getters['folhasobra/getPerifericosResultItems'])

            const materialsPanel = ref(null)

            return {
                centralDetecaoResultItems,
                perifericosResultItems,
                objectData,
                materialsPanel
            }
        }
    }
</script>

<style scoped>

</style>
