
<template>
    <div class="card">

        <div class="card-header">

            <h4>{{ messages("panel.admin.users.title") }}</h4>

        </div>


        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <button class="btn btn-success" v-on:click.prevent="showCreateUser">
                        {{ messages("panel.admin.users.create.btn") }} &nbsp;
                        <font-awesome-icon icon="plus-square"></font-awesome-icon>
                    </button>
                </div>
            </div>
        </div>


        <table-list :list="usersList" :page.sync="usersPage">

            <template #header>
                <th>{{ messages("label.name") }}</th>
                <th>{{ messages("label.email") }}</th>
                <th>{{ messages("label.username") }}</th>
                <th>{{ messages("label.tech.company") }}</th>
                <th class="text-center">{{ messages("label.active") }}</th>
                <th>{{ messages("label.profile") }}</th>
                <th class="text-center">{{ messages("label.date") }}</th>
                <th class="text-center"></th>
            </template>

            <tbody>
            <tr v-for="(user, idx) in usersPage" :key="user.id" v-cloak>
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.username }}</td>
                <td>{{user.empresa}}</td>
                <td class="text-center">

                    <font-awesome-icon v-if="user.active" class="text-success" icon="check"></font-awesome-icon>
                    <font-awesome-icon v-else class="text-danger" icon="times"></font-awesome-icon>

                </td>
                <td>{{ user.profile }}</td>
                <td class="text-center">{{ user.creationDate }}</td>
                <td class="text-right">
                    <button class="btn btn-outline-primary btn-sm" v-on:click="showEditUser(user, idx)">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </button>
                    <button class="btn btn-outline-danger btn-sm" v-on:click="deleteUserDialog(user)">
                        <font-awesome-icon icon="times"></font-awesome-icon>
                    </button>
                </td>
            </tr>
            </tbody>

        </table-list>


        <user-modal :show.sync="showModal" :user="selectedUser" :logged="loggedUser" @saveUser="saveUser" @editMode="editMode"></user-modal>


        <dialog-modal :show.sync="showDialog"
                      :title="messages('confirm.modal.title')"
                      :message="dialogText"
                      :successCallback="successDialogCallBack"></dialog-modal>

    </div>

</template>

<script>
    import UserModal from "./UserModal";
    import TableList from "../../../components/TableList";
    import {mapActions, mapGetters, mapMutations} from 'vuex';
    import DialogModal from "../../../components/utils/DialogModal";
    import {messagesMixin} from "../../../mixins/messagesMixin";

    export default {
        name: 'UsersMainList',
        mixins: [
            messagesMixin,
        ],

        components: {
            UserModal,
            TableList,
            DialogModal
        },

        data() {
            return {
                usersPage: [],

                selectedUser: {
                    active: true,
                    creationDate: "",
                    email: "",
                    username: "",
                    fileList: null,
                    id: null,
                    name: "",
                    password: "",
                    profile: "",
                    profileId: null,
                    empresa: null
                },

                showModal: false,

                showDialog: false,
                dialogText: '',
                successDialogCallBack: null,
                editMode: false
            }
        },

        created() {
            console.log('UsersMain created!!')
            this.loadUsersList()
        },

        computed: {
            ...mapGetters('users', {
                usersList: 'getUsersList',
                loggedUser: 'getLoggedUser',
            })
        },

        methods: {
            ...mapActions('users', [
                'loadUsersList', // map `this.increment()` to `this.$store.dispatch('increment')`
                'deleteUser',
                'criarUser',
                'updateUser',
            ]),
            ...mapMutations(
                /*[
                'addAlert',
                'showMask'],*/
                {
                    dialog: 'setDialogProps'
                }),

            showCreateUser() {
                this.editMode=false;
                this.selectedUser = {
                    active: true,
                    creationDate: "",
                    email: "",
                    username: "",
                    fileList: null,
                    id: null,
                    name: "",
                    password: "",
                    profile: "",
                    profileId: null,
                    empresa: null
                };
                this.showModal = true

            },

            showEditUser(user, idx) {
                this.editMode=true;
                this.usersList.splice(idx, 1);
                this.selectedUser = user;
                this.showModal = true
            },

            deleteUserDialog(user) {
                console.log("deleteUser -> " + JSON.stringify(user));
                this.dialog({
                    show: true,
                    message: this.messages('confirm.msg.delete.user', user.name),
                    callback: () => {
                        this.deleteUser(user)
                    }
                })

            },

            saveUser() {
                if (!this.editMode) {
                    this.criarUser(this.selectedUser);
                    this.$router.go();
                } else {
                    this.updateUser(this.selectedUser);
                    this.$router.go();
                }
            },

        },
    }
</script>



