/**
 *
 * Created by Filipe - 03/06/2020
 *
 */
import axios from 'axios'


const state = {

    contratosList: [],
    selectedContrato: {},
}

const getters = {

    getContratosList: state => state.contratosList,

    getSelectedContrato: state => state.selectedContrato,

}

const mutations = {
    setContratosList: (state, list) => state.contratosList = list,
    setSelectedContrato: (state, data) => state.selectedContrato = data,
}

const actions = {

    criarContrato({commit}, data) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ContratosController.doCriarContrato().url;

        return axios.post(url, data)
            .then((response) => {
                commit('showMask', false, {root: true})
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },

    loadContratosList({commit}) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ContratosController.loadContratos().url;

        return axios.post(url)
            .then((response) => {
                commit('setContratosList', response.data)
                commit('showMask', false, {root: true})
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });
    },

    loadContratoData({commit}, contratoId) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ContratosController.loadContratoData(contratoId).url;

        return axios.get(url)
            .then((response) => {
                commit('setSelectedContrato', response.data)
                commit('showMask', false, {root: true})
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });
    },

    editarContrato({commit}, data) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ContratosController.doEditarContrato().url;

        return axios.post(url, data)
            .then((response) => {
                commit('showMask', false, {root: true})
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },


}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}


