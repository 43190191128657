<template>
    <div class="card">

        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h3>{{messages('relatorio.testes.panel.title', folhaObra.referenciaFolhaObra)}}</h3>
                </div>

                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{name: 'finalizarFolhaObra', params: {foId: folhaObra.id}}"
                                 class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.back")}}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button class="btn btn-outline-warning btn-sm"
                            v-show="folhaObra.hasRelatorioTestesFile"
                            @click="downloadRelatorioTestes()"
                            data-toggle="tooltip" data-placement="left"
                            :title="messages('tooltip.download.test.report')">
                        <font-awesome-icon icon="file-pdf"></font-awesome-icon>
                    </button>
                </div>
            </div>
        </div>


        <relatorios-teste-final-results-panel :title="state.preventivaSistemasItemsList.title"
                                              :list-items="state.preventivaSistemasItemsList.listItems"></relatorios-teste-final-results-panel>

        <relatorios-teste-final-results-panel :title="state.corretivaCofresItemsList.title"
                                              :list-items="state.corretivaCofresItemsList.listItems"></relatorios-teste-final-results-panel>

        <relatorios-teste-final-results-panel :title="state.corretivaSicaItemsList.title"
                                              :list-items="state.corretivaSicaItemsList.listItems"></relatorios-teste-final-results-panel>

        <relatorios-teste-final-results-panel :title="state.corretivaSadiItemsList.title"
                                              :list-items="state.corretivaSadiItemsList.listItems"></relatorios-teste-final-results-panel>

        <relatorios-teste-final-results-panel :title="state.corretivaCftvItemsList.title"
                                              :list-items="state.corretivaCftvItemsList.listItems"></relatorios-teste-final-results-panel>

        <div class="card-footer text-right">

            <router-link tag="button"
                         :to="{name: 'finalizarFolhaObra', params: {foId: folhaObra.id}}"
                         class="btn btn-outline-secondary">
                <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                {{messages("button.back")}}
            </router-link>

        </div>

    </div>
</template>

<script>
    import {computed, onMounted, reactive} from "@vue/composition-api";
    import {messagesMixin} from "../../mixins/messagesMixin";
    import RelatoriosTesteFinalResultsPanel from "./components/RelatoriosTesteFinalResultsPanel";
    import {setDownloadFrame} from "../../utils";

    export default {
        name: "RelatorioTestesFinalPanel",
        components: {RelatoriosTesteFinalResultsPanel},
        mixins: [messagesMixin],


        setup(props, {root: {$store, $route}}) {
            // console.log('RelatorioTestesMainPanel', root)

            onMounted(() => {
                $store.dispatch('folhasobra/loadFolhaObraData', $route.params.foId)
            })

            const folhaObra = computed(() => $store.getters['folhasobra/getSelectedFolhaObra'])

            const state = reactive({
                preventivaSistemasItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].preventivaSistemasItemsList),
                corretivaCofresItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaCofresItemsList),
                corretivaSicaItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaSicaItemsList),
                corretivaSadiItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaSadiItemsList),
                corretivaCftvItemsList: computed(() => $store.getters['folhasobra/getRelatorioTestesItems'].corretivaCftvItemsList),
            })


            function downloadRelatorioTestes() {
                const url = jsRoutes.controllers.FolhaObraController.downloadRelatorioTestes(folhaObra.value.id).url;
                setDownloadFrame(url)
            }


            return {
                state,
                folhaObra,
                downloadRelatorioTestes
            }


        }

    }
</script>

<style scoped>

</style>
