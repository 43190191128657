/**
 *
 * Created by Filipe - 29/05/2020
 *
 */


const getIcon = (estado) => {
    switch (estado) {
        case 'SERVICO_CONCLUIDO':
            return ['far', 'check-circle']
        case 'CANCELADO':
            return ['far', 'times-circle']
    }
    return null
}

const getIconColor = (estado) => {
    switch (estado) {
        case 'SERVICO_CONCLUIDO':
            return 'text-success'
        case 'CANCELADO':
            return 'text-danger'
    }
    return ''
}

const redirectFromPlaneamento = ($router, evento) => {
    // console.debug('redirecFromPlaneamento', $router)
    // console.debug('redirecFromPlaneamento', evento)

    $('[data-toggle="tooltip"]').tooltip('dispose');

    /*if(evento.concluido){
        $router.push({name: 'finalizarFolhaObra', params: {foId: evento.folhaObraId}})
    }else {
        // console.debug('send to editar')
        $router.push({name: 'folhasobrasEditar', params: {foId: evento.folhaObraId}})
    }*/
    // console.debug('send to editar ' , evento.estadoFo)
    if(evento.concluido || evento.estadoFo == 'CANCELADO'){
        $router.push({name: 'finalizarFolhaObra', params: {foId: evento.folhaObraId}})
    }else {
        // console.debug('send to editar')
        $router.push({name: 'folhasobrasEditar', params: {foId: evento.folhaObraId}})
    }



}


export default {
    getIcon,
    getIconColor,
    redirectFromPlaneamento

}
