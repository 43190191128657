import axios from 'axios'

const state = {
    unidades: [
        {
            id: 'UN',
            key: 'enum.unidades.un'
        },
        {
            id: 'MT',
            key: 'enum.unidades.mt'
        },
        {
            id: 'CX',
            key: 'enum.unidades.cx'
        },
        {
            id: 'KG',
            key: 'enum.unidades.kg'
        }
    ],

    artigosList: [],
    artigosSelectList: [],
}
const getters = {
    // `getters` is localized to this module's getters
    // you can use rootGetters via 4th argument of getters
    // someGetter (state, getters, rootState, rootGetters)

    getArtigosList: state => state.artigosList,
    getUnidades: state => state.unidades,
    getArtigosSelectList: state => state.artigosSelectList,

}

const mutations = {
    setArtigos: (state, list) => state.artigosList = list,
    setArtigosSelect: (state, list) => state.artigosSelectList = list,
}

const actions = {

    // dispatch and commit are also localized for this module
    // they will accept `root` option for the root dispatch/commit
    // dispatch('someOtherAction', null, { root: true })
    // commit('someMutation', null, { root: true })

    loadArtigos({commit}, filterData) {
        commit('showMask', true, {root: true})

        const url = jsRoutes.controllers.ArtigoController.loadArtigos().url;

        return axios.post(url, filterData)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('setArtigos', response.data)
            }).catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });
    },

    loadArtigosSelect({commit}) {
        const url = jsRoutes.controllers.ListasComunsController.loadArtigosSelect().url;
        return axios.get(url)
            .then(response => {
                commit('setArtigosSelect', response.data)
            }).catch(error => {
            });
    },


    deleteArtigo({dispatch, commit}, artigo) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ArtigoController.deleteArtigo(artigo).url;

        return axios.get(url)
            .then(response => {
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.generic'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })

    },

    criarArtigo({commit}, artigo) {
        // console.log("criar artigo", artigo.codigo)
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ArtigoController.doCriarArtigo().url;
        return axios.post(url, artigo).then(response => {
            commit('showMask', false, {root: true})
            commit('addAlert', {
                title: Messages('success.modal.title'),
                message: Messages('success.msg.artigo.create'),
                type: 'success'
            }, {root: true});
            return response
        })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })

    },

    updateArtigo({commit}, artigo) {
        commit('showMask', true, {root: true})
        // console.log("update artigo", artigo.codigo);
        const url = jsRoutes.controllers.ArtigoController.updateArtigo().url;
        return axios.post(url, artigo)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.artigo.create'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },

    checkArtigoCodigo({commit}, codigo) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ArtigoController.checkArtigo(codigo).url;
        return axios.post(url, codigo)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.artigo.create'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
