<template>
    <div class="modal" tabindex="-1" role="dialog" ref="addAgenciasModal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{messages("panel.contratos.add.cliente.title")}}&nbsp;
                    </h5>
                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <ul class="list-group">

                    <li class="list-group-item">
                        <div class="form-row justify-content-center">
                            <div class="col-12 col-md-6">
                                <input class="form-control form-control-lg"
                                       :placeholder="messages('label.client.code')"
                                       v-model="state.codClienteSearch">
                            </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                        <div class="form-check">
                            <input class="form-check-input"
                                   type="checkbox" value=""
                                   id="defaultCheck1"
                                   v-model="state.selectAll">
                            <label class="form-check-label" for="defaultCheck1">
                                {{messages("label.select.all")}}
                            </label>
                        </div>
                    </li>

                    <li class="list-group-item">
                        <ul class="list-group">
                            <li class="list-group-item" v-for="c in state.agenciasList" :key="c.id">
                            <span>
                                <div class="form-check">
                                    <input class="form-check-input"
                                           type="checkbox"
                                           :value="c.id"
                                           v-model="state.selectedValues">
                                    <label class="form-check-label">
                                        {{ c.text }}
                                    </label>
                                </div>
                            </span>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                            @click="closeModal">
                        {{messages("button.cancel")}}
                    </button>
                    <button type="button" class="btn btn-primary" v-on:click="addAgencias">
                        {{messages("button.add")}}
                    </button>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
    import {computed, onMounted, reactive, ref, watch} from '@vue/composition-api'

    import {messagesMixin} from "../../../mixins/messagesMixin";

    export default {
        name: "AddAgenciaModal",
        mixins: [messagesMixin],
        props: ['selectedAgencias'],

        setup(props, {root: {$store}, emit}) {

            const addAgenciasModal = ref(null)

            const state = reactive({
                codClienteSearch: '',
                selectAll: false,
                agenciasList: computed(() => {
                    return $store.getters['getAgenciasAtivasCodeSelect'].filter(value => {
                        return value.text.includes(state.codClienteSearch)
                    })
                }),
                selectedValues: [],
            })

            onMounted(() => {
                $store.dispatch('loadAgenciasWithCodeAtivasSelect')
            })


            watch(() => props.selectedAgencias, (val) => {
                // console.log("watch props.selectedAgencias", val)
                if (val && val.length > 0) {
                    state.selectedValues = val.map(value => value.id)
                }
            })


            watch(() => state.selectAll, (val) => {
                // console.log("watch selectAll", val)
                const list = []
                if (val) {
                    state.agenciasList.forEach(val => {
                        list.push(val.id)
                    })
                }
                state.selectedValues = list

            })

            const addAgencias = () => {
                const list = state.agenciasList.filter(value => state.selectedValues.includes(value.id))
                emit('update:selectedAgencias', list)
                closeModal()
            }

            const showModal = () => {
                $(addAgenciasModal.value).modal('show')
            }

            const closeModal = () => {
                $(addAgenciasModal.value).modal('hide')
            }


            return {
                state,
                addAgenciasModal,
                showModal,
                closeModal,
                addAgencias
            }

        }


    }
</script>

<style scoped>

</style>
