<template>

    <div class="card">

        <div class="card-header">
            <h3>{{messages("panel.servicos.title")}}</h3>
        </div>

        <ul class="list-group">

            <!-- ACTION BUTTON BAR -->
            <li class="list-group-item">
                <button class="btn btn-success"
                        @click="showCriarServico">
                    {{ messages('button.criar.servico') }}&nbsp;
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                </button>
            </li>

            <li class="list-group-item">

                <table-list :list="state.servicos" :page.sync="state.servicosPage">

                    <template #header>
                        <th scope="col">{{messages("label.code")}}</th>
                        <th scope="col">{{messages("label.name")}}</th>
                        <th scope="col">{{messages("label.descricao")}}</th>
                        <th scope="col"></th>
                    </template>
                    <tbody>
                    <tr v-for="servico in state.servicosPage" :key="servico.codigo">
                        <th scope="row">
                            {{ servico.codigo }}
                        </th>
                        <td>
                            <input v-if="servico.isEdit"
                                   class="form-control "
                                   @focus="state.currentServico.editError = false"
                                   :class="{ 'is-invalid': state.currentServico.editError }"
                                   v-model="state.currentServico.nome">
                            <label v-else>{{ servico.nome }}</label>
                            <p class="invalid-feedback">{{messages("error.field.required")}}</p>
                        </td>
                        <td>
                            <input v-if="servico.isEdit" class="form-control" v-model="state.currentServico.descricao">
                            <label v-else>{{ servico.descricao }}</label>
                        </td>

                        <td v-if="servico.isDefault"></td>
                        <td v-else class="text-right">

                            <button v-show="servico.isEdit"
                                    class="btn btn-secondary btn-sm"
                                    data-toggle="tooltip"
                                    :title="messages('tooltip.servico.cancel.edit')"
                                    @click="cancelEditarServico(servico)">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </button>
                            <button v-show="!servico.isEdit"
                                    data-toggle="tooltip"
                                    :title="messages('tooltip.servico.edit')"
                                    class="btn btn-outline-primary btn-sm"
                                    @click="editarServico(servico)">
                                <font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
                            </button>

                            <button v-show="servico.isEdit"
                                    data-toggle="tooltip"
                                    :title="messages('tooltip.servico.edit')"
                                    @click="doEditarServico()"
                                    class="btn btn-primary btn-sm">
                                <font-awesome-icon icon="save"></font-awesome-icon>
                            </button>
                            <button v-show="!servico.isEdit"
                                    class="btn btn-outline-danger btn-sm"
                                    data-toggle="tooltip"
                                    @click="deleteServico(servico)"
                                    :title="messages('tooltip.servico.delete')">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </button>

                        </td>

                    </tr>
                    </tbody>

                </table-list>
            </li>

        </ul>

        <servico-modal ref="servicoModal"></servico-modal>

    </div>

</template>

<script>
import $ from 'jquery'
    import {computed, onMounted, reactive, ref} from '@vue/composition-api'

    import {messagesMixin} from "@/mixins/messagesMixin";
    import TableList from "../../components/TableList";
    import ServicoModal from "./ServicoModal";
    import {cloneObject} from "@/utils";


    export default {
        name: "ServicosMainPanel",
        components: {ServicoModal, TableList},
        mixins: [messagesMixin],

        setup(props, {root: {$store, $nextTick}}) {
            // console.log('ServicosMainPanel root', root)
            const servicoModal = ref(null)

            onMounted(() => {
                $store.dispatch('servicos/loadListServicos')
                    .then(() => {
                        $nextTick(() => {
                            $('[data-toggle="tooltip"]').tooltip();
                        });
                    })
            })

            const state = reactive({
                servicos: computed(() => $store.getters['servicos/getServicosList']),
                servicosPage: [],
                currentServico: {},
            })

            const deleteServico = (servico) => {
                $store.commit('setDialogProps', {
                    show: true,
                    message: Messages('confirm.msg.delete.servico', servico.codigo),
                    successStyle: 'btn-danger',
                    callback: () => {
                        $store.dispatch('servicos/deleteServico', servico.id)
                    }
                })
            }

            const showCriarServico = () => {
                servicoModal.value.showModal()
            }


            function editarServico(servico) {
                servico.isEdit = true
                servico.editError = false
                state.currentServico = cloneObject(servico)
            }

            function cancelEditarServico(servico) {
                servico.isEdit = false
                servico.editError = false
                state.currentServico = {}
            }

            function doEditarServico() {
                console.log('do editar serviço')
                if (!state.currentServico.nome || state.currentServico.nome === '') {
                    console.error('do editar serviço - edit error')
                    state.currentServico.editError = true
                    return
                }
                $store.dispatch('servicos/editarServico', state.currentServico)


            }

            return {
                servicoModal,
                state,
                deleteServico,
                showCriarServico,
                editarServico,
                cancelEditarServico,
                doEditarServico
            }

        }

    }

</script>

<style scoped>

</style>
