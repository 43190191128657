<template>

    <ul class="list-group">

        <li class="list-group-item">
            <items-selector @items-changed="changeItems"></items-selector>
        </li>

        <li class="list-group-item">

            <div class="row">
                <div class="col">
                    <table class="table table-striped table-hover table-sm">
                        <thead class="thead-light">
                        <tr>
                            <slot name="header"></slot>
                        </tr>
                        </thead>
                        <slot></slot>
                    </table>
                    <p v-show="list.length === 0" class="text-center p-2 mb-2 bg-secondary text-white rounded">
                        {{messages("info.msg.empty.list")}}
                    </p>
                </div>
            </div>

        </li>

        <li class="list-group-item">
            <paginator :total-items="list.length"
                       :items-per-page="itemsShow"
                       :selected-page="selectedPage"
                       @page-changed="changePage"></paginator>
        </li>

    </ul>


</template>

<script>
    import Paginator from './Paginator';
    import ItemsSelector from './ItemsSelector';
    import {paginatortMixin} from '../mixins/paginatorMixin'
    import {messagesMixin} from "../mixins/messagesMixin";


    export default {
        name: 'TableList',

        mixins: [
            paginatortMixin,
            messagesMixin
        ],

        components: {
            Paginator,
            ItemsSelector
        },

        props: {

            list: {
                required: true,
            },

            page: {
                required: true,
            }
        },


        data() {
            return {}

        },

        watch: {

            itemsShow() {
                this.updateItems();
            },

            selectedPage() {
                this.updateItems();
            },

            list() {
                this.updateItems();
            },

        },

        methods: {

            updateItems() {
                let firstItem = (this.selectedPage - 1) * this.itemsShow;
                let lastItem = this.itemsShow + firstItem;
                let page = this.list.slice(firstItem, lastItem);
                this.$emit('update:page', page)
            }

        }

    }

</script>

<style scoped>

</style>
