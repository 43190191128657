<template>
    <div class="modal fade" tabindex="-1" role="dialog" ref="userDataModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>

                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col">

                            <div class="form-group form-row">
                                <label class="col-auto col-form-label">{{ messages("label.name") }}</label>
                                <div class="col">
                                    <input v-model="user.name" class="form-control"
                                           :class="{'is-invalid': $v.user.name.$error }" :placeholder="messages('label.name')">
                                    <span v-show="!$v.user.name.required"
                                          class="invalid-feedback">{{ messages("error.field.required") }}</span>
                                </div>
                            </div>

                            <hr/>

                            <div class="form-group form-row">
                                <label class="col-auto col-form-label">{{ messages("label.username") }} (login)</label>
                                <div class="col">
                                    <input :disabled="editMode" v-model="user.username" class="form-control"
                                           :class="{ 'is-invalid' : $v.user.username.$error || nameRepeated}" :placeholder="messages('label.username')">
                                    <span v-show="!$v.user.username.required"
                                          class="invalid-feedback">{{ messages("error.field.required") }}</span>
                                    <span v-show="!$v.user.username.maxLength" class="invalid-feedback">{{ messages("error.field.maxlength.no-param") }}
                                        &nbsp;{{ $v.user.username.$params.maxLength.max }}</span>
                                    <span v-show="nameRepeated"
                                        class="invalid-feedback">{{messages("error.field.usernamerepeated")}}</span>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <div class="form-group row">
                                    <label class="col-auto col-form-label">{{ messages("label.email") }}</label>
                                    <div class="col">
                                        <input v-model="user.email" class="form-control"
                                               :class="{'is-invalid': $v.user.email.$error }" :placeholder="messages('label.email')">
                                        <span v-show="!$v.user.email.required"
                                              class="invalid-feedback">{{ messages("error.field.required") }}</span>
                                        <span v-show="!$v.user.email.email"
                                              class="invalid-feedback">{{ messages("error.field.email") }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="row">

                        <div class="col">
                            <div class="form-group form-row">
                                <label class="col-auto col-form-label">{{ messages("label.profile") }}</label>
                                <div class="col">
                                    <select v-model="user.profileId" class="form-control"
                                            :class="{'is-invalid': $v.user.profileId.$error }" >
                                        <option :value="null" selected>-- {{ messages("label.profile") }} --</option>
                                        <option v-for=" profile in profiles " v-bind:value="profile.id" :key="profile.id">
                                            {{ profile.text }}
                                        </option>
                                    </select>
                                    <span v-show="!$v.user.profileId.required"
                                          class="invalid-feedback">{{ messages("error.field.required") }}</span>
                                </div>
                            </div>
                        </div>



                        <div class="col">

                            <div class="custom-control custom-switch" v-show="editMode">
                                <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                       v-model="user.active">
                                <label class="custom-control-label" for="customSwitch1">{{ messages("label.active") }}</label>
                            </div>

                        </div>
                    </div>
                    <div v-if="user.profileId === 3" class="row">
                    <div class="col">
                        <div class="form-group form-row">
                            <label class="col-auto col-form-label">{{messages("label.tech.company")}}</label>
                            <div class="col">
                                <select v-model="user.empresa" class="form-control"
                                        :class="{'is-invalid': $v.user.empresa.$error }" :placeholder="messages('label.empresa')">
                                    <option v-for="empresa in empresas" :value="empresa.id" :key="empresa.id">{{messages(empresa.key)}}</option>
                                    <span class="invalid-feedback">{{ messages("error.field.required")}}</span>
                                </select>

                            </div>
                        </div>
                    </div>
                    </div>

                    <div v-if="user.profileId === 4" class="row">
                        <div class="col">
                            <div class="form-group form-row">
                                <label class="col-auto col-form-label">{{messages("label.tech.company")}}</label>
                                <div class="col">
                                    <select v-model="user.empresa" class="form-control"
                                            :class="{'is-invalid': $v.user.empresa.$error }" :placeholder="messages('label.empresa')">
                                        <option v-for="empresa in empresas" :value="empresa.id" :key="empresa.id">{{messages(empresa.key)}}</option>
                                        <span class="invalid-feedback">{{ messages("error.field.required")}}</span>
                                    </select>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row justify-content-end" v-show=" !isSameUser && editMode ">
                        <div class="col-auto">
                            <button class="btn btn-success" ref="resetPassBtn" v-on:click="resetUserPassword">{{messages("button.reset.password")}}</button>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="closeModal">
                        {{ messages("button.close") }}
                    </button>
                    <button type="button" id="saveButton" class="btn btn-primary" v-on:click.prevent="saveUser" ref="saveBtn">
                        {{ messages("button.save") }}
                        <font-awesome-icon icon="save"></font-awesome-icon>
                    </button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div> <!-- /.modal -->

</template>

<script>
    import $ from 'jquery'
    import {messagesMixin} from "@/mixins/messagesMixin";
    import {required, email, maxLength, requiredIf} from 'vuelidate/lib/validators'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: "UserModal",
        mixins: [messagesMixin],


        created() {
            this.loadProfilesSelect()
        },

        data() {
            return {
                nameRepeated: false
            }
        },

        props: {
            user: {
                type: Object,
                required: true,
                isTecnico: false
            },

            logged: {
                default() {
                    return null
                }
            },

            show: {
                required: true
            },

        },

        computed: {
            ...mapGetters('users', {
                profiles: 'getProfilesSelect',
                empresas: 'getEmpresas',
                usersList:'getUsersList'
                }),

            modalTitle() {
                if (this.editMode) {
                    return this.messages("modal.admin.edituser")
                } else {
                    return this.messages("modal.admin.createuser")
                }
            },

            isSameUser: function () {
                return this.logged === this.user.id;
            },

            editMode: function () {
                return this.user.id !== null;
            },

            checkTecnico: function () {
                if (this.user.profileId === 3)
                    return true
                else
                    return false
            }
        },

        watch: {
            show(value) {
                if (value) {
                    this.$v.$reset();
                    $(this.$refs.userDataModal).modal('show')
                } else {
                    $(this.$refs.userDataModal).modal('hide')
                }
            }
        },

        validations: {
            user: {
                username: {
                    required,
                    maxLength: maxLength(20)
                },
                email: {
                    required,
                    email
                },
                name: {
                    required
                },
                profileId: {
                    required
                },
                empresa:{
                    required: requiredIf((model) => {
                        if(!model.profileId){
                            return false
                        }
                        return model.profileId === 3
                    })
                }
            },
        },
        methods: {
            ...mapActions('users', [
                'loadProfilesSelect',
            ],
            ),

            saveUser: function () {
                this.$v.$touch();
                this.nameRepeated=false;
                this.checkName();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.nameRepeated){
                    return
                }
                this.$emit('saveUser')
            },


            closeModal() {
                this.$emit('update:show', false)
                //this.$emit('saveUser')
            },

            checkName(){
                for (var i = 0; i < this.usersList.length; i++) {
                    var repeated = this.user.username.localeCompare(this.usersList[i].username);
                    if (repeated === 0 && !this.editMode) {
                        this.nameRepeated = true;
                        return;
                    }
                }
            },

            resetUserPassword: function () {
                var url = jsRoutes.controllers.AdminController.resetUserPassword(this.user.id).url;
                this.$http.get(url).then(function (response) {
                    showSuccess(Messages("success.modal.title"), response.body);
                    setButtonEnabled(this.$refs.resetPassBtn)
                }, function (response) {
                    setButtonEnabled(this.$refs.resetPassBtn)
                    showError(Messages("error.modal.title"), response.body);
                });
                this.closeModal();
            },
        }


    }
</script>

<style scoped>

</style>
