<template>

    <div>
        <router-view></router-view>
        <alert-popup></alert-popup>
        <mask-modal></mask-modal>
        <dialog-modal></dialog-modal>
    </div>

</template>

<script>
    import {onMounted} from '@vue/composition-api'
    import AlertPopup from "./components/utils/AlertPopup";
    import MaskModal from "./components/utils/MaskModal";
    import DialogModal from "./components/utils/DialogModal";

    export default {
        name: "AppWrapper",
        components: {
            DialogModal,
            MaskModal,
            AlertPopup
        },

        setup(props, {root: {$store}}){
            onMounted(() => {
                $store.dispatch('loadAppInfo')

                const flashSuccessEl = document.getElementById('flash_success')
                if(flashSuccessEl){
                    // console.debug('flash found', flashSuccessEl.value)
                    $store.commit('addAlert',{
                        title: Messages('success.modal.title'),
                        message: flashSuccessEl.value,
                        type: 'success'
                    })

                }

                const flashErrorEl = document.getElementById('flash_error')
                if(flashErrorEl){
                    // console.error('flash found', flashErrorEl.value)
                    $store.commit('addAlert',{
                        title: Messages('error.modal.title'),
                        message: flashErrorEl.value,
                        type: 'error'
                    })
                }

            })
        }
    }
</script>

<style scoped>

</style>
