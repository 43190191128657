import axios from 'axios'

const state = {

    agenciasList: [],
}

const getters = {
    getAgenciasList: state => state.agenciasList,
}

const mutations = {
    setAgencias: (state, list) => state.agenciasList = list,
}

const actions = {

    loadAgencias({commit}, filterData) {
        commit('showMask', true, {root: true})

        const url = jsRoutes.controllers.ClienteAdminController.loadAllClients().url;

        return axios.post(url, filterData)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('setAgencias', response.data)
            }).catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });
    },

    deleteAgencia({commit}, id) {
        var url = jsRoutes.controllers.ClienteAdminController.deleteCliente(id).url;

        return axios.get(url)
            .then(response => {
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.generic'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },

    criarAgencia({commit}, agencia) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ClienteAdminController.doCriarCliente().url;
        return axios.post(url, agencia)
            .then(response => {
                commit('showMask', false, {root: false})
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.generic'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('showMask', true, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },

    loadAgencia({commit}, agenciaId) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ClienteAdminController.loadCliente(agenciaId).url;
        return axios.get(url)
            .then(response => {
                commit('showMask', false, {root: true})
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },

    updateAgencia({commit}, agencia) {
        const url = jsRoutes.controllers.ClienteAdminController.doEditarCliente().url;
        return axios.post(url, agencia)
            .then(response => {
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.generic'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    }


}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

