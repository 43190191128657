<template>
    <div class="card">

        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h3>{{editMode ? messages("panel.contratos.edit.title") : messages("panel.contratos.create.title")
                        }}</h3>
                </div>

                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/contratos'}"
                                 class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.back")}}
                    </router-link>
                </div>

            </div>

        </div>

        <div class="card-body">

            <div class="form-row form-group">
                <label class="col-form-label">{{messages("label.contract.number")}}</label>

                <div class="col-8">
                    <input type="text" class="form-control"
                           v-model="contrato.codigo"
                           :class="{ 'is-invalid' : $v.contrato.codigo.$error }"
                           :placeholder="messages('label.contract.number')">
                    <span v-show="!$v.contrato.codigo.required" class="invalid-feedback">{{messages("error.field.required")}}</span>
                </div>

            </div>

            <div class="form-row form-group">
                <label class="col-form-label">{{messages("label.description")}}</label>
                <div class="col">
                    <input type="text" class="form-control"
                           v-model="contrato.designacao"
                           :class="{ 'is-invalid' : $v.contrato.designacao.$error }"
                           :placeholder="messages('label.description')">
                    <span v-show="!$v.contrato.designacao.required" class="invalid-feedback">{{messages("error.field.required")}}</span>
                </div>
            </div>

            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="contratoDefaultCheck"
                       v-model="contrato.contratoDefault">
                <label class="custom-control-label"
                       for="contratoDefaultCheck">{{messages("label.contrato.default")}}</label>
            </div>


            <!-- SERVIÇOS -->
            <ul class="list-group mt-3">
                <li class="list-group-item list-group-item-light">
                    <h5>
                        {{messages('panel.contratos.servicos.title')}}&nbsp;
                        <button class="btn btn-outline-success ml-5"
                                @click="addServico">
                            {{messages("panel.contratos.add.servicos.btn")}} &nbsp;
                            <font-awesome-icon icon="plus-square"></font-awesome-icon>
                        </button>
                    </h5>
                </li>

                <li class="list-group-item" v-for="(servico, index) in $v.contrato.servicosContrato.$each.$iter" :key="servico.id">
                    <div class="row">
                        <div class="col-1">
                            <button v-show="!servico.$model.isDefault"
                                    @click="removerServico(index)"
                                    class="btn btn-sm btn-outline-danger">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </button>
                        </div>
                        <div class="col-8">
                            <p class="form-control-plaintext">{{ servico.$model.nomeServico }}</p>
                        </div>
                        <div class="col-3">
                            <div class="input-group">
                                <input type="number" step="0.01" v-model="servico.$model.preco" class="form-control"
                                       :class="{ 'is-invalid': servico.preco.$error }">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">€</div>
                                </div>
                                <span v-show="!servico.preco.required" class="invalid-feedback">{{messages("error.field.required")}}</span>
                                <span v-show="!servico.preco.decimal" class="invalid-feedback">{{messages("error.field.decimal.format")}}</span>

                            </div>
                        </div>
                    </div>
                </li>

            </ul>


            <!-- AGENCIAS -->
            <ul class="list-group mt-3" v-show="!contrato.contratoDefault">

                <li class="list-group-item list-group-item-light">
                    <h5>
                        {{messages('panel.contratos.clientes.title')}}&nbsp;
                        <button class="btn btn-outline-success ml-5"
                                v-on:click="addAgencia">
                            {{messages("panel.contratos.add.cliente.btn")}} &nbsp;
                            <font-awesome-icon icon="plus-square"></font-awesome-icon>
                        </button>
                    </h5>

                </li>

                <li class="list-group-item list-group-item-primary">
                    <div class="row">
                        <div class="offset-1 col-11 text-center">{{messages("label.name")}}</div>
                    </div>
                </li>

                <li class="list-group-item">
                    <div class="row mt-2" v-for="(cliente, index) in contrato.clientesContrato" :key="cliente.id">
                        <div class="col-1">
                            <button class="btn btn-sm btn-outline-danger" @click="removerAgencia(index)">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                            </button>
                        </div>
                        <div class="col">
                            <p class="form-control-plaintext text-center">{{ cliente.text }}</p>
                        </div>
                    </div>
                </li>

            </ul>


            <add-agencia-modal ref="agenciasModal"
                               :selected-agencias.sync="contrato.clientesContrato"></add-agencia-modal>

            <add-servico-modal ref="servicosModal"
                               :list-items="servicosAddList"
                               @added="doAddServico"></add-servico-modal>

        </div>


        <div class="card-footer">

            <div class="row justify-content-end">
                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/contratos'}"
                                 class="btn btn-outline-secondary mr-2">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.cancel")}}
                    </router-link>

                    <button class="btn btn-success  mr-2"
                            v-on:click="saveContrato">
                        {{editMode ? messages("button.edit.contrato") : messages("button.criar.contrato")}}&nbsp;
                        <font-awesome-icon icon="save"></font-awesome-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import {required, decimal} from 'vuelidate/lib/validators'

    import {messagesMixin} from "../../mixins/messagesMixin";
    import AddAgenciaModal from "./components/AddAgenciaModal";
    import AddServicoModal from "./components/AddServicoModal";

    export default {
        name: "ContratoMainPanel",
        components: {AddServicoModal, AddAgenciaModal},
        mixins: [messagesMixin],
        data() {
            return {
                refresh: 0,
                editMode: false,
            }
        },

        created() {
            this.setContrato({
                id: null,
                codigo: '',
                designacao: '',
                data: null,
                contratoDefault: false,
                clientesContrato: [],
                servicosContrato: [],
            })
        },

        mounted() {
            // console.log('ContratoMainPanel', this.$route.params.contratoId)
            this.loadServicos()
            if (this.$route.params.contratoId) {
                this.loadContrato(this.$route.params.contratoId)
                this.editMode = true;
            } else {
                this.editMode = false;
            }
            this.$v.$reset()
        },

        validations: {
            contrato: {
                codigo: {
                    required
                },
                designacao: {
                    required
                },

                servicosContrato: {
                    $each: {
                        // nomeServico:{},
                        preco: {
                            required,
                            decimal
                        }
                    }
                },

            }
        },

        computed: {
            ...mapGetters('contratos', {
                contrato: 'getSelectedContrato'
            }),

            ...mapGetters('servicos', {
                servicosList: 'getSelectServices',
                servicosDefault: 'getDefaulServices',
            }),

            servicosAddList() {
                const self = this
                this.refresh;
                return this.servicosList.filter(value => {
                    for (let idx in self.contrato.servicosContrato) {
                        const obj = self.contrato.servicosContrato[idx]
                        if (value.id === obj.servicoId) {
                            return false
                        }
                    }
                    return true
                })
            },

        },

        watch: {
            servicosDefault() {
                if (!this.editMode) {
                    this.servicosDefault.forEach(value => this.doAddServico(value.id))
                    this.refresh++
                }
            }
        },

        methods: {
            ...mapActions('contratos', {
                criarContrato: 'criarContrato',
                loadContrato: 'loadContratoData',
                editarContrato: 'editarContrato',
            }),

            ...mapMutations('contratos', {
                setContrato: 'setSelectedContrato'
            }),

            ...mapActions('servicos', {
                loadServicos: 'loadListServicos'
            }),

            saveContrato() {

                this.$v.$touch();
                if (this.$v.$invalid) {
                    console.error("validation ERROR!");
                    return;
                }

                if (this.editMode) {
                    this.editarContrato(this.contrato)
                        .then(response => {
                            this.$router.push({path: '/contratos'})
                        })
                } else {
                    this.criarContrato(this.contrato)
                        .then(response => {
                            this.$router.push({path: '/contratos'})
                        })
                }
            },

            addAgencia() {
                this.$refs.agenciasModal.showModal()
            },

            removerAgencia(idx) {
                this.contrato.clientesContrato.splice(idx, 1)
            },

            addServico() {
                this.$refs.servicosModal.showModal();
            },

            doAddServico(servicoId) {
                // console.log('doAddServico', servicoId)
                const obj = this.servicosAddList.filter(value => value.id === servicoId)[0]
                const servico = {
                    id: null,
                    contratoId: this.contrato.id,
                    servicoId: obj.id,
                    sistemaId: null,
                    nomeServico: obj.text,
                    preco: null,
                    isDefault: obj.isDefault,
                };
                this.contrato.servicosContrato.push(servico)
                this.refresh++
            },

            removerServico(idx){
                this.contrato.servicosContrato.splice(idx, 1)
            }

        }

    }


    /*

    data: {
        contrato: {
            id: null,
            codigo: '',
            designacao: '',
            data: null,
            contratoDefault: false,

            clientesContrato: [],
            sistemasContrato: [],
            servicosContrato: [],
        },

        contratoId: null,
        clientes: [],
        servicos: [],
        sistemas: [],
        sistemasService: [],

        modalData: {
            type: '', // sistema, servico, cliente
            items: [],
            addText: '',
            selectedIdx: null
        },

        editMode: false,

        codClienteSearch: '',
        selectAll: false,
    },

    computed: {
        clientesList() {
            return this.clientes.filter(val => val.text.startsWith(this.codClienteSearch))
        }
    },

    watch: {

        'contrato.contratoDefault'(newVal, val) {
            // console.log("watch - contrato.contratoDefault", newVal, val)
            if (!newVal) {
                this.contrato.clientesContrato = []
            }
        },

        selectAll(newVal, val) {
            // console.log("watch - contrato.contratoDefault", newVal, val)
            this.clientesList.forEach(val => val.selected = newVal)
        },

    },

    loadClientes: function () {
        var url = jsRoutes.controllers.ListasComunsController.loadClientesWithCodeSelect().url;

        this.$http.get(url).then(function (response) {
            this.clientes = response.body;
        }, function (reason) {
            showError(Messages('error.modal.title'), reason.body);
        });
    },

    loadServicos: function () {
        var url = jsRoutes.controllers.ListasComunsController.loadServicosSelect().url;
        this.$http.get(url).then(function (response) {
            this.servicos = response.body;
        }, function (reason) {
            showError(Messages('error.modal.title'), reason.body);
        });
    },

    addServico: function () {
        this.modalData = {
            type: 'servico',
            items: this.servicos,
            addText: Messages("panel.contratos.add.servicos.btn"),
            selectedIdx: null
        };
        $(this.$refs.addItemModal).modal('show');

    },

    addCliente: function () {
        var self = this
        this.clientes.forEach(val => {
            console.log("addCliente - cliente", val.text, val.id)
            self.contrato.clientesContrato.forEach(val1 => {
                if (val.id === val1.id) {
                    console.log("found ", val1.text, val1.id)
                    val.selected = true
                }
            })
            console.log("addCliente - cliente selected", val.text, val.selected)
        });
        this.codClienteSearch = ''
        this.selectAll = (this.clientes.length === this.contrato.clientesContrato.length);
        $(this.$refs.addClientesModal).modal('show');
    },

    doAddCliente: function () {
        var list = []
        this.clientesList.forEach(val => {
            if (val.selected) {
                list.push(val)
            }
        })
        this.contrato.clientesContrato = list
        this.codClienteSearch = ''
        $(this.$refs.addClientesModal).modal('hide');
    },

    addItem: function () {
        if (this.modalData.selectedIdx === null) {
            return;
        }
        var obj = {};
        switch (this.modalData.type) {
            case 'sistema':
                obj = this.modalData.items[this.modalData.selectedIdx];
                var sistema = {
                    id: null,
                    contratoId: this.contratoId,
                    sistemaId: obj.id,
                    nomeSistema: obj.text,
                    valorSla: null,
                    tipoSla: null,
                };
                this.contrato.sistemasContrato.push(sistema);
                this.sistemas.splice(this.modalData.selectedIdx, 1);
                break;
            case 'servico':
                obj = this.modalData.items[this.modalData.selectedIdx];
                var servico = {
                    id: null,
                    contratoId: this.contratoId,
                    servicoId: obj.id,
                    sistemaId: null,
                    nomeServico: obj.text,
                    preco: null,
                };
                this.contrato.servicosContrato.push(servico);
                break;

        }
        this.modalData = {
            type: '',
            items: [],
            addText: '',
            selectedIdx: null
        };

        $(this.$refs.addItemModal).modal('hide');
    },

    removerSistema: function (idx) {
        var obj = this.contrato.sistemasContrato[idx];
        var value = {
            id: obj.sistemaId,
            text: obj.nomeSistema
        };
        this.sistemas.push(value);
        this.contrato.sistemasContrato.splice(idx, 1);

    },

    removerServico: function (idx) {
        var obj = this.contrato.servicosContrato[idx];
        var value = {
            id: obj.servicoId,
            text: obj.nomeServico
        };
        this.servicos.push(value);
        this.contrato.servicosContrato.splice(idx, 1);
    },

    removerCliente: function (idx) {
        this.contrato.clientesContrato.splice(idx, 1);
    },

    },


    });

     */


</script>

<style scoped>

</style>
