<template>

    <div class="card">
        <div class="row">
            <div class="card col text-center bg-primary text-white" v-for="(day,idx) in weekDays" :key="day + idx">
                {{ day }}
            </div>
        </div>

        <div class="row" v-for="(week, idx) in weeks" :key="week + idx">

            <div v-for="(day, idx) in week"
                 :key="day+idx"
                 class="card col  day"
                 :class="{'text-muted': !day.isValid, 'bg-light': day.isWeekend && !day.isToday, 'bg-warning': day.isToday }">
                <label @click="inspectDay(day.dayDate)" class="clickable">{{ day.number }}</label>
                <!-- events container -->
                <div class="row">

                    <button v-for="evento in day.eventos"
                            :key="evento.folhaObraId + '_' + evento.tecnicoId"
                            @click="redirectFromPlaneamento($router, evento)"
                            class="col-12 event-container"
                            data-toggle="tooltip" data-html="true" :title="evento.sumario">
                        <font-awesome-icon v-if="getIcon(evento.estadoFo)" :icon="getIcon(evento.estadoFo)"
                                           :class="getIconColor(evento.estadoFo)"></font-awesome-icon>
                        &nbsp;{{ evento.displayTag }}
                    </button>

                </div>
            </div>
        </div>

    </div>


</template>

<script>

import planeamento from "../../compositionfn/planeamento";


export default {
    name: "Month",
    props: {
        days: {
            type: Array
        },
        isTecnico: {
            type: Boolean
        }
    },

    computed: {
        weeks() {
            var result = []
            this.days.forEach((d) => {
                if (result[d.weekNumber]) {
                    result[d.weekNumber].push(d)
                } else {
                    result[d.weekNumber] = []
                    result[d.weekNumber].push(d)
                }

            })


            return result
        }

    },

    data() {
        return {
            weekDays: ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
        }
    },
    methods: {

        ...planeamento,

        inspectDay(day) {
            this.$emit('view-day', day)

        },

    },
}
</script>

<style scoped>

button.event-container {
    font-size: 0.75rem;
    word-spacing: -3px;
    padding: 1px 2px;
    height: 1.6rem;
    overflow: hidden;
}

div.day {
    min-height: 5rem;
}

</style>
