<template>


    <div class="card  h-100">
        <ul class="list-group h-100">

            <li class="list-group-item">

                <div class="row">

                    <div class="col-6 col-lg-3">
                        <div class="form-row form-group">
                            <label class="col-form-label">{{ getText('label.days.number') }}</label>
                            <div class="col">
                                <select class="custom-select" v-model="days">
                                    <option :value="1">1</option>
                                    <option :value="3">3</option>
                                    <option :value="5">5</option>
                                    <option :value="7">7</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row justify-content-center">
                    <div class="col-auto">
                        <button class="btn btn-outline-secondary changer" @click="goNext(-1)"><i
                            class="fas fa-angle-double-left"></i></button>
                    </div>
                    <div class="col-auto">
                        <label class="bg-success p-3 text-light rounded">{{ currentDate }} - {{ weekDay }}</label>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-outline-secondary changer" @click="goNext(1)"><i
                            class="fas fa-angle-double-right"></i></button>
                    </div>
                </div>


            </li>

            <li class="list-group-item  h-50">

                <div class="row">

                    <div v-for="d in displayDays" :key="d.number"
                         @click="selectDay(d.date)"
                         :class="{ 'bg-warning': d.number === selectedDay, 'clickable': d.number !== selectedDay  }"
                         class="card col  day">
                        {{ d.number }} - {{ d.name }}

                        <div class="row">

                            <button v-for="evento in d.eventos"
                                    :key="evento.folhaObraId + '_' + evento.tecnicoId"
                                    class="event-container text-center"
                                    @click="redirectFromPlaneamento($router, evento)"
                                    :class="{ 'col-12': days > 3, 'col-6': days === 3, 'col-2': days === 1}"
                                    data-toggle="tooltip" data-html="true" :title="evento.sumario">
                                <font-awesome-icon v-if="getIcon(evento.estadoFo)" :icon="getIcon(evento.estadoFo)"
                                                   :class="getIconColor(evento.estadoFo)"></font-awesome-icon>
                                &nbsp;{{ evento.displayTag }}
                            </button>


                        </div>
                    </div>
                </div>
            </li>
        </ul>

    </div>
</template>

<script>

import planeamento from "../../compositionfn/planeamento";

import moment from 'moment'

export default {
    name: "DayViewer",

    props: {
        listaEventos: {
            type: Array,
            default: () => [],
        },
        currentDate: {
            type: String,
            default: moment().format('DD-MM-YYYY'),
        },

        isTecnico: {
            type: Boolean
        }
    },

    data() {
        return {
            days: 3,
            selectedDay: 0,
            firstDay: '',
            lastDay: '',
            weekDay: ''
        }
    },

    mounted() {
        this.selectDay(moment(this.currentDate, 'DD-MM-YYYY'))
    },

    watch: {
        days() {
            this.selectDay(moment(this.currentDate, 'DD-MM-YYYY'))
        }
    },

    computed: {

        displayDays() {
            let first = moment(this.firstDay, 'DD-MM-YYYY')
            let last = moment(this.lastDay, 'DD-MM-YYYY')
            // console.log("displayDays first: %s - last: %s", first, last)
            let list = []
            for (let i = first; i.isSameOrBefore(last); i.add(1, 'days')) {
                let today = moment(i).format('DD-MM-YYYY')
                let day = {
                    number: i.date(),
                    name: moment(i).format('dddd'),
                    date: today,
                    eventos: this.listaEventos.filter(ev => ev.dataAgendamento === today)
                }
                list.push(day)
            }
            return list
        }

    },

    methods: {

        ...planeamento,

        selectDay(date) {
            let currentDate = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY')
            this.$emit('update:currentDate', currentDate)
            this.selectedDay = moment(date, 'DD-MM-YYYY').date()
            this.weekDay = moment(currentDate, 'DD-MM-YYYY').format('dddd')

            let diff = (this.days - 1) / 2
            let first = moment(currentDate, 'DD-MM-YYYY').subtract(diff, 'days')
            let last = moment(currentDate, 'DD-MM-YYYY').add(diff, 'days')
            this.firstDay = moment(first).format('DD-MM-YYYY')
            this.lastDay = moment(last).format('DD-MM-YYYY')

            let value = {
                start: this.firstDay,
                end: this.lastDay
            }
            this.$emit('period-changed', value)

        },

        getText(key) {
            return Messages(key)
        },

        goNext(val) {
            let tmp;
            if (val > 0) {
                tmp = moment(this.currentDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY')
            } else {
                tmp = moment(this.currentDate, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY')
            }
            this.selectDay(tmp)
        },

    },

}
</script>

<style scoped>

div.day {
    min-height: 12rem;
}

button.event-container {
    font-size: 0.75rem;
    word-spacing: -3px;
    padding: 1px 2px;
    height: 1.6rem;
    overflow: hidden;
}

button.changer {
    margin-top: 10px;
}

</style>
