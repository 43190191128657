import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import $ from 'jquery'
import 'popper.js'
import axios from 'axios'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueCompositionApi from '@vue/composition-api';

import Moment from 'moment'
import Chart from 'chart.js'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import Paginator from './components/Paginator';
import ItemsSelector from './components/ItemsSelector';
import DatePicker from './components/datepicker/DatePicker'
import TimePicker from './components/TimePicker'

import store from './store/index'
import router from "./router";

import AppWrapper from "./AppWrapper";

/** SOLID icons */
import {
    faArrowAltCircleLeft,
    faBook,
    faCheck,
    faFilePdf,
    faFileExcel,
    faInfoCircle,
    faPlus,
    faPlusSquare,
    faSave,
    faSearch,
    faSpinner,
    faTimes,
    faQuestionCircle,
    faUser,
    faPencilAlt
} from '@fortawesome/free-solid-svg-icons'

/** REGULAR icons */
import {faCheckCircle, faCircle, faTimesCircle, faEdit} from '@fortawesome/free-regular-svg-icons'
import ProfilesAllowed from "./components/utils/ProfilesAllowed";

library.add(
    faPlus,
    faArrowAltCircleLeft,
    faSave,
    faBook,
    faSpinner,
    faTimes,
    faSearch,
    faInfoCircle,
    faCheck,
    faCircle,
    faCheckCircle,
    faTimesCircle,
    faFilePdf,
    faPlusSquare,
    faQuestionCircle,
    faFileExcel,
    faEdit,
    faUser,
    faPencilAlt
)

Vue.component('font-awesome-icon', FontAwesomeIcon)


// - PROD = false
Vue.config.devtools = false;
Vue.config.productionTip = true;

Vue.use(Vuelidate)
Vue.use(VueCompositionApi);
Vue.prototype.$http = axios


Vue.component('profiles-allowed', ProfilesAllowed);
Vue.component('paginator', Paginator);
Vue.component('items-selector', ItemsSelector);
Vue.component('date-picker', DatePicker);
Vue.component('time-picker', TimePicker);

Moment.locale('pt');


window.Vue = Vue
window.$ = $
window.moment = Moment
window.Chart = Chart


/** APP */
if (document.getElementById('main_pedidos_container')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#main_pedidos_container')
}

if (document.getElementById('main_folhas_obra_container')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#main_folhas_obra_container')
}

if (document.getElementById('planeamento_main_container')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#planeamento_main_container')
}

if (document.getElementById('main_artigos_container')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#main_artigos_container')
}
if (document.getElementById('main_contratos_container')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#main_contratos_container')
}
if (document.getElementById('servicos_main_container')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#servicos_main_container')
}
if (document.getElementById('agencias_main_container')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#agencias_main_container')
}
if (document.getElementById('users_main_container')){
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#users_main_container')
}

if (document.getElementById('parametros_main_container')){
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#parametros_main_container')
}

if (document.getElementById('user_area_container')){
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#user_area_container')
}


/**
 * LOGIN
 */

if (document.getElementById('login_page')) {
    new Vue({
        store,
        router,
        render: (h) => h(AppWrapper)
    }).$mount('#login_page')
}

/*

export {
    AlertBox,
    SignaturePad,
    required,
    email,
    maxLength,
    decimal,
    integer,
    requiredIf,
    paginatortMixin

}

*/

