<template>

    <div class="card-body border-top">

        <div class="form-group form-row">
            <label class="col-form-label font-weight-bold">{{title}}</label>
        </div>

        <div class="form-group form-row ">
            <div class="col-form-label col-1 text-center font-weight-bold">{{messages('relatorio.testes.header.order')}}</div>
            <div class="col-form-label col-2 font-weight-bold">{{messages('relatorio.testes.header.equipment')}}</div>
            <div class="col-form-label col-2 font-weight-bold">{{messages('relatorio.testes.header.test')}}</div>
            <div class="col-form-label col font-weight-bold">{{messages('relatorio.testes.header.description')}}</div>
            <div class="col-form-label col-1 text-center font-weight-bold">{{messages('relatorio.testes.header.result')}}</div>
        </div>

        <div class="row" v-for="item in listItems" :key="item.id">

            <div class="col-1 text-center">{{item.ordem}}</div>
            <div class="col-2">{{item.equipamento}}</div>
            <div class="col-2">{{item.teste}}</div>
            <div class="col">{{item.descricaoTeste}}</div>
            <div class="col-1 text-center">
                <font-awesome-icon
                    :icon="getResultIcon(item.result)"
                    :class="getResultStyle(item.result)"
                    size="lg"></font-awesome-icon>
            </div>
        </div>

    </div>

</template>

<script>
    import {getButtonStyleForBool, getIconForBool, getTextStyleForBool} from "../../../compositionfn/utils";
    import {messagesMixin} from "../../../mixins/messagesMixin";

    export default {
        name: "RelatoriosTesteFinalResultsPanel",
        props: ['title', 'listItems'],
        mixins: [messagesMixin],

        setup() {

            const getResultIcon = (result) => getIconForBool(result)
            const getResultClass = (result) => getButtonStyleForBool(result)
            const getResultStyle = (result) => getTextStyleForBool(result)

            return {
                getResultIcon,
                getResultClass,
                getResultStyle
            }
        }
    }
</script>

<style scoped>

</style>
