<template>

    <div class="modal" tabindex="-1" role="dialog" ref="modalElement">

        <div class="modal-dialog" role="document">

            <div> <!--class="modal-content "-->
                <font-awesome-icon icon="spinner" size="4x" spin :style="{ color: '#dcdcdc' }"></font-awesome-icon>
            </div>


        </div>
    </div>


</template>

<script>
import $ from 'jquery'
import {computed, ref, watch} from '@vue/composition-api';


export default {
    name: "MaskModal",

    setup(props, {root: {$store}}) {
        // refs for DOM elements
        const modalElement = ref(null)

        const show = computed(() => $store.getters.getShowMask)
        watch(show, (show) => show ? showMask() : hideMask())

        function showMask() {
            $(modalElement.value).modal('show')
        }

        function hideMask() {
            $(modalElement.value).modal('hide')
        }


        return {
            modalElement
        }

    }
}
</script>

<style scoped>

.modal-dialog div {
    width: 1px;
    top: 35%;
    left: 50%;
    position: fixed;
}

</style>
