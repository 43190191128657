/**
 *
 * Created by Filipe - 07/06/2020
 *
 */
import axios from 'axios'

const state = {
    servicosList: [],
}

const getters = {
    // `getters` is localized to this module's getters
    // you can use rootGetters via 4th argument of getters
    // someGetter (state, getters, rootState, rootGetters)
    getServicosList: state => state.servicosList,

    getDefaulServices: (state) => {
        return state.servicosList.filter(value => value.isDefault)
    },

    getSelectServices: (state) => {
        return state.servicosList.map(value => ({id: value.id, text: value.nome, isDefault: value.isDefault}))
    },


}

const mutations = {
    setServicosList: (state, list) => state.servicosList = list,
}

const actions = {
    // dispatch and commit are also localized for this module
    // they will accept `root` option for the root dispatch/commit
    // dispatch('someOtherAction', null, { root: true })
    // commit('someMutation', null, { root: true })
    criarServico({commit, dispatch}, servico) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ServicosController.criarServico().url;


        return axios.post(url, servico)
            .then((response) => {
                dispatch('loadListServicos')
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.service.create'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
                return error
            }).finally(() => {
                commit('showMask', false, {root: true})
            })

    },

    loadListServicos({commit}) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ServicosController.loadServicos().url;

        return axios.get(url)
            .then((response) => {
                commit('setServicosList', response.data)
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })
    },

    editarServico({commit, dispatch}, servico){
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ServicosController.editarServico().url;

        return axios.post(url, servico)
            .then((response) => {
                dispatch('loadListServicos')
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.service.edited'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })

    },

    deleteServico({commit, dispatch}, servicoId) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.ServicosController.anularServico(servicoId).url;

        return axios.get(url)
            .then((response) => {
                dispatch('loadListServicos')
                commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.generic'),
                    type: 'success'
                }, {root: true});
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })
    },

}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
