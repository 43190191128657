<template>
    <router-view></router-view>
</template>

<script>
    import {onMounted} from "@vue/composition-api";
    import agencias from "../../store/modules/agencias";

    export default {
        name: "AgenciasModule",

        setup(props, {root: {$store}}) {

            onMounted(() => {
                $store.dispatch('loadZonasAgenciasSelect')
            })

        }
    }
</script>

<style scoped>

</style>
