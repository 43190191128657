/**
 *
 * Created by Filipe - 10/06/2020
 *
 */


export function setDownloadFrame(downloadUrl) {
    try {
        window.open(downloadUrl, '_blank')
        /*
        const iframe = document.createElement("IFRAME");
        iframe.setAttribute("src", downloadUrl);
        iframe.setAttribute("id", "__downloadframe");
        iframe.addEventListener("load", (e) => {
            document.body.removeChild(iframe);
        });
        iframe.addEventListener("error", (err) => {
            console.error('error ', err)
        });
        document.body.appendChild(iframe);
        */
    } catch (e) {
        console.error('catched error ', e)
    }
}


export function cloneObject(obj) {
    let result = {}

    for (let i in obj) {
        result[i] = obj[i]
    }

    return result
}





