<template>


    <div class="modal" tabindex="-1" role="dialog" ref="servicoModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> {{messages("modal.servicos.title")}} </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="form-group row">
                        <label class="col-auto col-form-label">{{messages("label.code")}}</label>
                        <div class="col">
                            <input type="text" class="form-control"
                                   v-model="servico.codigo"
                                   :class="{'is-invalid': $v.servico.codigo.$error }"
                                   :placeholder="messages('label.code')">
                            <span v-show="!$v.servico.codigo.required"
                                  class="invalid-feedback">{{messages("error.field.required")}}</span>
                            <span v-show="!$v.servico.codigo.maxLength"
                                  class="invalid-feedback">{{messages("error.field.maxlength", $v.servico.codigo.$params.maxLength.max)}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-auto col-form-label">{{messages("label.nome")}}</label>
                        <div class="col">
                            <input type="text" class="form-control"
                                   v-model="servico.nome"
                                   :class="{'is-invalid': $v.servico.nome.$error }"
                                   :placeholder="messages('label.nome')">
                            <span v-show="!$v.servico.nome.required"
                                  class="invalid-feedback">{{messages("error.field.required")}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-auto col-form-label">{{messages("label.description")}}</label>
                        <div class="col">
                            <textarea class="form-control" rows="6"
                                      v-model="servico.descricao"
                                      :placeholder="messages('label.description')"></textarea>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">
                        {{messages("button.close")}}
                    </button>
                    <button type="button" class="btn btn-primary"
                            @click="criarServico">
                        {{messages("button.save")}}&nbsp;
                        <font-awesome-icon icon="save"></font-awesome-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import {mapActions} from 'vuex'
    import {maxLength, required} from 'vuelidate/lib/validators'


    import {messagesMixin} from "../../mixins/messagesMixin";

    export default {
        name: "ServicoModal",
        mixins: [messagesMixin],

        data() {
            return {
                servico: {
                    id: null,
                    codigo: "",
                    nome: "",
                    descricao: "",
                }
            }
        },

        validations: {
            servico: {
                codigo: {
                    required,
                    maxLength: maxLength(50)
                },
                nome: {
                    required,
                }
            }
        },

        methods: {

            ...mapActions('servicos', {
                doCriar: 'criarServico'
            }),

            criarServico() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.error("validation ERROR!");
                    return;
                }

                this.doCriar(this.servico)
                    .then(response => {
                        this.closeModal()
                    })

            },

            showModal() {
                this.servico = {
                    id: null,
                    codigo: '',
                    nome: '',
                    descricao: '',
                }
                this.$v.$reset();
                $(this.$refs.servicoModal).modal('show')
            },

            closeModal() {
                $(this.$refs.servicoModal).modal('hide')
            },


        }
    }
</script>

<style scoped>

</style>
