/**
 *
 * Created by Filipe - 23/05/2020
 *
 */
import axios from 'axios'
import {setDownloadFrame} from "../../utils";


const state = {

    estadosFolhaObra: [
        {
            id: 'ABERTO',
            key: 'enum.estadofolhaobra.aberto'
        },
        {
            id: 'SERVICO_CONCLUIDO',
            key: 'enum.estadofolhaobra.servico_concluido'
        },
        {
            id: 'CANCELADO',
            key: 'enum.estadofolhaobra.cancelado'
        },
    ],

    folhasObraList: [],
    folhasObraSelectList: [],


    preventivaDefault: {

        centralDetecaoResultItems: [
            {
                codigo: 'C01',
                descricao: 'verificação geral da operacionalidade',
                quantidade: '',
                result: null,
                observacoes: ''
            },

            {
                codigo: 'C02',
                descricao: 'sinóptico de informação das zonas da central, com verificação da actuação',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C03',
                descricao: 'funcionamento dos alarmes, em caso de atuação de qualquer uma das zonas',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C04',
                descricao: 'funcionamento da sirene, com verificação da temporização desta',
                quantidade: '',
                result: null,
                observacoes: ''
            },

            {
                codigo: 'C05',
                descricao: 'programação, com verificação desta, quando existir',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C06',
                descricao: 'chave de comando, com verificação da sua existência',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C07',
                descricao: 'verificação de baterias, com substituição se necessário e etiquetagem',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C08',
                descricao: 'reaperto de todos os bornes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C09',
                descricao: 'limpeza geral exterior',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C10',
                descricao: 'restantes ações corretivas, consideradas necessárias',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C11',
                descricao: 'formação aos utilizadores dos procedimentos básicos',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C12',
                descricao: 'interligação da central com AVAC, incluíndo testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C13',
                descricao: 'interligação da central com Elevadores, incluíndo testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C14',
                descricao: 'interligação da central com Sistema de Pressurização e Desenfumagem, incluíndo testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C15',
                descricao: 'interligação da central com Registos Corta-Fogo, incluíndo testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C16',
                descricao: 'interligação da central com Portas Corta-Fogo, incluíndo testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C17',
                descricao: 'interligação da central com outros meios passivos, incluíndo testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C18',
                descricao: 'organização e fixação de todos os cabos, com abraçadeiras plásticas(sempre que justifique)',
                quantidade: '',
                result: null,
                observacoes: ''
            },


        ],

        perifericosResultItems: [
            {
                codigo: 'C19',
                descricao: 'detetores, com limpeza e realização de testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C20',
                descricao: 'detetores de inundação, com verificação de funcionamento',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C21',
                descricao: 'sinalizadores repetidores, com limpeza e verificação de funcionamento',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C22',
                descricao: 'botoneiras de evacuação, com testes de atuação e verificação dos vidros de proteção',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C23',
                descricao: 'sirenes interiores e exteriores, placa temporizadora, com verificação de atuação',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C24',
                descricao: 'placa de voz, com verificação de atuação',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C25',
                descricao: 'módulos sirenes, zona e comando',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C26',
                descricao: 'painéis repetidores, incluíndo testes',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C27',
                descricao: 'condições de alimentação de energia',
                quantidade: '',
                result: null,
                observacoes: ''
            },

            {
                codigo: 'C28',
                descricao: 'verificação das fontes de alimentação',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C29',
                descricao: 'verificação de baterias das FA, com substituição se necessário e etiquetagem',
                quantidade: '',
                result: null,
                observacoes: ''
            },
            {
                codigo: 'C30',
                descricao: 'restantes ações corretivas, consideradas necessárias',
                quantidade: '',
                result: null,
                observacoes: ''
            },
        ]

    },

    selectedFolhaObra: {
        id: 0,
        loggedUser: null,
        tipoPedidoKey: '',
        estadoFolhaObraKey: '',
        tipoFolhaObra: '',
    },

    relatorioTestesDefaultItems: {
        preventivaSistemasItemsList: {
            title: 'MANUTENÇÃO PREVENTIVA SISTEMAS ELETRÓNICOS',
            listItems: [
                {
                    ordem: 1,
                    equipamento: 'Onsafe',
                    teste: 'Ping-Perda',
                    descricaoTeste: 'Desligar cabo de rede do Onsafe',
                    result: null,
                },
                {
                    ordem: 2,
                    equipamento: 'Onsafe',
                    teste: 'Falha Alimentação',
                    descricaoTeste: 'Desligar cabo de alimentação do Onsafe',
                    result: null,
                },
                {
                    ordem: 3,
                    equipamento: 'Onsafe',
                    teste: 'Falha Geral 220V',
                    descricaoTeste: 'Desligar o switch do Relé RCU',
                    result: null,
                },
                {
                    ordem: 4,
                    equipamento: 'Cofre Num. Segredo IP',
                    teste: 'Coação',
                    descricaoTeste: 'Solicitar à gerência teste de coação no Segredo do cofre IP',
                    result: null,
                },
                {
                    ordem: 5,
                    equipamento: 'SICA',
                    teste: 'Pânico',
                    descricaoTeste: 'Pressionar um botão de pânico',
                    result: null,
                },
                {
                    ordem: 6,
                    equipamento: 'SICA',
                    teste: 'Coação',
                    descricaoTeste: 'Activar código de coação SICA',
                    result: null,
                },
                {
                    ordem: 7,
                    equipamento: 'SICA',
                    teste: 'Intrusão A2(CF)',
                    descricaoTeste: 'Ligar zona do cofre e entrar no local',
                    result: null,
                },
                {
                    ordem: 8,
                    equipamento: 'SICA',
                    teste: 'Intrusão A3(ZT)',
                    descricaoTeste: 'Ligar zona das máquinas e testar um contacto magnético ou um detetor sismico térmico de uma máquina',
                    result: null,
                },
                {
                    ordem: 9,
                    equipamento: 'SICA',
                    teste: 'Intrusão A4(CFA)',
                    descricaoTeste: 'Ativar a zona do cofre de aluguer caso exista e entrar no local',
                    result: null,
                },
                {
                    ordem: 10,
                    equipamento: 'SADII',
                    teste: 'Central Incêndio',
                    descricaoTeste: 'Ativar um detetor ma central de incêndio e confirmar se há activação no SICA',
                    result: null,
                },
                {
                    ordem: 11,
                    equipamento: 'CFTV',
                    teste: 'Câmaras',
                    descricaoTeste: 'Técnico deve informar a Central de Segurança sobre o número total de câmaras instaladas (incluindo micros)',
                    result: null,
                },
                {
                    ordem: 12,
                    equipamento: 'CFTV',
                    teste: 'Micros em cofres permanentes e máquinas',
                    descricaoTeste: 'Técnico deve informar a Central de Segurança sobre número de máquinas e câmaras',
                    result: null,
                },
                {
                    ordem: 13,
                    equipamento: 'CFTV',
                    teste: 'PORTA ENTRADA',
                    descricaoTeste: 'Verificar visibilidade e enquadramento das câmaras com Central de Segurança',
                    result: null,
                },
                {
                    ordem: 14,
                    equipamento: 'CFTV',
                    teste: 'TODAS AS CAIXAS',
                    descricaoTeste: 'Verificar visibilidade e enquadramento das câmaras com Central de Segurança',
                    result: null,
                },
                {
                    ordem: 15,
                    equipamento: null,
                    teste: null,
                    descricaoTeste: 'Questionar Órgão de Gerência sobre a necessidade da alteração dos segredos dos cofres',
                    result: null,
                },

            ],
        },
        corretivaCofresItemsList: {
            title: 'MANUTENÇÃO CORRETIVA - COFRES',
            listItems: [
                {
                    ordem: 1,
                    equipamento: 'Cofre Num. Segredo IP',
                    teste: 'Coação',
                    descricaoTeste: 'Solicitar à gerência teste de coação no Segredo do cofre IP',
                    result: null,
                },
                {
                    ordem: 2,
                    equipamento: 'SICA',
                    teste: 'Coação',
                    descricaoTeste: 'Activar código coação SICA',
                    result: null,
                },
                {
                    ordem: 3,
                    equipamento: 'SICA',
                    teste: 'Pânico',
                    descricaoTeste: 'Pressionar botão de pânico',
                    result: null,
                },
                {
                    ordem: 4,
                    equipamento: 'CFTV',
                    teste: 'Câmaras',
                    descricaoTeste: 'Verificação das câmaras com operador Central Segurança',
                    result: null,
                },
                {
                    ordem: 5,
                    equipamento: null,
                    teste: null,
                    descricaoTeste: 'Questionar Órgão de Gerência sobre a necessidade da alteração dos segredos dos cofres',
                    result: null,
                },

            ],
        },
        corretivaSicaItemsList: {
            title: 'MANUTENÇÃO CORRETIVA - SICA',
            listItems: [
                {
                    ordem: 1,
                    equipamento: 'SICA',
                    teste: 'Pânico',
                    descricaoTeste: 'Pressionar botão de pânico',
                    result: null,
                },
                {
                    ordem: 2,
                    equipamento: 'CFTV',
                    teste: 'Câmaras',
                    descricaoTeste: 'Verificação das câmaras com operador Central Segurança',
                    result: null,
                },
            ],
        },
        corretivaSadiItemsList: {
            title: 'MANUTENÇÃO CORRETIVA - SADI',
            listItems: [
                {
                    ordem: 1,
                    equipamento: 'SADII',
                    teste: 'Central Incêndio',
                    descricaoTeste: 'Ativar um detetor na central de incêndio e confirmar se há activação no SICA',
                    result: null,
                },
                {
                    ordem: 2,
                    equipamento: 'CFTV',
                    teste: 'Câmaras',
                    descricaoTeste: 'Verificação das câmaras com operador Central Segurança',
                    result: null,
                },

            ],
        },
        corretivaCftvItemsList: {
            title: 'MANUTENÇÃO CORRETIVA - CFTV',
            listItems: [
                {
                    ordem: 1,
                    equipamento: 'SICA',
                    teste: 'Coação',
                    descricaoTeste: 'Activar código de coação SICA',
                    result: null,
                },
                {
                    ordem: 2,
                    equipamento: 'CFTV',
                    teste: 'Câmaras',
                    descricaoTeste: 'Verificação das câmaras com operador Central Segurança',
                    result: null,
                },

            ],
        },
    },
}

const getters = {
    getFolhasObraList: state => state.folhasObraList,
    getFolhasObraSelectList: state => state.folhasObraSelectList,

    getEstados: state => state.estadosFolhaObra,

    getCentralDetecaoResultItems: state => {
        if (!state.selectedFolhaObra.preventivaData.centralDetecaoResultItems) {
            state.selectedFolhaObra.preventivaData.centralDetecaoResultItems = state.preventivaDefault.centralDetecaoResultItems;
        }
        return state.selectedFolhaObra.preventivaData.centralDetecaoResultItems;
    },

    getPerifericosResultItems: state => {
        if (!state.selectedFolhaObra.preventivaData.perifericosResultItems) {
            state.selectedFolhaObra.preventivaData.perifericosResultItems = state.preventivaDefault.perifericosResultItems;
        }
        return state.selectedFolhaObra.preventivaData.perifericosResultItems;
    },

    getSelectedFolhaObra: state => state.selectedFolhaObra,

    getPreventivaData: state => state.selectedFolhaObra.preventivaData,
    getOutrosTiposData: state => state.selectedFolhaObra.outrosTiposData,

    getRelatorioTestesItems: state => {

        if (state.selectedFolhaObra.relatorioTestes) {
            return {
                preventivaSistemasItemsList: {
                    title: 'MANUTENÇÃO PREVENTIVA SISTEMAS ELETRÓNICOS',
                    listItems: state.selectedFolhaObra.relatorioTestes.preventivaSistemasItemsList,
                },
                corretivaCofresItemsList: {
                    title: 'MANUTENÇÃO CORRETIVA - COFRES',
                    listItems: state.selectedFolhaObra.relatorioTestes.corretivaCofresItemsList,
                },
                corretivaSicaItemsList: {
                    title: 'MANUTENÇÃO CORRETIVA - SICA',
                    listItems: state.selectedFolhaObra.relatorioTestes.corretivaSicaItemsList,
                },
                corretivaSadiItemsList: {
                    title: 'MANUTENÇÃO CORRETIVA - SADI',
                    listItems: state.selectedFolhaObra.relatorioTestes.corretivaSadiItemsList,
                },
                corretivaCftvItemsList: {
                    title: 'MANUTENÇÃO CORRETIVA - CFTV',
                    listItems: state.selectedFolhaObra.relatorioTestes.corretivaCftvItemsList,
                },

            }
        }

        return state.relatorioTestesDefaultItems

    },

}

const mutations = {
    setFolhasObra: (state, list) => state.folhasObraList = list,
    setFolhasObraSelect: (state, list) => state.folhasObraSelectList = list,

    setSelectedFolhaObra: (state, data) => state.selectedFolhaObra = data,
}

const actions = {

    loadFolhasObraList({commit}, filterData) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.loadFolhasObra().url;

        return axios.post(url, filterData)
            .then((response) => {
                commit('setFolhasObra', response.data)
                commit('showMask', false, {root: true})
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },

    loadFolhasObraSelectList({commit}, filterData) {
        // commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.loadFolhasObraSelectBetweenDates(filterData.from, filterData.to).url;

        return axios.post(url, filterData)
            .then((response) => {
                commit('setFolhasObraSelect', response.data)
                // commit('showMask', false, {root: true})
            })
            .catch(error => {
                // commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            });

    },

    loadFolhaObraData({commit}, foId) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.loadFolhaObraData(foId).url;

        return axios.get(url)
            .then(response => {
                commit('showMask', false, {root: true})
                commit('setSelectedFolhaObra', response.data)
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
                throw error
            })
    },

    editFolhaObra({commit}, folhaObra) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.editarFolhaObra().url;

        return axios.post(url, folhaObra)
            .then(response => {
                commit('showMask', false, {root: true})
                /*commit('addAlert', {
                    title: Messages('success.modal.title'),
                    message: Messages('success.msg.fo.updated'),
                    type: 'success'
                }, {root: true});*/
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })

    },

    closeFolhaObra({commit, dispatch}, data) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.endFolhaObra().url;
        return axios.patch(url, data)
            .then(response => {
                commit('showMask', false, {root: true})
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },

    cancelarFo({commit, dispatch}, folhaObraId) {
        commit('showMask', true, {root: true})

        const url = jsRoutes.controllers.FolhaObraController.anularFO(folhaObraId).url;

        return axios.delete(url)
            .then(response => {
                commit('showMask', false, {root: true})
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
    },

    saveRelatorioTestes({commit, dispatch}, relatorioTestesData) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.saveRelatorioTestes().url;

        return axios.post(url, relatorioTestesData)
            .then(response => {
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
                throw error
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })

    },

    exportarInfoFaturacao({commit, dispatch}, filterData) {
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.exportarInfoFaturacao().url

        return axios.post(url, filterData)
            .then(response => {
                const dnldUrl = jsRoutes.controllers.Application.downloadXlsxFile(response.data.tmpFile, response.data.filename).url
                setDownloadFrame(dnldUrl)
                commit('showMask', false, {root: true})
                return response
            })
            .catch(error => {
                commit('showMask', false, {root: true})
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })

    },

    marcarPeriodoExtra({commit, dispatch}, data){
        commit('showMask', true, {root: true})
        const url = jsRoutes.controllers.FolhaObraController.marcarPeriodoExtra().url

        return axios.put(url, data)
            .then(response => {
                dispatch('loadFolhaObraData', data.id)
                return response
            })
            .catch(error => {
                commit('addAlert', {
                    title: Messages('error.modal.title'),
                    message: error.response.data,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                commit('showMask', false, {root: true})
            })




    }

}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
