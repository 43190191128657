<template>
    <div class="card">

        <div class="card-header">

            <div class="row">
                <div class="col">
                    <h3>{{messages("panel.pedidos.book.title")}} - {{ pedido.referenciaPedido }}</h3>
                </div>
                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/pedidos'}"
                                 class="btn btn-outline-secondary">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.back")}}
                    </router-link>
                </div>
            </div>


        </div>


        <div class="card-body">

            <div class="row">
                <div class="col">
                    <p>
                        <label class="bg-secondary text-light p-2 rounded">{{ pedido.clienteCode }}</label> -
                        <label>{{ pedido.clienteNome }}</label>
                    </p>
                </div>
            </div>

            <div class="row">

                <div class="col">

                    <p>
                        <label class="col-form-label">{{messages("label.request.number")}}:&nbsp;</label>
                        <label class="col-form-label">{{ pedido.numeroPedidoCliente }}</label>
                    </p>


                </div>
                <div class="col">
                    <p>
                        <label class="col-form-label">{{messages("label.order.by")}}:&nbsp;</label>
                        <label class="col-form-label">{{ pedido.pedidoPor }}</label>
                    </p>
                </div>
                <div class="col">
                    <p>
                        <label class="col-form-label">{{messages("label.request.date")}}:&nbsp;</label>
                        <label class="col-form-label">{{ pedido.dataPedido }} {{ pedido.horaPedido }}</label>
                    </p>
                </div>

            </div>

            <hr/>

            <!-- tec/data/hora -->
            <div class="row mt-1">
                <div class="col">
                    <ul class="list-group">

                        <li class="list-group-item list-group-item-primary">
                            <div class="row">
                                <div class="col-4">{{messages("label.tecnico")}}</div>
                                <div class="col-4">{{messages("label.date.start")}}</div>
                                <div class="col-4">{{messages("label.date.end")}}</div>
                            </div>
                        </li>

                        <li class="list-group-item">

                            <div class="row">

                                <div class="col-4">
                                    <select class="form-control" v-model="folhaObra.tecnicoId"
                                            :class="{ 'is-invalid': $v.folhaObra.tecnicoId.$error }">
                                        <option :value="null">{{messages("label.tecnicos.choose.placeholder")}}</option>
                                        <option :value="tec.id" :key="tec.id" v-for="tec in tecnicos">{{ tec.text }}</option>
                                    </select>
                                    <span v-show="!$v.folhaObra.tecnicoId.required" class="invalid-feedback">{{messages("error.field.required")}}</span>
                                </div>

                                <div class="col-4">
                                    <div class="row">
                                        <date-picker :date.sync="folhaObra.dataInicio"></date-picker>
                                        <time-picker :time.sync="folhaObra.horaInicio"></time-picker>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <date-picker :date.sync="folhaObra.dataFim"
                                                     :class="{ 'is-invalid': false }"></date-picker>
                                        <time-picker :time.sync="folhaObra.horaFim"
                                                     :class="{ 'is-invalid': false }"></time-picker>
                                    </div>
                                </div>

                            </div>
                        </li>

                    </ul>

                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <div class="form-group ">
                        <label>{{messages("label.request.description")}}</label>
                        <textarea class="form-control" v-model="folhaObra.descricaoAvaria" rows="5"></textarea>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group ">
                        <label>{{messages("label.confidential.info")}}</label>
                        <textarea class="form-control" v-model="folhaObra.observacoesConfidenciais" rows="5"></textarea>
                    </div>
                </div>

            </div>

        </div>


        <div class="card-footer">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <router-link tag="button"
                                 :to="{path: '/pedidos'}"
                                 class="btn btn-outline-secondary mr-2">
                        <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>&nbsp;
                        {{messages("button.cancel")}}
                    </router-link>

                    <button class="btn btn-success" @click="agendarPedido">
                        {{messages("button.book.pedido")}}&nbsp;
                        <font-awesome-icon icon="book"></font-awesome-icon>
                    </button>

                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'
    import {mapGetters, mapActions} from 'vuex'

    import {messagesMixin} from "../../mixins/messagesMixin";

    export default {
        name: "AgendarPedidoPanel",
        mixins: [messagesMixin],

        mounted: function () {

            this.loadTecnicos()
            this.loadPedido(this.$route.params.pedidoId)
                .then(response => {
                    // console.log('AgendarPedidoPanel response', response)
                    this.pedido = response.data;
                    this.folhaObra.pedidoId = this.pedido.pedidoId;
                    this.folhaObra.observacoesConfidenciais = this.pedido.observacoesConfidenciais;
                    this.folhaObra.descricaoAvaria = this.pedido.descricao;
                    this.folhaObra.nPedidoCliente = this.pedido.numeroPedidoCliente;
                })
        },

        data() {
            return {
                pedido: {},

                folhaObra: {
                    id: null,
                    pedidoId: null,

                    tecnicoId: null,
                    dataInicio: moment().format('DD-MM-YYYY'),
                    horaInicio: moment().format('HH:mm'),

                    dataFim: moment().format('DD-MM-YYYY'),
                    horaFim: moment().format('HH:mm'),

                    observacoes: "",
                    //nPedidoCliente: this.pedido.numeroPedidoCliente,
                },
            }
        },

        validations: {
            folhaObra: {
                tecnicoId: {
                    required
                }

            },
        },

        computed: {
            ...mapGetters('pedidos', {}),

            ...mapGetters({
                tecnicos: 'getTecnicosSelect'
            }),
        },

        methods: {
            ...mapActions('pedidos', {
                loadPedido: 'loadPedido',
                doAgendarPedido: 'agendarPedido',
            }),
            ...mapActions({
                loadTecnicos: 'loadTecnicosSelect'
            }),

            agendarPedido: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.error("validation ERROR!");
                    return;
                }
                this.doAgendarPedido(this.folhaObra)
                    .then((response) => {
                        this.$router.push({path: '/pedidos'})
                    })
            },

            setDataInicio($event, entradaTech) {
                entradaTech.dataInicio.$model = $event;
                entradaTech.dataFim.$model = $event;
            },

            setHoraInicio($event, entradaTech) {
                entradaTech.horaInicio.$model = $event;
                entradaTech.horaFim.$model = $event;
            },


        },
    }
</script>

<style scoped>

</style>
